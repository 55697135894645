export default {
  methods: {
    fetchPrintData() {
      const params = {
        reportType: this.ReportType,
        schoolYear: this.$route.params.schoolYear,
        countyCd: this.$route.params.countyCd,
        districtCd: this.$route.params.districtCd,
        schoolCd: this.$route.params.schoolCd,
        langCd: this.$store.getters.CurrentLanguage,
      };

      this.$store.dispatch('getTypeAndContactInformation', params);
      // this.$store.dispatch('getOverview', params);

      this.$store.dispatch('getSchoolsInDistrict', params);

      this.$store.dispatch('getDistrictEnglishMathELATrend', params);
      this.$store.dispatch('getDistrictEnglishMathMathTrend', params);
      this.$store.dispatch('getDistrictEnglishMathTrend', params);
      this.$store.dispatch('getDistrictEnglishPerformance', params);
      this.$store.dispatch('getDistrictMathPerformance', params);
      this.$store.dispatch('getEnglishProfTest', params);
      this.$store.dispatch('getEnglishProgressProf', params);
      this.$store.dispatch('getNJSLAScienceAssessmentGradeXSummary', params);
      this.$store.dispatch('getNationalAssessmentEducationalProgressNAEP', params);
      this.$store.dispatch('getDLMAssessmentParticipation', params);
      this.$store.dispatch('getELAPartPerf', params);
      this.$store.dispatch('getELAPerfByGradeX', params);
      this.$store.dispatch('getDistrictEnglishMathTrend', params);
      this.$store.dispatch('getMathPartPerf', params);
      this.$store.dispatch('getMathPerfByGradeX', params);
      this.$store.dispatch('getMathPerfByTestX', params);
      this.$store.dispatch('getNJSLAPerformanceByGradeXTable', params);

      this.$store.dispatch('getComprehensiveOrTargettedSupport', params);
      this.$store.dispatch('getESSAAccountabilityStatus', params);
      this.$store.dispatch('getESSAAccountabilityProgress', params);
      this.$store.dispatch('getESSAAccountabilitySummative', params);
      this.$store.dispatch('getSummativeFooter', params);
      this.$store.dispatch('getESSAAccountabilitySummary', params);

      this.$store.dispatch('getDistrictChronicAbsent', params);
      this.$store.dispatch('getDistrictDaysAbsent', params);
      this.$store.dispatch('getDistrictChronicAbsentGrade', params);
      this.$store.dispatch('getDistrictViolenceOffense', params);
      this.$store.dispatch('getDistrictPoliceNotif', params);
      this.$store.dispatch('getDistrictHarrassInvest', params);
      this.$store.dispatch('getDistrictStdDisciplin', params);
      this.$store.dispatch('getDistrictSchoolDay', params);
      this.$store.dispatch('getDistrictSchoolDaysMissed', params);
      this.$store.dispatch('getDistrictDeviceRatios', params);

      this.$store.dispatch('getDistrictAssessmentsParticipation', params);
      this.$store.dispatch('getDistrictAssessmentsPerformance', params);
      this.$store.dispatch('getDistrictAPIB', params);
      this.$store.dispatch('getDistrictAPIBCoursesOffered', params);
      this.$store.dispatch('getDistrictDualEnrollment', params);
      this.$store.dispatch('getDistrictDualEnrollStdtGrp', params);
      this.$store.dispatch('getDistrictCTEParticipation', params);
      this.$store.dispatch('getDistrictCTEStdGroup', params);
      this.$store.dispatch('getWorkBasedLearning', params);
      this.$store.dispatch('getDistrictCTEValuedCred', params);
      this.$store.dispatch('getDistrictCTEValuedCareer', params);
      this.$store.dispatch('getDistrictCourseParticipationMath', params);
      this.$store.dispatch('getDistrictCourseParticipationScience', params);
      this.$store.dispatch('getDistrictCourseParticipationHistory', params);
      this.$store.dispatch('getDistrictCourseParticipationLanguage', params);
      this.$store.dispatch('getDistrictCourseParticipationIT', params);
      this.$store.dispatch('getDistrictSealBiliteracy', params);
      this.$store.dispatch('getStructuredLearningExpParticipation', params);
      this.$store.dispatch('getNJGPA11', params);

      this.$store.dispatch('getDistrictEnrollmentByGrade', params);
      this.$store.dispatch('getDistrictEnrollmentByDay', params);
      this.$store.dispatch('getDistrictEnrollmentByStudentGroup', params);
      this.$store.dispatch('getDistrictEnrollmentByTime', params);
      this.$store.dispatch('getDistrictEnrollmentByRace', params);
      this.$store.dispatch('getDistrictEnrollmentByHomeLanguage', params);

      this.$store.dispatch('getGraduationRateTrendsVisual4Year', params);
      this.$store.dispatch('getGraduationRateTrendsVisual5Year', params);
      this.$store.dispatch('getGraduationRateTrendsVisual', params);
      this.$store.dispatch('getCohort4YearGraduationCohortProfile', params);
      this.$store.dispatch('getCohort5YearGraduationCohortProfile', params);
      this.$store.dispatch('getCohort6YearGraduationCohortProfile', params);
      this.$store.dispatch('getFederalGraduationRates', params);
      this.$store.dispatch('getAccountabilityGraduationRates', params);
      this.$store.dispatch('getGraduationPathways', params);
      this.$store.dispatch('getDropoutRateTrends', params);
      this.$store.dispatch('getPostsecondaryEnrollmentRateSummaryVisual', params);
      this.$store.dispatch('getPostsecondaryEnrollmentRateSummaryTable', params);
      this.$store.dispatch('getPostsecondaryEnrollmentRatesFall', params);
      this.$store.dispatch('getPostsecondaryEnrollmentRates16month', params);
      this.$store.dispatch('getApprenticeship', params);
      this.$store.dispatch('getPostsecondaryEnrollmentRatesFall', params);
      this.$store.dispatch('getPostsecondaryEnrollmentRates16month', params);
      this.$store.dispatch('getApprenticeship', params);

      this.$store.dispatch('getStudentGrowthTrendsELAMedian', params);
      this.$store.dispatch('getStudentGrowthTrendsMathMedian', params);
      this.$store.dispatch('getStudentGrowthTrendsMedian', params);
      this.$store.dispatch('getStudentGrowthTableData', params);
      this.$store.dispatch('getStudentGrowthByELAPerfLevelData', params);
      this.$store.dispatch('getStudentGrowthByMathPerfLevelData', params);
      this.$store.dispatch('getStudentGrowthByELAGradeData', params);
      this.$store.dispatch('getStudentGrowthByMathGradeData', params);
      this.$store.dispatch('getAllNarrativeData', params);

      this.$store.dispatch('getDistrictTeacherExp', params);
      this.$store.dispatch('getDistrictAdmExp', params);
      this.$store.dispatch('getDistrictStdStaffRatios', params);
      this.$store.dispatch('getDistrictTeacherDemog', params);
      this.$store.dispatch('getDistrictTeacherLevelEdu', params);
      this.$store.dispatch('getDistrictTeacher1YearRetention', params);
      this.$store.dispatch('getDistrictTeacherSubjectArea', params);
      this.$store.dispatch('getDistrictStaffCounts', params);
      // this.$store.dispatch('getDistrictFacultyAttendance', params);
      this.$store.dispatch('getStatewideEducatorEquityData', params);
      this.$store.dispatch('getPerPupilExpenditure', params);
      this.$store.dispatch('getDistrictCourseParticipationArtsKto5', params);
      this.$store.dispatch('getDistrictCourseParticipationArts6to8', params);
      this.$store.dispatch('getDistrictCourseParticipationArts9to12', params);
      this.$store.dispatch('getDistrictCourseParticipationArtsKto5Donut', params);
      this.$store.dispatch('getDistrictCourseParticipationArtsKto5DonutState', params);
      this.$store.dispatch('getDistrictCourseParticipationArts6to8Donut', params);
      this.$store.dispatch('getDistrictCourseParticipationArts6to8DonutState', params);
      this.$store.dispatch('getDistrictCourseParticipationArts9to12Donut', params);
      this.$store.dispatch('getDistrictCourseParticipationArts9to12DonutState', params);

      this.$store.dispatch('getPerPupilExpenditure', params);
    },
  },
};
