import TranslationSvc from '../../services/translation.service';
import mapping from '../mapping.mixin';
import navigation from '../navigation.mixin';

export default {
  mixins: [mapping, navigation],
  computed: {
    StaffSectionHeader() {
      return this.translationService.getStaffTranslation(
        'Staff-Header',
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Language() {
      return this.$store.getters.CurrentLanguage;
    },
    StaffHeaderId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.StaffHeader,
      );
    },
    StaffIntro() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.StaffIntro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    TeacherExpId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.TeacherExpTitle,
      );
    },
    TeacherExpTitle() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.TeacherExpTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    TeacherExpDescription() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.TeacherExpText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    TeacherExpData() {
      try {
        const storeData = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_TeacherExp`);
        if (!storeData || storeData.length === 0
          || !storeData[0].data || !storeData[0].data.labels
          || storeData[0].data.labels.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    AdmExpId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.AdmExpTitle,
      );
    },
    AdmExpTitle() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.AdmExpTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AdmExpDescription() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.AdmExpText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AdmExpData() {
      try {
        const storeData = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_AdmExp`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    StaffCountsId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.StaffCountsTitle,
      );
    },
    StaffCountsTitle() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.StaffCountsTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StaffCountsDescription() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.StaffCountsText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StaffCountsData() {
      try {
        const storeData = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_StaffCounts`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    StdStaffRatiosId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.StdStaffRatiosTitle,
      );
    },
    StdStaffRatiosTitle() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.StdStaffRatiosTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdStaffRatiosDescription() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.StdStaffRatiosText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdStaffRatiosPlusFooter() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.StdStaffRatiosPlusFooter,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdStaffRatiosTableShading() {
      return [
        {
          cellValues: ['', 'NA', 'N/A'],
          cellVariant: 'secondary',
          suppressText: true,
        },
      ];
    },
    StdStaffRatiosData() {
      try {
        const storeData = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_StdStaffRatios`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    TeacherDemogId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.TeacherDemogTitle,
      );
    },
    TeacherDemogTitle() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.TeacherDemogTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    TeacherDemogDescription() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.TeacherDemogText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    TeacherDemogData() {
      try {
        const storeData = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_TeacherDemog`);
        if (!storeData || storeData.length === 0
          || !storeData[0].data || !storeData[0].data.labels
          || storeData[0].data.labels.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    TeacherLevelEducId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.TeacherLevelEducTitle,
      );
    },
    TeacherLevelEducTitle() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.TeacherLevelEducTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    TeacherLevelEducDescription() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.TeacherLevelEducText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Teacher1YearRetentionId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.Teacher1YearRetentionTitle,
      );
    },
    Teacher1YearRetentionTitle() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.Teacher1YearRetentionTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Teacher1YearRetentionDescription() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.Teacher1YearRetentionText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Teacher1YearRetentionData() {
      try {
        const storeData = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_Teacher1YearRetention`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    StatewideEducatorEquityId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.EducatorEquityDataTitle,
      );
    },
    StatewideEducatorEquityTitle() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.EducatorEquityDataTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StatewideEducatorEquityDescription() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.EducatorEquityDataText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StatewideEducatorEquityData() {
      try {
        const storeData = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_StatewideEducatorEquityData`);
        if (!storeData || storeData.length === 0
          || !storeData[0].data || !storeData[0].data.labels
          || storeData[0].data.labels.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    StatewideEducatorEquityTableShading() {
      return [
        {
          cellValues: ['NA'],
          cellVariant: 'secondary',
          suppressText: true,
        },
      ];
    },
    TeacherSubjectAreaId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.TeacherSubjectAreaTitle,
      );
    },
    TeacherSubjectAreaTitle() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.TeacherSubjectAreaTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    TeacherSubjectAreaDescription() {
      return this.translationService.getStaffTranslation(
        TranslationSvc.Constants.ComponentNames.TeacherSubjectAreaText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    TeacherSubjectAreaData() {
      try {
        const storeData = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_TeacherSubjectArea`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
  },
  methods: {
    fetchData() {
      const params = {
        reportType: this.ReportType,
        schoolYear: this.$store.getters.SelectedSchoolYear.text,
        countyCd: this.$route.params.countyCd,
        districtCd: this.$route.params.districtCd,
        schoolCd: this.$route.params.schoolCd,
        langCd: this.$store.getters.CurrentLanguage,
        // eslint-disable-next-line max-len
        // cds: `${this.$route.params.countyCd || '0'}_${this.$route.params.districtCd || '0'}_$
        // {this.$route.params.schoolCd || '0'}`,
      };
      this.$store.dispatch('getDistrictTeacherExp', params);
      this.$store.dispatch('getDistrictAdmExp', params);
      this.$store.dispatch('getDistrictStdStaffRatios', params);
      this.$store.dispatch('getDistrictTeacherDemog', params);
      this.$store.dispatch('getDistrictTeacherLevelEdu', params);
      this.$store.dispatch('getDistrictStaffCounts', params);
      this.$store.dispatch('getDistrictTeacher1YearRetention', params);
      this.$store.dispatch('getDistrictTeacherSubjectArea', params);
      // this.$store.dispatch('getDistrictFacultyAttendance', params);
      this.$store.dispatch('getStatewideEducatorEquityData', params);
    },
    getChartTitle(index) {
      if (!this.TeacherLevelEducData()
        || !this.TeacherLevelEducData().length
        || this.TeacherLevelEducData().length === 0
        || this.TeacherLevelEducData().length <= index
        || !this.TeacherLevelEducData()[index].ChartSubTitle) return '';
      return this.TeacherLevelEducData()[index].ChartSubTitle.replace(' ', '<br/>');
    },
    TeacherLevelEducData() {
      try {
        const storeObjects = this.$store.getters.StaffReportData.filter((o) => o.Component === `${this.ReportType}_TeacherLevelEdu`);
        if (!storeObjects || storeObjects.length === 0
          || !storeObjects[0].data || !storeObjects[0].data.length
          || storeObjects[0].data.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;
        return this.MapDataHBarChart(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
  },
  watch: {
    Language() {
      this.$store.dispatch('flushReportData');
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
