import districtService from '../services/district.service';
import schoolService from '../services/school.service';
import stateService from '../services/state.service';

export default {
  state: {
    climateReportData: [],
  },
  getters: {
    ClimateReportData: (state) => state.climateReportData,
  },
  mutations: {
    SET_CLIMATE_REPORT_DATA(state, payload) {
      const component = state.climateReportData
        .filter((o) => o.Component === payload.Component);

      if (component && component.length === 1) {
        component[0].data = payload.data;
      } else {
        state.climateReportData.push({
          Component: payload.Component,
          data: payload.data,
        });
      }
    },
  },
  actions: {
    async getDistrictChronicAbsent(
      { commit, state },
      payload,
    ) {
      if (!state.climateReportData.some((o) => o.Component === `${payload.reportType}_AbsentChronic`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Climate
              .getAbsentChronic(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.Climate
              .getAbsentChronic(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.Climate
              .getAbsentChronic(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_CLIMATE_REPORT_DATA', { Component: `${payload.reportType}_AbsentChronic`, data: response });
      }
    },
    async getDistrictDaysAbsent(
      { commit, state },
      payload,
    ) {
      if (!state.climateReportData.some((o) => o.Component === `${payload.reportType}_DaysAbsent`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Climate
              .getDaysAbsent(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.Climate
              .getDaysAbsent(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.Climate
              .getDaysAbsent(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_CLIMATE_REPORT_DATA', { Component: `${payload.reportType}_DaysAbsent`, data: response });
      }
    },
    async getDistrictChronicAbsentGrade(
      { commit, state },
      payload,
    ) {
      if (!state.climateReportData.some((o) => o.Component === `${payload.reportType}_AbsentChronicGrade`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Climate
              .getAbsentChronicGrade(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.Climate
              .getAbsentChronicGrade(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.Climate
              .getAbsentChronicGrade(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_CLIMATE_REPORT_DATA', { Component: `${payload.reportType}_AbsentChronicGrade`, data: response });
      }
    },
    async getDistrictViolenceOffense(
      { commit, state },
      payload,
    ) {
      if (!state.climateReportData.some((o) => o.Component === `${payload.reportType}_ViolenceOffense`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Climate
              .getViolenceVandalismHIBandSubstanceOffenses(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.Climate
              .getViolenceVandalismHIBandSubstanceOffenses(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.Climate
              .getViolenceVandalismHIBandSubstanceOffenses(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_CLIMATE_REPORT_DATA', { Component: `${payload.reportType}_ViolenceOffense`, data: response });
      }
    },
    async getDistrictPoliceNotif(
      { commit, state },
      payload,
    ) {
      if (!state.climateReportData.some((o) => o.Component === `${payload.reportType}_PoliceNotif`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Climate
              .getPoliceNotifications(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.Climate
              .getPoliceNotifications(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.Climate
              .getPoliceNotifications(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_CLIMATE_REPORT_DATA', { Component: `${payload.reportType}_PoliceNotif`, data: response });
      }
    },
    async getDistrictHarrassInvest(
      { commit, state },
      payload,
    ) {
      if (!state.climateReportData.some((o) => o.Component === `${payload.reportType}_HarrassInvest`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Climate
              .getHarassmentIntimidationandBullyingHIBInvestigations(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.Climate
              .getHarassmentIntimidationandBullyingHIBInvestigations(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.Climate
              .getHarassmentIntimidationandBullyingHIBInvestigations(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_CLIMATE_REPORT_DATA', { Component: `${payload.reportType}_HarrassInvest`, data: response });
      }
    },
    async getDistrictStdDisciplin(
      { commit, state },
      payload,
    ) {
      if (!state.climateReportData.some((o) => o.Component === `${payload.reportType}_StdDisciplin`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Climate
              .getStudentDisciplinaryRemovals(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.Climate
              .getStudentDisciplinaryRemovals(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.Climate
              .getStudentDisciplinaryRemovals(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_CLIMATE_REPORT_DATA', { Component: `${payload.reportType}_StdDisciplin`, data: response });
      }
    },
    async getDistrictStdDisciplinGrade(
      { commit, state },
      payload,
    ) {
      if (!state.climateReportData.some((o) => o.Component === `${payload.reportType}_StdDisciplinGrade`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Climate
              .getStudentDisciplinaryRemovalsByGrade(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.Climate
              .getStudentDisciplinaryRemovalsByGrade(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.Climate
              .getStudentDisciplinaryRemovalsByGrade(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_CLIMATE_REPORT_DATA', { Component: `${payload.reportType}_StdDisciplinGrade`, data: response });
      }
    },
    async getDistrictStdArrestedGroup(
      { commit, state },
      payload,
    ) {
      if (!state.climateReportData.some((o) => o.Component === `${payload.reportType}_StdArrestedGroup`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Climate
              .getStudentArrestedByGroup(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.Climate
              .getStudentArrestedByGroup(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.Climate
              .getStudentArrestedByGroup(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_CLIMATE_REPORT_DATA', { Component: `${payload.reportType}_StdArrestedGroup`, data: response });
      }
    },
    async getDistrictStdArrestedGrade(
      { commit, state },
      payload,
    ) {
      if (!state.climateReportData.some((o) => o.Component === `${payload.reportType}_StdArrestedGrade`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Climate
              .getStudentArrestedByGrade(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.Climate
              .getStudentArrestedByGrade(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.Climate
              .getStudentArrestedByGrade(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_CLIMATE_REPORT_DATA', { Component: `${payload.reportType}_StdArrestedGrade`, data: response });
      }
    },
    async getDistrictStdPoliceGroup(
      { commit, state },
      payload,
    ) {
      if (!state.climateReportData.some((o) => o.Component === `${payload.reportType}_StdPoliceGroup`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Climate
              .getStudentPoliceByGroup(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.Climate
              .getStudentPoliceByGroup(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.Climate
              .getStudentPoliceByGroup(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_CLIMATE_REPORT_DATA', { Component: `${payload.reportType}_StdPoliceGroup`, data: response });
      }
    },
    async getDistrictStdPoliceGrade(
      { commit, state },
      payload,
    ) {
      if (!state.climateReportData.some((o) => o.Component === `${payload.reportType}_StdPoliceGrade`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Climate
              .getStudentPoliceByGrade(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.Climate
              .getStudentPoliceByGrade(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.Climate
              .getStudentPoliceByGrade(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_CLIMATE_REPORT_DATA', { Component: `${payload.reportType}_StdPoliceGrade`, data: response });
      }
    },
    async getDistrictSchoolDay(
      { commit, state },
      payload,
    ) {
      if (!state.climateReportData.some((o) => o.Component === `${payload.reportType}_SchoolDay`)) {
        let response;
        switch (payload.reportType) {
          case 'school':
            response = await schoolService.Detail.Climate
              .getSchoolDay(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            break;
        }
        commit('SET_CLIMATE_REPORT_DATA', { Component: `${payload.reportType}_SchoolDay`, data: response });
      }
    },
    async getDistrictSchoolDaysMissed(
      { commit, state },
      payload,
    ) {
      if (!state.climateReportData.some((o) => o.Component === `${payload.reportType}_SchoolDaysMissed`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.Climate
              .getStudentDisciplinaryRemovalsSchoolDaysMissed(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.Climate
              .getStudentDisciplinaryRemovalsSchoolDaysMissed(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.Climate
              .getStudentDisciplinaryRemovalsSchoolDaysMissed(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_CLIMATE_REPORT_DATA', { Component: `${payload.reportType}_SchoolDaysMissed`, data: response });
      }
    },
    async getDistrictDeviceRatios(
      { commit, state },
      payload,
    ) {
      if (!state.climateReportData.some((o) => o.Component === `${payload.reportType}_DeviceRatios`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            // response = await districtService.Detail.Climate
            //   .getDeviceRatios(
            //     payload.schoolYear,
            //     payload.countyCd, payload.districtCd,
            //     payload.langCd,
            //   );
            break;
          case 'school':
            response = await schoolService.Detail.Climate
              .getDeviceRatios(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            // response = await stateService.Detail.Climate
            //   .getDeviceRatios(
            //     payload.schoolYear,
            //     payload.langCd,
            //   );
            break;
        }
        commit('SET_CLIMATE_REPORT_DATA', { Component: `${payload.reportType}_DeviceRatios`, data: response });
      }
    },
  },
};
