import TranslationSvc from '../../services/translation.service';
import mapping from '../mapping.mixin';
import navigation from '../navigation.mixin';

export default {
  mixins: [mapping, navigation],
  computed: {
    ELASectionHeader() {
      return this.translationService.getAcademicAchievementTranslation(
        'Acad-Achievement-Header',
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ScienceSectionHeader() {
      return this.translationService.getAcademicAchievementTranslation(
        'Science-Header',
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Language() {
      return this.$store.getters.CurrentLanguage;
    },
    AcadAchievementId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.AcadAchievementHeader,
      );
    },
    AcadAchievementIntro() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.AcadAchievementIntro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnglishMathTrendId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.EnglishMathTrendTitle,
      );
    },
    EnglishMathTrendIntro() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EnglishMathTrendIntro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnglishMathTrendELAId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.EnglishMathTrendELATitle,
      );
    },
    EnglishMathTrendELATitle() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EnglishMathELATrendChartTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnglishMathTrendELAData() {
      try {
        const storeObjects = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_EnglishMathELATrend`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }
        const storeObject = storeObjects[0].data;
        const mappedObj = {
          Labels: [],
          Values: [],
          ColumnHeaders: [],
        };

        mappedObj.Labels = storeObject.labels
          .filter((o) => o.includes(' ELA'))
          .map((o) => o.replace(' ELA', ''));
        let i;
        const dataValues = [];
        const dataStrings = [];
        for (i = 0; i < mappedObj.Labels.length; i += 1) {
          dataValues.push(+storeObject.values[i][0][0].replace('%', ''));
          dataStrings.push(storeObject.values[i][0][0]);
        }
        mappedObj.Values.push({
          fill: false,
          data: dataValues,
          dataAsString: dataStrings,
          label: '4Year',
          backgroundColor: '#317bb4',
          borderColor: '#426580',
          lineTension: 0,
          spanGaps: false,
        });

        return mappedObj;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    EnglishMathTrendMathTitle() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EnglishMathMathTrendChartTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnglishMathTrendMathData() {
      try {
        const storeObjects = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_EnglishMathMathTrend`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }
        const storeObject = storeObjects[0].data;
        const mappedObj = {
          Labels: [],
          Values: [],
          ColumnHeaders: [],
        };
        mappedObj.Labels = storeObject.labels
          .filter((o) => o.includes(' Math') || o.includes(' Matemáticas'))
          .map((o) => o.replace(' Math', '').replace(' Matemáticas', ''));
        let i;
        const dataValues = [];
        const dataStrings = [];
        for (i = 0; i < mappedObj.Labels.length; i += 1) {
          dataValues.push(+storeObject.values[i][0][0].replace('%', ''));
          dataStrings.push(storeObject.values[i][0][0]);
        }
        mappedObj.Values.push({
          fill: false,
          data: dataValues,
          dataAsString: dataStrings,
          label: '4Year',
          backgroundColor: '#317bb4',
          borderColor: '#426580',
          lineTension: 0,
          spanGaps: false,
        });

        return mappedObj;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    EnglishMathTrendTitle() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EnglishMathTrendTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnglishMathTrendDescription() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EnglishMathTrendText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnglishMathTrendPlusFooter() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EnglishParticipationPlusFooter,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnglishMathTrendShading() {
      return [
        {
          cellValues: ['Met Standard', 'Cumplió el Estándar', 'Cumplió el Estándar',
            'Met Goal', 'Objetivo Cumplido', 'Cumplir Estándar', 'Met Target†', 'Objetivo cumplido†',
            'Met Target', 'Objetivo cumplido'],
          cellVariant: 'success',
        },
        {
          cellValues: ['Not Met', 'Objetivo no Cumplido', 'No se cumplió', 'No Cumplido'],
          cellVariant: 'danger',
        },
        {
          cellValues: ['Exceeds Standard', 'Exceder el Estándar', 'Excede el Estándar'],
          cellVariant: 'info',
        },
        {
          cellValues: ['NA', 'N/A', 'N'],
          cellVariant: 'secondary',
          suppressText: true,
        },
      ];
    },
    EnglishMathTrendData() {
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_EnglishMathTrend`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    ELAParticipationPerformanceId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.EnglishParticipationTitle,
      );
    },
    ELAParticipationPerformanceTitle() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EnglishParticipationTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ELAParticipationPerformanceDescription() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EnglishParticipationText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ELAParticipationPerformanceData() {
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_ELAPartPerf`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    ELAParticipationPerformanceTableShading() {
      return [
        {
          cellValues: ['NA', 'N'],
          cellVariant: 'secondary',
          suppressText: true,
        },
      ];
    },
    ELAParticipationPerformancePlusFooter() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EnglishParticipationPlusFooter,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnglishPerformanceId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.EnglishPerformanceTitle,
      );
    },
    EnglishPerformanceTitle() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EnglishPerformanceTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnglishPerformanceChartSubTitle() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EnglishPerformanceChartSubTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnglishPerformanceDescription() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EnglishPerformanceText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnglishPerformanceData() {
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_EnglishPerformance`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;

        let mappedData = this.MapData(storeObject, this.ChartTypes);
        mappedData = this.ApplyTripleBarChartPatterns(mappedData);

        return mappedData;
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    ELAPerfByGradeId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.EnglishGradesTitle,
      );
    },
    ELAPerfByGradeDescription() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.ELAPerformanceByGradeXText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ELAPerfByGradeData() {
      try { // _ELAPerfByGrade
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_ELAPerfByGrade`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        const returnData = [];
        let mapObj;
        storeObject.forEach((obj) => {
          mapObj = this.MapData(obj, this.ChartTypes);
          mapObj.grade = obj.grade;
          returnData.push(mapObj);
        });
        return returnData;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    MathParticipationPerformanceId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.MathParticipationTitle,
      );
    },
    MathParticipationPerformanceTitle() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.MathParticipationTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    MathParticipationPerformanceDescription() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.MathParicipationPerformanceText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    MathParticipationPerformanceData() {
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_MathPartPerf`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    MathParticipationPerformanceTableShading() {
      return [
        {
          cellValues: ['NA', 'N'],
          cellVariant: 'secondary',
          suppressText: true,
        },
      ];
    },
    MathParticipationPerformancePlusFooter() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EnglishParticipationPlusFooter,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    MathPerformanceId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.MathPerformanceTitle,
      );
    },
    MathPerformanceTitle() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.MathPerformanceTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    MathPerfChartSubTitle() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.MathPerformanceChartSubTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    MathPerformanceDescription() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.MathPerformanceText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    MathPerformancePlusFooter() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.MathPerformancePlusFooter,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    MathPerformanceData() {
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_MathPerformance`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;

        let mappedData = this.MapData(storeObject, this.ChartTypes);
        mappedData = this.ApplyTripleBarChartPatterns(mappedData);

        return mappedData;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    MathPerfByGradeData() {
      try { // _MathPerfByGrade
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_MathPerfByGrade`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        const returnData = [];
        let mapObj;
        storeObject.forEach((obj) => {
          mapObj = this.MapData(obj, this.ChartTypes);
          mapObj.grade = obj.grade;
          returnData.push(mapObj);
        });
        return returnData;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    MathPerfByTestId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.MathTestTitle,
      );
    },
    MathPerfByTestData() {
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_MathPerfByTest`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        const returnData = [];
        let mapObj;
        storeObject.forEach((obj) => {
          mapObj = this.MapData(obj, this.ChartTypes);
          mapObj.test = obj.test;
          returnData.push(mapObj);
        });
        return returnData;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    DLMAssessmentParticipationId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.DLMTitle,
      );
    },
    DLMAssessmentParticipationTitle() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.DLMTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    DLMAssessmentParticipationDescription() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.DLMText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    DLMAssessmentParticipationData() {
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_DLMAssessmentParticipation`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    EnglishProgressToProficiencyTestId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.EngProficiencyTestTitle,
      );
    },
    EnglishProgressToProficiencyTestTitle() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EngProficiencyTestTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnglishProgressToProficiencyTestDescription() {
      const text = this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EngProficiencyTestText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
      return text;
    },
    EnglishProgressToProficiencyTestData() {
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_EnglishProgressProficiencyTest`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    EnglishProgressToProficiencyId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.EngProficiencyTitle,
      );
    },
    EnglishProgressToProficiencyTitle() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EngProficiencyTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnglishProgressToProficiencyDescription() {
      const text = this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EngProficiencyText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
      return text;
    },
    EnglishProgressToProficiencyData() {
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_EnglishProgressProficiency`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    EnglishProfPlusFooter() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EngProficiencyPlussFooter,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EngProficiencyTestId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.EngProficiencyTestTitle,
      );
    },
    EnglishParticipationPerfTitle() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EngProficiencyTestTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnglishParticipationPerfDescription() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.EngProficiencyTestText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    EnglishParticipationPerfData() {
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_EnglishParticipationPerf`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    EnglishParticipationPerfId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.EnglishParticipationTitle,
      );
    },
    //
    NJGPATrendsId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.NJGPATrendsTitle,
      );
    },
    NJGPATrendsTitle() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.NJGPATrendsTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NJGPATrendsDescription() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.NJGPATrendsText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NJGPATrendsELATitle() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.ELAGradReadyTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NJGPAELATrendsData() {
      try {
        const storeObjects = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_EnglishMathELATrend`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }
        const storeObject = storeObjects[0].data;
        const mappedObj = {
          Labels: [],
          Values: [],
          ColumnHeaders: [],
        };

        mappedObj.Labels = storeObject.labels
          .filter((o) => o.includes(' ELA'))
          .map((o) => o.replace(' ELA', ''));
        let i;
        const dataValues = [];
        const dataStrings = [];
        for (i = 0; i < mappedObj.Labels.length; i += 1) {
          dataValues.push(+storeObject.values[i][0][0].replace('%', ''));
          dataStrings.push(storeObject.values[i][0][0]);
        }
        mappedObj.Values.push({
          fill: false,
          data: dataValues,
          dataAsString: dataStrings,
          label: '4Year',
          backgroundColor: '#317bb4',
          borderColor: '#426580',
          lineTension: 0,
          spanGaps: false,
        });

        return mappedObj;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    NJGPATrendsMathTitle() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.MathGradReadyTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NJGPATrendsMathData() {
      try {
        const storeObjects = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_EnglishMathMathTrend`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }
        const storeObject = storeObjects[0].data;
        const mappedObj = {
          Labels: [],
          Values: [],
          ColumnHeaders: [],
        };
        mappedObj.Labels = storeObject.labels
          .filter((o) => o.includes(' Math') || o.includes(' Matemáticas'))
          .map((o) => o.replace(' Math', '').replace(' Matemáticas', ''));
        let i;
        const dataValues = [];
        const dataStrings = [];
        for (i = 0; i < mappedObj.Labels.length; i += 1) {
          dataValues.push(+storeObject.values[i][0][0].replace('%', ''));
          dataStrings.push(storeObject.values[i][0][0]);
        }
        mappedObj.Values.push({
          fill: false,
          data: dataValues,
          dataAsString: dataStrings,
          label: '4Year',
          backgroundColor: '#317bb4',
          borderColor: '#426580',
          lineTension: 0,
          spanGaps: false,
        });

        return mappedObj;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    //
    NJGPA11Id() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.NJGPATitle,
      );
    },
    NJGPA11Title() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.NJGPATitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NJGPA11Description() {
      const text = this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.NJGPAText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
      return text;
    },
    NJGPA11Data() {
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_NJGPA11`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    ScienceHeaderId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.ScienceHeader,
      );
    },
    NJSLAScienceGradeIntro() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.NJSLAScienceGradeIntro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NJSLAScienceGradeSummaryId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.NJSLAScienceGradeSummaryTitle,
      );
    },
    NJSLAScienceGradeSummaryDescription() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.NJSLAScienceGradeSummaryText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NJSLAScienceGradeSummaryData() {
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_NJSLAScienceAssessmentGradeXSummary`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        const returnData = [];
        let mapObj;
        storeObject.forEach((obj) => {
          mapObj = this.MapData(obj, this.ChartTypes);
          mapObj.grade = obj.grade;
          returnData.push(mapObj);
        });
        return returnData;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    NJSLAPerfByGradeTableId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.NJSLAScienceGradeTitleTable,
      );
    },
    NJSLAPerfByGradeTableDescription() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.NJSLAScienceGradeSummaryTableText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NJSLAPerfByGradeTableData() {
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_NJSLAPerfByGradeTable`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        const returnData = [];
        let mapObj;
        storeObject.forEach((obj) => {
          mapObj = this.MapData(obj, this.ChartTypes);
          mapObj.grade = obj.grade;
          returnData.push(mapObj);
        });
        return returnData;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    NAEPId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.NAEPTitle,
      );
    },
    NAEPIntro() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.NAEPIntro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NAEPTitle() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.NAEPTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NAEPDescription() {
      return this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.NAEPText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    NAEPData() {
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_NationalAssessmentEducationalProgressNAEP`);
        if (!storeData
          || storeData.length === 0
          || !storeData[0].data
          || !storeData[0].data.labels
          || storeData[0].data.labels.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    // #region AcademicAchievement
    HideEnglishMathTrend() {
      if (!this.visualIsAvailableForInstitution(
        'AcademicAchievement',
        TranslationSvc.Constants.ComponentNames.EnglishMathTrendTitle,
      )) {
        return true;
      }
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_EnglishMathTrend`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    HideELAParticipationPerformance() {
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_ELAPartPerf`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideEnglishPerformance() {
      if (!this.visualIsAvailableForInstitution(
        'AcademicAchievement',
        TranslationSvc.Constants.ComponentNames.EnglishPerformanceTitle,
      )) {
        return true;
      }
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_EnglishPerformance`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideELAPerfByGrade() {
      if (!this.visualIsAvailableForInstitution(
        'AcademicAchievement',
        TranslationSvc.Constants.ComponentNames.EnglishGradesTitle,
      )) {
        return true;
      }
      return false;
    },
    HideMathPartPerf() {
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_MathPartPerf`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideMathPerformance() {
      if (!this.visualIsAvailableForInstitution(
        'AcademicAchievement',
        TranslationSvc.Constants.ComponentNames.MathPerformanceTitle,
      )) {
        return true;
      }
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_MathPerformance`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideMathPerfByGrade() {
      if (!this.visualIsAvailableForInstitution(
        'AcademicAchievement',
        TranslationSvc.Constants.ComponentNames.MathGrades35Title,
      )) {
        return true;
      }
      return false;
    },
    HideMathPerfByTest() {
      if (!this.visualIsAvailableForInstitution(
        'AcademicAchievement',
        TranslationSvc.Constants.ComponentNames.MathTestTitle,
      )) {
        return true;
      }
      return false;
    },
    HideDLMAssessmentParticipation() {
      if (!this.visualIsAvailableForInstitution(
        'AcademicAchievement',
        TranslationSvc.Constants.ComponentNames.DLMTitle,
      )) {
        return true;
      }
      return false;
    },
    HideEnglishProficiencyTest() {
      if (!this.visualIsAvailableForInstitution(
        'AcademicAchievement',
        TranslationSvc.Constants.ComponentNames.EngProficiencyTestTitle,
      )) {
        return true;
      }
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_EnglishProgressProficiencyTest`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideEnglishProficiency() {
      if (!this.visualIsAvailableForInstitution(
        'AcademicAchievement',
        TranslationSvc.Constants.ComponentNames.EngProficiencyTitle,
      )) {
        return true;
      }
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_EnglishProgressProficiency`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    HideEnglishParticipationPerf() {
      if (!this.visualIsAvailableForInstitution(
        'AcademicAchievement',
        TranslationSvc.Constants.ComponentNames.EngProficiencyTestTitle,
      )) {
        return true;
      }
      return false;
    },
    HideNJGPA11() {
      if (!this.visualIsAvailableForInstitution(
        'AcademicAchievement',
        TranslationSvc.Constants.ComponentNames.NJGPATitle,
      )) {
        return true;
      }
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_NJGPA11`);
        if (!storeData || storeData.length === 0 || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    // This method is not used from what I could find
    // HideNJSLA() {
    //   if (!this.visualIsAvailableForInstitution('AcademicAchievement',
    //     TranslationSvc.Constants.ComponentNames.NJSLAScienceGradeSummaryTitle)) {
    //     return true;
    //   }
    //   return false;
    // },
    HideNJSLAScienceGradeSummary() {
      if (!this.visualIsAvailableForInstitution(
        'AcademicAchievement',
        TranslationSvc.Constants.ComponentNames.NJSLAScienceGradeSummaryTitle,
      )) {
        return true;
      }
      return false;
    },
    HideNJSLAPerfByGradeTable() {
      if (!this.visualIsAvailableForInstitution(
        'AcademicAchievement',
        TranslationSvc.Constants.ComponentNames.NJSLAScienceGradeTitleTable,
      )) {
        return true;
      }
      return false;
    },
    HideNAEP() {
      if (!this.visualIsAvailableForInstitution(
        'AcademicAchievement',
        TranslationSvc.Constants.ComponentNames.NAEPTitle,
      )) {
        return true;
      }
      try {
        const storeData = this.$store.getters.AcademicReportData.filter((o) => o.Component === `${this.ReportType}_NationalAssessmentEducationalProgressNAEP`);
        if (!storeData
          || storeData.length === 0
          || !storeData[0].data
          || !storeData[0].data.labels
          || storeData[0].data.labels.length === 0) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    // #endregion AcademicAchievement
  },
  methods: {
    fetchData() {
      const params = {
        reportType: this.ReportType,
        schoolYear: this.$store.getters.SelectedSchoolYear.text,
        countyCd: this.$route.params.countyCd,
        districtCd: this.$route.params.districtCd,
        schoolCd: this.$route.params.schoolCd,
        langCd: this.$store.getters.CurrentLanguage,
      };
      this.$store.dispatch('getDistrictEnglishMathELATrend', params);
      this.$store.dispatch('getDistrictEnglishMathMathTrend', params);
      this.$store.dispatch('getELAPartPerf', params);
      this.$store.dispatch('getELAPerfByGradeX', params);
      this.$store.dispatch('getDistrictEnglishMathTrend', params);
      this.$store.dispatch('getMathPartPerf', params);
      this.$store.dispatch('getMathPerfByGradeX', params);
      this.$store.dispatch('getMathPerfByTestX', params);
      this.$store.dispatch('getDistrictEnglishPerformance', params);
      this.$store.dispatch('getDistrictMathPerformance', params);
      this.$store.dispatch('getEnglishProfTest', params);
      this.$store.dispatch('getEnglishProgressProf', params);
      this.$store.dispatch('getDistrictEnglishParticipationPerf', params);
      this.$store.dispatch('getNJGPA11', params);
      this.$store.dispatch('getNJSLAScienceAssessmentGradeXSummary', params);
      this.$store.dispatch('getNJSLAPerformanceByGradeXTable', params);
      this.$store.dispatch('getNationalAssessmentEducationalProgressNAEP', params);
      this.$store.dispatch('getDLMAssessmentParticipation', params);
    },
    ELAPerfByGradeTitle(index) {
      let title = this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.ELAPerfByGradeVisualTitleTemplate,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
      if (this.ELAPerfByGradeData[index].grade
        && this.ELAPerfByGradeData[index].grade !== '') {
        return title.replace('X', this.ELAPerfByGradeData[index].grade);
      }
      // for spanish version when grade is emtpy
      title = title.replace('de X', 'de Grado');
      // for english version when grade is emtpy
      title = title.replace('X', '');
      return title;
    },
    MathPerfByGradeTitle(index) {
      let title = this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.MathPerfByGradeVisualTitleTemplate,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
      if (this.MathPerfByGradeData[index].grade
        && this.MathPerfByGradeData[index].grade !== '') {
        return title.replace('X', this.MathPerfByGradeData[index].grade);
      }
      // for spanish version when grade is emtpy
      title = title.replace('de X', 'de Grado');
      // for english version when grade is emtpy
      title = title.replace('X', '');
      return title;
    },
    MathPerfByGradeId(index) {
      if (this.MathPerfByGradeData[index].grade === 'Grade 3'
      || this.MathPerfByGradeData[index].grade === 'Grade 4'
      || this.MathPerfByGradeData[index].grade === 'Grade 5'
      || this.MathPerfByGradeData[index].grade === 'Grado 3'
      || this.MathPerfByGradeData[index].grade === 'Grado 4'
      || this.MathPerfByGradeData[index].grade === 'Grado 5') {
        return this.GetElementIdByTitle(
          TranslationSvc.Constants.ComponentNames.MathGrades35Title,
        );
      }
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.MathGrades68Title,
      );
    },
    MathPerfByGradeDescription(index) {
      let description = this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.MathPerformanceByGradeX35Text,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
      if (this.MathPerfByGradeData[index].grade === 'Grade 3'
      || this.MathPerfByGradeData[index].grade === 'Grade 4'
      || this.MathPerfByGradeData[index].grade === 'Grade 5'
      || this.MathPerfByGradeData[index].grade === 'Grado 3'
      || this.MathPerfByGradeData[index].grade === 'Grado 4'
      || this.MathPerfByGradeData[index].grade === 'Grado 5') {
        description = this.translationService.getAcademicAchievementTranslation(
          TranslationSvc.Constants.ComponentNames.MathGrades35Text,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
      } else if (this.MathPerfByGradeData[index].grade === 'Grade 6'
      || this.MathPerfByGradeData[index].grade === 'Grado 6') {
        description = this.translationService.getAcademicAchievementTranslation(
          TranslationSvc.Constants.ComponentNames.MathGrades6Text,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
      } else if (this.MathPerfByGradeData[index].grade === 'Grade 7'
      || this.MathPerfByGradeData[index].grade === 'Grade 8'
      || this.MathPerfByGradeData[index].grade === 'Grado 7'
      || this.MathPerfByGradeData[index].grade === 'Grado 8') {
        description = this.translationService.getAcademicAchievementTranslation(
          TranslationSvc.Constants.ComponentNames.MathGrades78Text,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
      }
      return description;
    },
    MathPerfByTestTitle(index) {
      let title = this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.MathPerfByTestVisualTitleTemplate,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
      if (this.MathPerfByTestData[index].test
        && this.MathPerfByTestData[index].test !== '') {
        return title.replace('X', this.MathPerfByTestData[index].test);
      }
      // for spanish version when test is emtpy
      title = title.replace('de X', 'de Prueba');
      // for english version when test is emtpy
      title = title.replace('X', '');

      return title;
    },
    MathPerfByTestDescription(index) {
      let description = this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.MathTestText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
      if (this.MathPerfByTestData[index].test === 'Algebra I') {
        description = this.translationService.getAcademicAchievementTranslation(
          TranslationSvc.Constants.ComponentNames.MathTestAlgText,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
      } else if (this.MathPerfByTestData[index].test === 'Geometry'
      || this.MathPerfByTestData[index].test === 'Geometría') {
        description = this.translationService.getAcademicAchievementTranslation(
          TranslationSvc.Constants.ComponentNames.MathTestGeoText,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
      } else if (this.MathPerfByTestData[index].test === 'Algebra II') {
        description = this.translationService.getAcademicAchievementTranslation(
          TranslationSvc.Constants.ComponentNames.MathTestAlg2Text,
          this.$store.getters.CurrentLanguage,
          this.$store.getters.SelectedSchoolYear.value,
        );
      }
      return description;
    },
    NJSLAScienceGradeSummaryTitle(index) {
      let title = this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.NJSLAScienceVisualTitleTemplate,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
      if (this.NJSLAScienceGradeSummaryData[index].grade
        && this.NJSLAScienceGradeSummaryData[index].grade !== '') {
        return title.replace('X', this.NJSLAScienceGradeSummaryData[index].grade);
      }
      // for spanish version when grade is emtpy
      title = title.replace('de X', 'de Grado');
      // for english version when grade is emtpy
      title = title.replace('X', '');
      return title;
    },
    NJSLAPerfByGradeTableTitle(index) {
      let title = this.translationService.getAcademicAchievementTranslation(
        TranslationSvc.Constants.ComponentNames.NJSLAPerfByGradeVisualTitleTemplate,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
      if (this.NJSLAPerfByGradeTableData[index].grade
        && this.NJSLAPerfByGradeTableData[index].grade !== '') {
        return title.replace('X', this.NJSLAPerfByGradeTableData[index].grade);
      }
      // for spanish version when grade is emtpy
      title = title.replace('de X', 'de Grado');
      // for english version when grade is emtpy
      title = title.replace('X', '');
      return title;
    },
  },
  watch: {
    Language() {
      this.$store.dispatch('flushReportData');
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
