<template>
  <div>
    <b-container fluid class="print-target">
      <div class="print-container">
        <!-- First Page Header -->
        <school-district-information />
        <!-- If school is "Targetd Support" -->
        <b-row v-if="isTarget" class="px-4">
          <b-col>
            <p><i class="fas fa-info-circle" /><span v-html="TargetText" /></p>
          </b-col>
        </b-row>
        <!-- Intro Section -->
        <b-row class="px-4">
          <b-col>
            <p v-html="SummaryIntro" />
          </b-col>
        </b-row>
        <!-- School/District Highlights -->
        <b-row v-if="ShowHighlights" class="px-4">
          <b-col>
            <h2 class="pl-2" style="text-align: left; border-bottom: 2px solid #2C7FC2;">
              <i>{{ HighlightsTitle }}</i>
            </h2>
            <ul>
              <li v-for="(point, index) in HighlightsData" :key="index" class="text-left">
                {{ point }}
              </li>
            </ul>
          </b-col>
        </b-row>
        <!-- Student Growth compared to other students-->
        <b-row
          v-if="SelectedSchoolYear.value >= 2223
            && ShowGrowthComparison()"
        >
          <b-col>
            <b-container ref="growthContainer" fluid class="outside-border px-4">
              <h2 class="text-center">
                {{ StudentGrowthCompareTitle }}
                <i class="fas fa-info-circle" style="font-size: 26px;" />
              </h2>
              <b-row>
                <b-col cols="3" class="description">
                  <span v-html="StudentGrowthCompareDescription" />
                </b-col>
                <b-col
                  cols="4"
                  lg="4"
                  class="grad-chart-area"
                >
                  <visual-component
                    :visual-title="''"
                    :visual-description="''"
                    :chart-type="ChartTypes.GaugeChart"
                    :scale-data-points-label="[1, 40, 60, 99]"
                    :scale-data-points="[0, 40, 60, 100]"
                    :suppress-border="true"
                    :data-decoration="''"
                    :chart-data="StdGrowthELAData"
                  />
                </b-col>
                <b-col
                  cols="4"
                  lg="4"
                  class="grad-chart-area"
                >
                  <visual-component
                    :visual-title="''"
                    :visual-description="''"
                    :chart-type="ChartTypes.GaugeChart"
                    :scale-data-points-label="[1, 40, 60, 99]"
                    :scale-data-points="[0, 40, 60, 100]"
                    :suppress-border="true"
                    :data-decoration="''"
                    :chart-data="StdGrowthMathData"
                  />
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
        <span class="p-4" />
        <!-- How did students perform on assessments -->
        <b-row v-if="ShowPerformAssessments()" class="px-4 py-0">
          <b-col>
            <b-container ref="howAssessContainer" fluid class="outside-border px-4">
              <h2 class="text-center">
                {{ HowAssessTitle }}
                <i class="fas fa-info-circle" style="font-size: 26px;" />
              </h2>
              <b-row>
                <b-col cols="3" class="description">
                  <span v-html="HowAssessDescription" />
                </b-col>
                <b-col
                  cols="4"
                  lg="4"
                  class="grad-chart-area"
                >
                  <visual-component
                    :visual-title="''"
                    :visual-description="''"
                    :chart-type="ChartTypes.GaugeChart"
                    :scale-data-points="[0, 51.3, 80, 100]"
                    :scale-data-points-label="[0, 51.3, 80, 100]"
                    :suppress-border="true"
                    :data-decoration="'%'"
                    :chart-data="HowAssessELAData"
                  />
                </b-col>
                <b-col
                  cols="4"
                  lg="4"
                  class="grad-chart-area"
                >
                  <visual-component
                    :visual-title="''"
                    :visual-description="''"
                    :chart-type="ChartTypes.GaugeChart"
                    :scale-data-points="[0, 38.2, 80, 100]"
                    :scale-data-points-label="[0, 38.2, 80, 100]"
                    :suppress-border="true"
                    :data-decoration="'%'"
                    :chart-data="HowAssessMathData"
                  />
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
        <div
          v-if="!ShowGrowthComparison() && !ShowPerformAssessments()">
          <b-row class="px-4 py-5">
            <b-col>
              <b-container fluid class="outside-border p-4">
                <h2 class="text-center">
                  {{ StdRiskTitle }}
                </h2>
                <b-row>
                  <b-col
                    cols="3"
                    class="description">
                    <span v-html="StdRiskDescription" />
                  </b-col>
                  <b-col cols="8">
                    <visual-component
                      class="grad-chart-area"
                      :visual-title="''"
                      :visual-description="''"
                      :chart-type="ChartTypes.HorizontalBarChart"
                      :chart-data="StdRiskData"
                      :chart-x-axis-title="StdRiskXAxisTitle"
                      :suppress-border="true"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </div>
        <!-- Page 1 Footer -->
        <b-row class="mt-4" style="border-top: 2px solid #426580;">
          <b-col cols="2" class="m-auto footer-title">
            <div>
              {{ FooterTitle }}
            </div>
          </b-col>
          <b-col>
            <b-container fluid class="p-2">
              <summary-footer />
            </b-container>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="ShowGrowthComparison() || ShowPerformAssessments()"
        class="page-break" />
      <!-- Start Page 2 -->
      <div
        v-if="ShowGrowthComparison() || ShowPerformAssessments()"
        class="print-container">
        <!-- Page 2 Header (Metrics Hidden) -->
        <school-district-information :hide-metrics="true" />
        <!-- Are students at risk -->
        <b-row class="px-4 py-5">
          <b-col>
            <b-container fluid class="outside-border p-4">
              <h2 class="text-center">
                {{ StdRiskTitle }}
                <b-link
                  :to="climateLink"
                  target="_blank"
                  @click="setStdRiskScroll"
                  @keydown="setStdRiskScroll"
                >
                  <!-- Renamed from @click.native -->
                  <font-awesome-icon :icon="['fa', 'info-circle']">
                    <span class="sr-only">Students at Risk link</span>
                  </font-awesome-icon>
                </b-link>
              </h2>
              <b-row>
                <b-col
                  cols="3"
                  class="description">
                  <span v-html="StdRiskDescription" />
                </b-col>
                <b-col cols="8">
                  <visual-component
                    class="grad-chart-area"
                    :visual-title="''"
                    :visual-description="''"
                    :chart-type="ChartTypes.HorizontalBarChart"
                    :chart-data="StdRiskData"
                    :chart-x-axis-title="StdRiskXAxisTitle"
                    :suppress-border="true"
                  />
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
        <!-- Graduation Rates -->
        <b-row v-if="ShowGradRates()" class="px-4 py-0">
          <b-col>
            <b-container ref="gradRateContainer" fluid class="outside-border px-4">
              <h2 class="text-center">
                {{ StdGradTitle }}
                <i class="fas fa-info-circle" style="font-size: 26px;" />
              </h2>
              <b-row>
                <b-col cols="3" class="description">
                  <span v-html="StdGradDescription" />
                </b-col>
                <b-col
                  cols="4"
                  lg="4"
                  class="grad-chart-area"
                >
                  <visual-component
                    :visual-title="''"
                    :visual-description="''"
                    :chart-type="ChartTypes.GaugeChart"
                    :scale-data-points="[0, 91.1, 95, 100]"
                    :scale-data-points-label="[0, 91.1, 95, 100]"
                    :suppress-border="true"
                    :data-decoration="'%'"
                    :chart-data="StdGrad4YearData"
                  />
                </b-col>
                <b-col
                  cols="4"
                  lg="4"
                  class="grad-chart-area"
                >
                  <visual-component
                    :visual-title="''"
                    :visual-description="''"
                    :chart-type="ChartTypes.GaugeChart"
                    :scale-data-points="[0, 92.7, 96, 100]"
                    :scale-data-points-label="[0, 92.7, 96, 100]"
                    :suppress-border="true"
                    :data-decoration="'%'"
                    :chart-data="StdGrad5YearData"
                  />
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
        <span class="p-4" />
        <!-- CCR Graphs -->
        <b-row
          v-if="ShowCollegeReady()"
          class="p-4"
        >
          <b-col>
            <b-container fluid class="outside-border p-4">
              <h2 class="text-center">
                {{ StdCollegeTitle }}
              </h2>
              <b-row v-for="(data, index) in StdCollegeData" :key="index">
                <b-col v-if="ShowSubVisual(data)" cols="12" class="keep-break">
                  <b-row>
                    <b-col cols="4">
                      <div class="text-left pt-4">
                        <span class="chart-title">{{ data.lTitle }}</span><br />
                        <span class="description">{{ data.lDescription }}</span>
                      </div>
                    </b-col>
                    <b-col cols="7">
                      <visual-component
                        class="sub-charts-print"
                        :visual-title="data.ChartTitle"
                        :chart-type="ChartTypes.HorizontalBarChart"
                        :suppress-border="true"
                        :chart-data="data"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </div>
      <!-- Page 2 Footer -->
      <b-row
        v-if="ShowCollegeReady()"
        class="mt-3"
        style="border-top: 2px solid #426580;"
      >
        <b-col cols="2" class="m-auto footer-title">
          <div>
            {{ FooterTitle }}
          </div>
        </b-col>
        <b-col cols="10">
          <b-container fluid class="">
            <summary-footer />
          </b-container>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SchoolDistrictInformation from '../components/SchoolDistrictInformation.vue';
import VisualComponent from '../components/VisualComponent.vue';
import SummaryFooter from '../components/SummaryFooter.vue';
import Summary from '../mixins/detailComponents/summary.mixin';

export default {
  emits: ['close'],
  components: {
    SchoolDistrictInformation,
    VisualComponent,
    SummaryFooter,
  },
  mixins: [Summary],
  computed: {
    requestCount() {
      return this.$store.getters.RequestCount;
    },
    hasDoeNotes() {
      return false;
    },
  },
  watch: {
    requestCount(newValue) {
      if (newValue === 0) {
        setTimeout(() => {
          this.$children.forEach((child) => {
            if (child.enableprintMode) {
              child.enableprintMode();
            }
          });

          const visualComponents = this.$children.filter((o) => o.$vnode.tag.indexOf('visual-component') >= 0);
          visualComponents.forEach((comp) => {
            comp.enablePrintView();
          });
        }, 750);
      }
    },
  },
  created() {
    this.$store.dispatch('setPrintView', true);

    const printStyle = document.createElement('link');
    printStyle.setAttribute('rel', 'stylesheet');
    printStyle.setAttribute('type', 'text/css');
    printStyle.setAttribute('href', '/print-summary.css');
    document.head.appendChild(printStyle);
  },
  mounted() {
  },
};
</script>
<style>
@media print {
}
</style>
<style scoped>
@media print {
  @page {
    size: 8.5in 11in;
  }
  .map-print {
    height: 1.5in;
  }
  .description {
    font-size: 8pt;
  }
  p :deep(ul, ul) {
    margin-bottom: 0.05in !important;
  }
  h2 {
    margin-bottom: 0.05in !important;
  }
  .grad-chart-area :deep(.ChartSubRender) {
    display: none;
  }
  .sub-charts-print :deep(.ChartSubRender) {
    display: none;
  }
  .sub-charts-print :deep(.card.mt-print-0) {
    margin-top: 1rem !important;
  }
  /* .grad-chart-area :deep(.card-body) {
    padding: 0;
  } */
}
.footer-title {
  font-weight: bold;
  font-size: 12pt;
  color: #fff;
  background: #426580;
}
.footer-title div {
  padding: .25in 0;
}
.print-container {
  width: 12in !important;
  /* max-width: 8.5in !important; */
  height: 13in !important;
}
.print-target {
  /* min-width: 12.25in !important;
  max-width: 12.25in !important; */
  width: 12in !important;
  height: 14in !important;
  margin: 0 .25in !important;
  padding: 0 !important;
}
.description {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
  text-align: left;
}
.chart-title {
  font-weight: bolder;
}
.circle {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 2px;
  background: #426580;
  border: 3px solid #426580;
  color: #fff;
  text-align: center;
}
.icon-shade {
  color: #426580;
}
</style>
