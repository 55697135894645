<template>
  <div role="navigation" class="bar nav-background">
    <b-navbar toggleable="lg">
      <b-navbar-brand class="ml-1 mr-lg-auto pt-0">
        <span
          v-if="$route.name === 'Home'"
          class="bar-label first-item"
        >School Year </span>
        <b-select
          v-if="$route.name === 'Home'"
          v-model="selectedSchoolYear"
          :options="schoolYears"
          size="sm"
        />
        <span
          v-if="$route.name !== 'Home'"
          class="pl-4 home d-lg-none"
        >
          <b-link :to="{ name: 'Home' }" class="text-white">
            <font-awesome-icon :icon="['fa', 'home']" />
          </b-link>
        </span>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-resource-collapse">
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="chevron-bar-up" />
          <b-icon v-else icon="chevron-bar-down" />
        </template>
      </b-navbar-toggle>

      <b-collapse id="nav-resource-collapse" class="nav-background" is-nav>
        <b-nav-item
          v-if="$route.name !== 'Home'"
          class="nav-background bar-label mx-auto"
          href="/"
        >
          Home
        </b-nav-item>
        <b-nav-item-dropdown text="Resource Documents" class="nav-background bar-label ml-auto">
          <!-- <b-dropdown-item class="bar-label" :href="`${documents}`
          + shortschoolYear + `/ReferenceGuide.pdf`" target="_blank">
            Reference Guide
          </b-dropdown-item> -->
          <b-dropdown-item class="bar-label" href="/Documents/ReferenceGuide.pdf" target="_blank">
            Reference Guide
          </b-dropdown-item>
          <!--  <b-dropdown-item class="bar-label"
         href="/Documents/1819/SummaryReportGuide.pdf" target="_blank">
            Summary Report Guide
          </b-dropdown-item> -->
          <!-- <b-dropdown-item class="bar-label" :href="`${documents}`
          + shortschoolYear +`/FAQs.pdf`" target="_blank">
            Frequently Asked Questions
          </b-dropdown-item> -->
          <b-dropdown-item class="bar-label" href="/Documents/FAQs.pdf" target="_blank">
            Frequently Asked Questions
          </b-dropdown-item>
          <b-dropdown-item class="bar-label" :to="{ name: 'Additional' }">
            All Resources
          </b-dropdown-item>
          <!-- <b-dropdown-item class="bar-label" :href="`${documents}`
          + shortschoolYear +`/DataPrivacyRules.pdf`" target="_blank">
            Data Privacy Rules (FERPA)
          </b-dropdown-item> -->
          <b-dropdown-item
            class="bar-label"
            href="/Documents/DataPrivacyRules.pdf"
            target="_blank"
            rel="noopener noreferrer">
            Data Privacy Rules (FERPA)
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item
          class="nav-background bar-label mx-auto"
          href="https://www.surveymonkey.com/r/2022-23SPR"
          target="_blank"
          rel="noopener noreferrer">
          Take Feedback Survey
        </b-nav-item>
        <b-nav-item
          v-if="showDownload"
          class="nav-background bar-label mx-auto"
          :to="{ name: 'DownloadData' }"
        >
          Download Data
        </b-nav-item>
        <b-nav-item class="nav-background bar-label mx-auto" href="mailto:reportcard@doe.nj.gov">
          Contact Us
        </b-nav-item>
        <b-nav-item v-if="isValid" class="nav-background bar-label mx-auto" @click="toggleLanguage">
          <font-awesome-icon :icon="['fa', 'globe-americas']" />
          Language: {{ languageDisplay }}
        </b-nav-item>
      </b-collapse>
    </b-navbar>
    <b-container v-if="false" fluid>
      <b-row>
        <b-col>
          <span class="bar-label">School Year </span>
          <b-select
            v-model="selectedSchoolYear"
            :options="schoolYears"
            size="sm"
          />
        </b-col>
        <b-col>
          <span
            class="bar-label"
            @mouseover="toggleResourceDocs"
            @focusin="toggleResourceDocs"
            @mouseout="toggleResourceDocs"
            @focusout="toggleResourceDocs"
          >Resource Documents <b-icon-caret-down-fill />
          </span>
          <div
            v-show="showResourceDocs"
            class="resource-popup"
            @mouseenter="toggleResourceDocs"
            @focusin="toggleResourceDocs"
            @mouseleave="toggleResourceDocs"
            @focusout="toggleResourceDocs"
          >
            <a href="test">Reference Guide</a>
            <a href="test">Summary Report Guide</a>
            <a href="test">Frequently Asked Questions</a>
            <a href="test">Additional Resources</a>
            <a href="test">Data Privacy Rules (FERPA)</a>
          </div>
        </b-col>
        <b-col>
          <a
            href="https://www.surveymonkey.com/r/2021-22SPR"
            class="bar-label"
            target="_blank"
            rel="noopener noreferrer">
            Take Feedback Survey</a>
        </b-col>
        <b-col>
          <b-link class="bar-label" to="/Download">
            Download Data
          </b-link>
        </b-col>
        <b-col>
          <a href="mailto:reportcard@doe.nj.gov" class="bar-label">Contact Us</a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TranslationService from '../services/translation.service';

export default {
  name: 'ResourceBar',
  data() {
    return {
      showResourceDocs: false,
      languageCodes: TranslationService.Constants.LanguageOptions,
    };
  },
  computed: {
    languageCode() {
      return this.$store.getters.CurrentLanguage;
    },
    languageDisplay() {
      return this.languageCode === this.languageCodes.English ? 'Eng' : 'Esp';
    },
    schoolYears() {
      return this.$store.getters.CurrentSchoolYears;
    },
    selectedSchoolYear: {
      get() {
        return this.$store.getters.SelectedSchoolYear ? this.$store.getters.SelectedSchoolYear.value : '';
      },
      set(value) {
        this.$store.dispatch('setSelectedSchoolYear', value);
        this.$emit('schoolYearChanged', value);
      },
    },
    showDownload() {
      return this.$store.getters.SelectedSchoolYear
        && !this.$store.getters.SelectedSchoolYear.embargo;
    },
    schoolYear() {
      return this.$store.getters.SelectedSchoolYear ? this.$store.getters.SelectedSchoolYear.text : '';
    },
    shortschoolYear() {
      return this.$store.getters.SelectedSchoolYear ? this.$store.getters.SelectedSchoolYear.value : '';
    },
    isValid() {
      if (process.env.VUE_APP_SHOW_SPANISH_REPORTS !== 'Yes'
        && this.$store.getters.SelectedSchoolYear.embargo) {
        return false;
      }
      switch (this.$route.name) {
        case 'SummaryReport':
        case 'DetailReport':
        case 'Overview':
        case 'Demographics':
        case 'StudentGrowth':
        case 'AcademicAchievement':
        case 'CollegeCareer':
        case 'PostSecondary':
        case 'Climate':
        case 'Staff':
        case 'PerPupil':
        case 'Accountability':
        case 'Narrative':
        case 'Default':
          return true;
        default:
          return false;
      }
    },
    documents() {
      return process.env.VUE_APP_DOCUMENTS;
    },
    referenceGuide() {
      return process.env.VUE_APP_REFERENCE_GUIDE;
    },
  },
  methods: {
    toggleLanguage() {
      this.$store.dispatch(
        'setCurrentLanguage',
        this.languageCode === TranslationService.Constants.LanguageOptions.English
          ? TranslationService.Constants.LanguageOptions.Spanish
          : TranslationService.Constants.LanguageOptions.English,
      );
    },
    toggleResourceDocs() {
      this.showResourceDocs = !this.showResourceDocs;
    },
  },
};
</script>

<style>
.bar .bar-label {
  display: inline-block;
  font-weight: bold;
  color: #F3EEEE;
}
.bar-label:hover {
  color: #F3EEEE;
}
.bar.nav-background .dropdown-menu {
  background-color: #426580 !important;
}
.bar .dropdown-menu {
  margin-left: 10%;
}
.bar .dropdown-item {
  color: #F3EEEE;
}
</style>
<style scoped>
.navbar {
  background-color: #426580;
  height: 38px;
  max-height: 36px;
  min-height: 36px;
  padding: 0;
}
.nav-background {
  background-color: #426580;
  z-index: 1;
}
.navbar-toggler {
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #426580;
  color: #F3EEEE !important;
  border-color: #F3EEEE !important;
}
.bar {
  height: 38px;
  max-height: 36;
  min-height: 36px;
  width: 100%;
  padding: 0 8%;
}
.bar-label.first-item {
  display: inline-block;
  padding-right: 5px;
}
.bar-label, .bar-label :deep(.nav-link) {
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #F3EEEE;
  padding: 0.1rem 0.1rem;
}
.bar-label > .dropdown-item {
  display: block;
}
.bar-label .nav-link, .nav-item > .nav-link {
  text-decoration: none !important;
  color: #F3EEEE !important;
}
select {
  width: 114px;
  margin-top: 3px;
  border-radius: 4px;
}
.resource-popup {
  width: 300px;
  max-width: 100%;
  position: absolute;
  background-color: #426580;
  margin-left: 10px;
  text-align: left;
  z-index: 1;
}
.resource-popup a:first {
  padding-top: 2px;
}
.resource-popup a {
  display: block;
  font-size: 16px;
  padding-bottom: 2px;
  text-decoration: none;
  color: #F3EEEE;
  padding-left: 4px;
}
.resource-popup a:hover {
  font-size: 18px;
  padding-bottom: 0;
  font-weight: bolder;
  text-decoration: none;
  background-color: #F3EEEE;
  color: #426580;
  padding-left: 4px;
}
.home {
  font-size: 20px;
  color: #fff;
  vertical-align: bottom;
}
</style>
