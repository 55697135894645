<template>
  <div>
    <b-container fluid>
      <!-- <div class="intro" v-html="GraduationRateTrendsVisualIntro"></div> -->
      <b-row v-if="!HideGradHeader" class="pt-4 d-print-none">
        <b-col>
          <section-header :id="GradId" :header-text="GradSectionHeader" />
        </b-col>
      </b-row>
      <b-row v-if="!HideGraduationRateTrends" class="pb-4">
        <!-- Graduation Rate Trends -->
        <b-col>
          <div :id="GradId" class="intro" v-html="GraduationRateTrendsVisualIntro" />
          <b-card
            :id="`${GraduationRateTrendsId}-print`"
            ref="GradRateTrends"
            :class="'outside-border'"
          >
            <b-card-header class="d-none d-print-block">
              {{ gradRateTrendPrintDisplay }}
            </b-card-header>
            <b-row :id="GraduationRateTrendsId">
              <b-col>
                <h3>{{ GraduationRateTrendsVisualTitle }}</h3>
              </b-col>
              <b-col cols="1" class="d-none d-lg-block">
                <a
                  class="small-icon d-print-none"
                  :title="GraduationRateTrendsVisualTitle"
                  @click="printGradRateTrends"
                  @keydown="printGradRateTrends"
                >
                  <font-awesome-icon :icon="['fas', 'download']">
                    <span class="sr-only">Download Icon</span>
                  </font-awesome-icon>
                </a>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p v-html="GraduationRateTrendsvisualDescription" />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="1" class="d-print-none" />
              <b-col col md="5" sm="12" class="border-right-only mx-2">
                <visual-component
                  ref="GradRateTrend4Year"
                  :visual-title="GraduationRateTrendsVisual4YearTitle"
                  :suppress-border="true"
                  :suppress-print-button="true"
                  :visual-description="''"
                  :chart-type="ChartTypes.LineChart"
                  :chart-data="GraduationRateTrendsVisual4YearData"
                />
              </b-col>
              <b-col cols="1" class="d-none d-print-block" />
              <b-col col md="5" sm="12" class="mx-2">
                <visual-component
                  ref="GradRateTrend5Year"
                  :visual-title="GraduationRateTrendsVisual5YearTitle"
                  :suppress-border="true"
                  :suppress-print-button="true"
                  :visual-description="''"
                  :chart-type="ChartTypes.LineChart"
                  :chart-data="GraduationRateTrendsVisual5YearData"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <visual-component
                  v-if="$store.getters.SelectedSchoolYear.value == 2021"
                  ref="GradRateTrendTable"
                  :visual-title="''"
                  :suppress-border="true"
                  :suppress-print-button="true"
                  :visual-description="''"
                  :table-shading="GraduationRateTrendsVisualShading"
                  :chart-type="ChartTypes.TableGrid"
                  :chart-data="GraduationRateTrendsVisualData"
                  :vertical-lines="['Cohort 2021 4-Year Rate', 'Cohorte 2021 Tasa de 4 años']"
                />
                <visual-component
                  v-if="$store.getters.SelectedSchoolYear.value == 2122"
                  ref="GradRateTrendTable"
                  :visual-title="''"
                  :suppress-border="true"
                  :suppress-print-button="true"
                  :visual-description="''"
                  :table-shading="GraduationRateTrendsVisualShading"
                  :chart-type="ChartTypes.TableGrid"
                  :chart-data="GraduationRateTrendsVisualData"
                  :vertical-lines="['Cohort 2022 4-Year Rate', 'Cohorte 2022 Tasa de 4 años']"
                />
                <visual-component
                  v-if="$store.getters.SelectedSchoolYear.value == 2223"
                  ref="GradRateTrendTable"
                  :visual-title="''"
                  :suppress-border="true"
                  :suppress-print-button="true"
                  :visual-description="''"
                  :table-shading="GraduationRateTrendsVisualShading"
                  :chart-type="ChartTypes.TableGrid"
                  :chart-data="GraduationRateTrendsVisualData"
                  :vertical-lines="['Cohort 2023 4-Year Rate', 'Cohorte 2023 Tasa de 4 años']"
                />
                <visual-component
                  v-if="$store.getters.SelectedSchoolYear.value == 2324"
                  ref="GradRateTrendTable"
                  :visual-title="''"
                  :suppress-border="true"
                  :suppress-print-button="true"
                  :visual-description="''"
                  :table-shading="GraduationRateTrendsVisualShading"
                  :chart-type="ChartTypes.TableGrid"
                  :chart-data="GraduationRateTrendsVisualData"
                  :vertical-lines="['Cohort 2024 4-Year Rate', 'Cohorte 2024 Tasa de 4 años']"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="!HideCohort4Year" class="pt-4">
        <!-- COHORT 4 Year -->
        <b-col>
          <visual-component
            v-if="!HideCohort4Year"
            :id="Cohort4YearId"
            :visual-title="Cohort4YearTitle"
            :visual-description="Cohort4YearDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="Cohort4YearData"
            :horizontal-lines="['White', 'Blanco', 'Female', 'Femenino',
                                'Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja']"
            :vertical-lines="['Non-Continuing Students',
                              'Estudiantes que no son de Educación Continua',
                              'Estudiantes no continuos']"
          />
        </b-col>
      </b-row>
      <b-row v-if="!HideCohort5Year" class="pt-4">
        <!-- COHORT 5 Year -->
        <b-col>
          <visual-component
            v-if="!HideCohort5Year"
            :id="Cohort5YearId"
            :visual-title="Cohort5YearTitle"
            :visual-description="Cohort5YearDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="Cohort5YearData"
            :horizontal-lines="['White', 'Blanco', 'Female', 'Femenino',
                                'Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja']"
            :vertical-lines="['Non-Continuing Students',
                              'Estudiantes que no son de Educación Continua',
                              'Estudiantes no continuos']"
          />
        </b-col>
      </b-row>
      <b-row v-if="!HideCohort6Year" class="pt-4">
        <!-- COHORT 6 Year -->
        <b-col>
          <visual-component
            v-if="!HideCohort6Year"
            :id="Cohort6YearId"
            :visual-title="Cohort6YearTitle"
            :visual-description="Cohort6YearDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="Cohort6YearData"
            :horizontal-lines="['White', 'Blanco', 'Female', 'Femenino',
                                'Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja']"
            :vertical-lines="['Non-Continuing Students',
                              'Estudiantes que no son de Educación Continua',
                              'Estudiantes no continuos']"
          />
        </b-col>
      </b-row>
      <b-row v-if="!HideFederalGradRates" class="pt-4">
        <!-- Federal Grad Rates -->
        <b-col>
          <visual-component
            v-if="!HideFederalGradRates
              && $store.getters.SelectedSchoolYear.value == 2021"
            :id="FederalGradRatesId"
            :visual-title="FederalGradRatesTitle"
            :visual-description="FederalGradRatesDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="FederalGradRatesData"
            :horizontal-lines="['White', 'Blanco', 'Female', 'Femenino',
                                'Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja']"
            :vertical-lines="['2020 5-Year Federal Graduation Rate',
                              'Tasa de graduación federal de 5 años de 2020']"
          />
          <visual-component
            v-if="!HideFederalGradRates
              && $store.getters.SelectedSchoolYear.value == 2122"
            :id="FederalGradRatesId"
            :visual-title="FederalGradRatesTitle"
            :visual-description="FederalGradRatesDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="FederalGradRatesData"
            :horizontal-lines="['White', 'Blanco', 'Female', 'Femenino',
                                'Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja']"
            :vertical-lines="['2021 5-Year Federal Graduation Rate',
                              'Tasa de graduación federal de 5 años de 2021']"
          />
          <visual-component
            v-if="!HideFederalGradRates
              && $store.getters.SelectedSchoolYear.value == 2223"
            :id="FederalGradRatesId"
            :visual-title="FederalGradRatesTitle"
            :visual-description="FederalGradRatesDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="FederalGradRatesData"
            :horizontal-lines="['White', 'Blanco', 'Female', 'Femenino',
                                'Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja']"
            :vertical-lines="['2022 5-Year Federal Graduation Rate',
                              'Tasa de graduación federal de 5 años de 2022']"
          />
          <visual-component
            v-if="!HideFederalGradRates
              && $store.getters.SelectedSchoolYear.value == 2324"
            :id="FederalGradRatesId"
            :visual-title="FederalGradRatesTitle"
            :visual-description="FederalGradRatesDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="FederalGradRatesData"
            :horizontal-lines="['White', 'Blanco', 'Female', 'Femenino',
                                'Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja']"
            :vertical-lines="['2023 5-Year Federal Graduation Rate',
                              'Tasa de graduación federal de 5 años de 2023']"
          />
        </b-col>
      </b-row>
      <b-row v-if="!HideAccountabilityGradRates" class="pt-4">
        <!-- Accountability -->
        <b-col>
          <!-- -->
          <!-- <div class="intro pt-4" v-html="GradRateIntro"></div> -->
          <!-- TODO: Horizontal/Vertical Lines will need to be
          refactored to a Computed for next year -->
          <visual-component
            v-if="!HideAccountabilityGradRates
              && $store.getters.SelectedSchoolYear.value == 2021"
            :id="AccountabilityGradRatesId"
            :visual-title="AccountabilityGradRatesTitle"
            :visual-description="AccountabilityGradRatesDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="AccountabilityGradRatesData"
            :table-shading="AccountabilityGradRatesShading"
            :horizontal-lines="['White', 'Blanco', 'Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja']"
            :vertical-lines="['Cohort 2020: Met Target', 'Cohorte 2020: Objetivo cumplido']"
          />
          <visual-component
            v-if="!HideAccountabilityGradRates
              && $store.getters.SelectedSchoolYear.value == 2122"
            :id="AccountabilityGradRatesId"
            :visual-title="AccountabilityGradRatesTitle"
            :visual-description="AccountabilityGradRatesDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="AccountabilityGradRatesData"
            :table-shading="AccountabilityGradRatesShading"
            :horizontal-lines="['White', 'Blanco', 'Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja']"
            :vertical-lines="['Cohort 2021: Met Target', 'Cohorte 2021: Objetivo cumplido']"
          />
          <visual-component
            v-if="!HideAccountabilityGradRates
              && $store.getters.SelectedSchoolYear.value == 2223"
            :id="AccountabilityGradRatesId"
            :visual-title="AccountabilityGradRatesTitle"
            :visual-description="AccountabilityGradRatesDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="AccountabilityGradRatesData"
            :table-shading="AccountabilityGradRatesShading"
            :horizontal-lines="['White', 'Blanco', 'Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja']"
            :vertical-lines="['Cohort 2022: Met Target', 'Cohorte 2022: Objetivo cumplido']"
          />
          <visual-component
            v-if="!HideAccountabilityGradRates
              && $store.getters.SelectedSchoolYear.value == 2324"
            :id="AccountabilityGradRatesId"
            :visual-title="AccountabilityGradRatesTitle"
            :visual-description="AccountabilityGradRatesDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="AccountabilityGradRatesData"
            :table-shading="AccountabilityGradRatesShading"
            :horizontal-lines="['White', 'Blanco', 'Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja']"
            :vertical-lines="['Cohort 2023: Met Target', 'Cohorte 2023: Objetivo cumplido']"
          />
        </b-col>
      </b-row>
      <b-row v-if="!HideGradPathways" class="pt-4">
        <!-- Grad Pathways -->
        <b-col>
          <visual-component
            v-if="!HideGradPathways
              && $store.getters.SelectedSchoolYear.value <= 2122"
            :id="GradPathwaysId"
            :visual-title="GradPathwaysTitle"
            :visual-description="GradPathwaysDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="GradPathwaysData"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="$store.getters.SelectedSchoolYear.value == 2223
          && !HideGradPathways"
        class="pt-4">
        <!-- Grad Pathways -->
        <b-col>
          <b-card
            :id="GradPathwaysId"
            :class="'outside-border'"
          >
            <b-card-header class="d-none d-print-block">
              {{ printDisplay }}
            </b-card-header>
            <b-row :id="GradPathwaysId">
              <b-col>
                <h3>{{ GradPathwaysTitle }}</h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p v-html="GradPathwaysDescription" />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="!HideDropout" class="pt-4">
        <!-- Dropout Rate -->
        <b-col>
          <visual-component
            v-if="!HideDropout"
            :id="DropoutId"
            :visual-title="DropoutTitle"
            :visual-description="DropoutDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="DropoutData"
          />
        </b-col>
      </b-row>
      <!-- Post Secondary Enrollment Start -->
      <b-row
        class="pt-4 d-print-none"
      >
        <b-col>
          <section-header :id="PostSecEnrollId" :header-text="PostSecondarySectionHeader" />
        </b-col>
      </b-row>
      <b-row
        class="py-4"
      >
        <b-col>
          <span v-html="PostSecEnrollIntro" />
          <b-card
            v-if="!HidePostSecEnrollSummaryTable
              && !HidePostSecEnrollSummaryVisual
              && $store.getters.SelectedSchoolYear.value <= 2122"
            :id="`${PostSecEnrollSummaryId}-print`"
            ref="PostSecEnroll"
            class="outside-border pt-4"
          >
            <b-card-header class="d-none d-print-block">
              {{ postSecEnrollPrintDisplay }}
            </b-card-header>
            <b-row :id="PostSecEnrollSummaryId">
              <b-col>
                <h3>{{ PostSecEnrollSummaryTitle }}</h3>
              </b-col>
              <b-col cols="1" class="d-none d-lg-block">
                <a
                  class="small-icon d-print-none"
                  :title="PostSecEnrollSummaryTitle"
                  @click="printPostSecEnrollSummary"
                  @keydown="printPostSecEnrollSummary"
                >
                  <font-awesome-icon :icon="['fas', 'download']">
                    <span class="sr-only">Download Icon</span>
                  </font-awesome-icon>
                </a>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p v-html="PostSecEnrollSummaryDescription" />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <visual-component
                  v-if="!HidePostSecEnrollSummaryVisual"
                  ref="PostSecEnrollSummaryChart"
                  :visual-title="''"
                  :visual-description="''"
                  :chart-type="ChartTypes.StackedBarChart"
                  :chart-data="PostSecEnrollSummaryVisualData"
                  :suppress-border="true"
                  :suppress-print-button="true"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <visual-component
                  v-if="!HidePostSecEnrollSummaryTable"
                  ref="PostSecEnrollSummaryTable"
                  :visual-title="''"
                  :visual-description="''"
                  :chart-type="ChartTypes.TableGrid"
                  :chart-data="PostSecEnrollSummaryTableData"
                  :suppress-border="true"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        v-if="!HidePostSecEnrollFallVisual"
        class="pt-4"
      >
        <b-col>
          <visual-component
            v-if="!HidePostSecEnrollFallVisual"
            :id="PostSecEnrollFallId"
            :visual-description="PostSecEnrollFallDescription"
            :chart-type="ChartTypes.TableGrid"
            :visual-title="PostSecEnrollFallTitle"
            :chart-data="PostSecEnrollFallData"
            :horizontal-lines="['White', 'Blanco',
                                'Statewide', 'Toda la Estado', 'Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja',
                                'Female', 'Femenino']"
            :vertical-lines="['% Enrolled in Any Institution',
                              '% de Inscritos en Cualquier Institución']"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="!HidePostSecEnroll16MonthVisual"
        class="pt-4"
      >
        <b-col>
          <visual-component
            v-if="!HidePostSecEnroll16MonthVisual"
            :id="PostSecEnroll16MonthId"
            :visual-title="PostSecEnroll16MonthTitle"
            :visual-description="PostSecEnroll16MonthDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="PostSecEnroll16MonthData"
            :horizontal-lines="['White', 'Blanco',
                                'Statewide', 'Toda la Estado', 'Economically Disadvantaged Students',
                                'Estudiantes Económicamente en Desventaja',
                                'Female', 'Femenino']"
            :vertical-lines="['% Enrolled in Any Institution',
                              '% de Inscritos en Cualquier Institución',
                              '% of Enrolled in 4-Year Institution', '% de Inscritos en Institución de 4 años',
                              '% of Enrolled in Private Institution', '% de Inscritos en Institución Privada']"
          />
        </b-col>
      </b-row>
      <b-row v-if="!HideApprenticeship" class="pb-4">
        <!--Apprenticeship -->
        <b-col>
          <visual-component
            v-if="!HideApprenticeship"
            :id="ApprenticeshipId"
            :visual-title="ApprenticeshipTitle"
            :visual-description="ApprenticeshipDescription"
            :chart-type="ChartTypes.TableGrid"
            :chart-data="ApprenticeshipData"
          />
        </b-col>
      </b-row>
    </b-container>
    <!-- Spacer needed for iphone 14 pro max -->
    <b-container fluid class="pt-4">
      <b-row class="pt-4">
        <b-col class="pt-4">
          <br />
          <br />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import VisualComponent from '../../components/VisualComponent.vue';
import ChartTypes from '../../components/ChartTypes';
import SectionHeader from '../../components/SectionHeader.vue';
// eslint-disable-next-line import/no-cycle
import grad from '../../mixins/detailComponents/grad.mixin';
import translation from '../../mixins/translation-service.mixin';
import hiddenLogic from '../../mixins/hidden.mixin';
import inPageNav from '../../mixins/in-page-nav.mixin';

export default {
  name: 'SchoolGrad',
  components: {
    VisualComponent,
    SectionHeader,
  },
  mixins: [grad, translation, hiddenLogic, inPageNav],
  data() {
    return {
      ChartTypes,
      gradRateTrendPrintDisplay: '',
      postSecEnrollPrintDisplay: '',
    };
  },
  methods: {
    printGradRateTrends() {
      this.$refs.GradRateTrends.style.width = '1008px';
      setTimeout(() => {
        this.$refs.GradRateTrend4Year.enablePrintView();
        this.$refs.GradRateTrend5Year.enablePrintView();
        this.$refs.GradRateTrendTable.enablePrintView();

        this.gradRateTrendPrintDisplay = this.$refs.GradRateTrend4Year.printDisplay;
        this.$nextTick(() => {
          this.$htmlToPaper(`${this.GraduationRateTrendsId}-print`);

          this.$refs.GradRateTrend4Year.disablePrintView();
          this.$refs.GradRateTrend5Year.disablePrintView();
          this.$refs.GradRateTrendTable.disablePrintView();
          this.$refs.GradRateTrends.style.width = '';
        });
      }, 100);
    },
    printPostSecEnrollSummary() {
      this.$refs.PostSecEnroll.style.width = '1008px';
      setTimeout(() => {
        this.$refs.PostSecEnrollSummaryChart.enablePrintView();
        this.$refs.PostSecEnrollSummaryTable.enablePrintView();

        this.postSecEnrollPrintDisplay = this.$refs.PostSecEnrollSummaryChart.printDisplay;
        this.$nextTick(() => {
          this.$htmlToPaper(`${this.PostSecEnrollSummaryId}-print`);

          this.$refs.PostSecEnrollSummaryChart.disablePrintView();
          this.$refs.PostSecEnrollSummaryTable.disablePrintView();
          this.$refs.PostSecEnroll.style.width = '';
        });
      }, 100);
    },
  },
};
</script>
<style>
  @media only screen and  (max-width: 885px) {
      /* show horizontal scroll for table on small screens */
      /**Grad - this page has many tables that have
       column names that cannot wrap less than 885px*/
      .table-parent {
          overflow-x: scroll;
          overflow-y: hidden;
          max-width: 750px!important;
        }
    }
</style>
<style scoped>
.intro{
  text-align: left;
}
.outside-border{
  border: 2px solid #287AB8;
}
@media (min-width: 768px) {
  /*show right border only on medium and up */
  .border-right-only{
  border-right: 2px solid #287AB8;
  }
}

.small-icon{
  padding: 0px;
  padding-right: 5px;
  margin-left: -15px;
  margin-top: 12px;
  font-size: 26px;
  text-align: left;
  color: #7B7474;
  cursor: pointer;
}
</style>
