<template>
  <div>
    <b-container id="print-target">
      <div class="print-container">
        <school-district-information />
        <div class="section-header">
          {{ getSectionHeaderText('Overview') }}
        </div>
        <b-row class="pt-4 alignment print-section">
          <b-col>
            <span v-html="OverviewIntro" />
          </b-col>
        </b-row>
      </div>
      <div v-if="ReportType !== 'state'" class="page-break" />
      <div v-if="ReportType !== 'state'" class="print-container">
        <!-- Overview 2 -->
        <print-header>
          {{ getSectionHeaderText('Overview') }}
        </print-header>
        <b-row v-if="ReportType !== 'state'" class="text-center">
          <b-col>
            <visual-component
              v-if="HideTypeAndContactData"
              :id="TypeAndContactId"
              :visual-title="TypeAndContactTitle"
              :visual-description="TypeAndContactDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="TypeAndContactData"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <div
          v-for="(block, index) in schoolsInDistrictBlockCount"
          :key="`Overview_Schools_${index}`"
          class="print-container page-break"
        >
          <print-header>
            {{ getSectionHeaderText('Overview') }}
          </print-header>
          <b-row>
            <b-col>
              <visual-component
                :visual-title="SchoolsInDistrictTitle"
                :visual-description="SchoolsInDistrictDescription"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="schoolsInDistrictBlock(index)"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="page-break" />
      <div class="print-container">
        <!-- Demographics 1 -->
        <print-header>
          {{ getSectionHeaderText('Demographics') }}
        </print-header>
        <b-row>
          <b-col>
            <span style="text-align: left;" v-html="PageHeader" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <visual-component
              :visual-title="EnrollmentByGradeTitle"
              :visual-description="EnrollmentByGradeDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="EnrollmentByGradeData"
              :suppress-print-button="true"
              :horizontal-lines="['Total']"
            />
          </b-col>
          <b-col cols="8">
            <visual-component
              :visual-title="EnrollmentByStudentGroupTitle"
              :visual-description="EnrollmentByStudentGroupDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="EnrollmentByStudentGroupData"
              :suppress-print-button="true"
              :horizontal-lines="['Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja']"
              :table-shading="EnrollmentByStudentGroupTableShading"
            />
            <visual-component
              :visual-title="EnrollmentByRaceTitle"
              :visual-description="EnrollmentByRaceDescription"
              :suppress-print-button="true"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="EnrollmentByRaceData"
            />
          </b-col>
        </b-row>
      </div>
      <div
        v-if="!(HideEnrollmentByDayVisual
          && HideEnrollmentByTimeVisual)"
        class="page-break"
      />
      <!-- Demographics 2 -->
      <div
        v-if="!(HideEnrollmentByDayVisual && HideEnrollmentByTimeVisual)"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('Demographics') }}
        </print-header>
        <b-row>
          <b-col>
            <span v-html="PageHeader" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideEnrollmentByDayVisual"
              :visual-title="EnrollmentByDayTitle"
              :visual-description="EnrollmentByDayDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="EnrollmentByDayData"
              :suppress-print-button="true"
              :horizontal-lines="['KG - Half Day', 'KG - Medio Día']"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideEnrollmentByTimeVisual"
              :visual-title="EnrollmentByTimeTitle"
              :visual-description="EnrollmentByTimeDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="EnrollmentByTimeData"
              :suppress-print-button="true"
              :horizontal-lines="['Full Time Equivalent', 'Equivalente a Tiempo Completo']"
            />
          </b-col>
        </b-row>
      </div>
      <div class="page-break" />
      <div class="print-container">
        <!-- Demographics 3 -->
        <print-header>
          {{ getSectionHeaderText('Demographics') }}
        </print-header>
        <b-row>
          <b-col>
            <span v-html="PageHeader" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              :id="EnrollmentByLanguageId"
              :visual-title="EnrollmentByLanguageTitle"
              :visual-description="EnrollmentByLanguageDescription"
              :chart-type="ChartTypes.BarChart"
              :suppress-legend="true"
              :chart-data="EnrollmentByLanguageData"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showSection('StudentGrowth')" class="page-break" />
      <div v-if="showSection('StudentGrowth')" class="print-container">
        <!-- Student Growth 1 -->
        <print-header>
          {{ getSectionHeaderText('StudentGrowth') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro" v-html="StdGrowthIntro" />
          </b-col>
        </b-row>
        <!-- NOT FOR 21-22 -->
        <b-row
          v-if="$store.getters.SelectedSchoolYear.value < 2122
            || $store.getters.SelectedSchoolYear.value >= 2223"
        >
          <b-col>
            <b-card class="py-0" :class="'outside-border'" no-body>
              <b-row>
                <b-col>
                  <h3>{{ StdGrowthTrendTitle }}</h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p class="px-3" v-html="StdGrowthTrendDescription" />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" class="border-right-only">
                  <visual-component
                    ref="ELATrends"
                    :visual-title="StdGrowthELATrendTitle"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :chart-type="ChartTypes.LineChart"
                    :chart-data="StdGrowthELATrendData"
                  />
                </b-col>
                <b-col cols="6">
                  <visual-component
                    ref="MathTrends"
                    :visual-title="StdGrowthMathTrendTitle"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :chart-type="ChartTypes.LineChart"
                    :chart-data="StdGrowthMathTrendData"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <visual-component
                    v-if="$store.getters.SelectedSchoolYear.value < 2122
                      || $store.getters.SelectedSchoolYear.value >= 2223"
                    ref="ELAMathTable"
                    :visual-title="''"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :chart-type="ChartTypes.TableGrid"
                    :chart-data="StdGrowthTrendData"
                    :table-shading="StdGrowthTrendTableShading"
                    :vertical-lines="['2022-23 ELA']"
                  />
                  <visual-component
                    v-if="$store.getters.SelectedSchoolYear.value == 2122"
                    ref="ELAMathTable"
                    :visual-title="''"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :chart-type="ChartTypes.TableGrid"
                    :chart-data="StdGrowthTrendData"
                    :table-shading="StdGrowthTrendTableShading"
                    :vertical-lines="['2021-22 ELA']"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="false && showSection('StudentGrowth')
          && $store.getters.SelectedSchoolYear.value == 2021"
        class="page-break"
      />
      <!-- Student Growth 2 -->
      <div
        v-if="false && showSection('StudentGrowth')
          && $store.getters.SelectedSchoolYear.value == 2021"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('StudentGrowth') }}
        </print-header>
        <b-row>
          <b-col>
            <b-card class="py-0" :class="'outside-border'" no-body>
              <b-row>
                <b-col>
                  <h3>{{ StdGrowthTrendTitle }}</h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p class="px-3" v-html="StdGrowthTrendDescription" />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" class="border-right-only">
                  <visual-component
                    ref="ELATrends"
                    :visual-title="StdGrowthELATrendTitle"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :chart-type="ChartTypes.LineChart"
                    :chart-data="StdGrowthELATrendData"
                  />
                </b-col>
                <b-col cols="6">
                  <visual-component
                    ref="MathTrends"
                    :visual-title="StdGrowthMathTrendTitle"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :chart-type="ChartTypes.LineChart"
                    :chart-data="StdGrowthMathTrendData"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <visual-component
                    v-if="$store.getters.SelectedSchoolYear.value == 2021"
                    ref="ELAMathTable"
                    :visual-title="''"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :chart-type="ChartTypes.TableGrid"
                    :chart-data="StdGrowthTrendData"
                    :table-shading="StdGrowthTrendTableShading"
                    :vertical-lines="['2020-21 ELA']"
                  />
                  <visual-component
                    v-if="$store.getters.SelectedSchoolYear.value == 2122"
                    ref="ELAMathTable"
                    :visual-title="''"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :chart-type="ChartTypes.TableGrid"
                    :chart-data="StdGrowthTrendData"
                    :table-shading="StdGrowthTrendTableShading"
                    :vertical-lines="['2021-22 ELA']"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="false && showSection('StudentGrowth')
          && $store.getters.SelectedSchoolYear.value == 2021"
        class="page-break"
      />
      <!-- Student Growth 3 -->
      <div v-if="showSection('StudentGrowth')" class="page-break" />
      <div
        v-if="showSection('StudentGrowth')
          && $store.getters.SelectedSchoolYear.value >= 2223"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('StudentGrowth') }}
        </print-header>
        <b-row>
          <b-col>
            <visual-component
              v-if="$store.getters.SelectedSchoolYear.value >= 2223"
              :id="StudentGrowthId"
              :visual-title="StdGrowthTitle"
              :visual-description="StdGrowthDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="StdGrowthData"
              :horizontal-lines="['White', 'Blanco',
                                  'Female', 'Femenino', 'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja',
              ]"
              :vertical-lines="['ELA: Met Standard (40-59.5)',
                                'ELA: Cumplió con el estándar (40-59.5)']"
              :table-shading="StdGrowthTableShading"
            />
          </b-col>
        </b-row>
      </div>
      <!-- Student Growth Pg 4-->
      <div v-if="showSection('StudentGrowth')" class="page-break" />
      <div
        v-if="showSection('StudentGrowth')
          && $store.getters.SelectedSchoolYear.value >= 2223"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('StudentGrowth') }}
        </print-header>
        <b-row>
          <b-col md="12" sm="12">
            <b-card
              v-if="$store.getters.SelectedSchoolYear.value >= 2223"
              class="outside-border"
            >
              <b-card-header class="d-none d-print-block">
                {{ printDisplay }}
              </b-card-header>
              <b-row>
                <b-col>
                  <h3>{{ StdGrowthPerfTitle }}</h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p v-html="StdGrowthPerfDescription" />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span style="color: #317bb4; text-align: center; font-size: 22px;">
                    <strong v-html="StdGrowthPerfLevelChartTitleEla" />
                  </span>
                  <visual-component
                    ref="ELAGrade"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :visual-description="''"
                    :chart-type="ChartTypes.BarChart"
                    :chart-data="StdGrowthELAPerfLevelData"
                    :chart-sub-title="StdGrowthMedianSubTitle"
                    :SuppressLegend="true"
                  />
                </b-col>
                <b-col>
                  <span style="color: #317bb4; text-align: center; font-size: 22px;">
                    <strong v-html="StdGrowthPerfLevelChartTitleMath" />
                  </span>
                  <visual-component
                    ref="MathPerfLevel"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :visual-description="''"
                    :chart-type="ChartTypes.BarChart"
                    :chart-data="StdGrowthMathPerfLevelData"
                    :chart-sub-title="StdGrowthMedianSubTitle"
                    :SuppressLegend="true"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <!-- Student Growth by Grade - 2 bar charts-->
      <div v-if="showSection('StudentGrowth')" class="page-break" />
      <div
        v-if="showSection('StudentGrowth')
          && $store.getters.SelectedSchoolYear.value >= 2223"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('StudentGrowth') }}
        </print-header>
        <b-row>
          <b-col md="12" sm="12">
            <b-card
              v-if="$store.getters.SelectedSchoolYear.value >= 2223"
              class="outside-border"
            >
              <b-card-header class="d-none d-print-block">
                {{ printDisplay }}
              </b-card-header>
              <b-row>
                <b-col>
                  <h3>{{ StdGrowthgradeTitle }}</h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p v-html="StdGrowthgradeDescription" />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span style="color: #317bb4; text-align: center; font-size: 22px;">
                    <strong v-html="StdGrowthgradeChartTitleEla" />
                  </span>
                  <visual-component
                    ref="ELAGrade"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :visual-description="''"
                    :chart-type="ChartTypes.BarChart"
                    :chart-data="StdGrowthELAGradeData"
                    :chart-sub-title="StdGrowthMedianSubTitle"
                    :SuppressLegend="true"
                  />
                </b-col>
                <b-col>
                  <span style="color: #317bb4; text-align: center; font-size: 22px;">
                    <strong v-html="StdGrowthgradeChartTitleMath" />
                  </span>
                  <visual-component
                    ref="MathGrade"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :visual-description="''"
                    :chart-type="ChartTypes.BarChart"
                    :chart-data="StdGrowthMathGradeData"
                    :chart-sub-title="StdGrowthMedianSubTitle"
                    :SuppressLegend="true"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <!-- Academic Achievement-->
      <div v-if="showAcademicPage2" class="page-break" />
      <div v-if="showAcademicPage2" class="print-container">
        <!-- Academic 2 -->
        <print-header>
          {{ getSectionHeaderText('AcademicAchievement') }}
        </print-header>
        <b-row>
          <b-col>
            <span v-html="AcadAchievementIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card class="py-0" :class="'outside-border'" no-body>
              <b-row :id="EnglishMathTrendId">
                <b-col>
                  <h3>{{ EnglishMathTrendTitle }}</h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p class="px-3" v-html="EnglishMathTrendDescription" />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" class="border-right-only px-4">
                  <visual-component
                    ref="ELATrends"
                    :visual-title="EnglishMathTrendELATitle"
                    :suppress-border="true"
                    :suppress-print-button="true"

                    :chart-type="ChartTypes.LineChart"
                    :chart-data="EnglishMathTrendELAData"
                  />
                </b-col>
                <b-col cols="6" class="px-4">
                  <visual-component
                    ref="MathTrends"
                    :visual-title="EnglishMathTrendMathTitle"
                    :suppress-border="true"
                    :suppress-print-button="true"

                    :chart-type="ChartTypes.LineChart"
                    :chart-data="EnglishMathTrendMathData"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <visual-component
                    ref="ELAMathTable"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :visual-footer="EnglishMathTrendPlusFooter"
                    :table-shading="EnglishMathTrendShading"
                    :chart-type="ChartTypes.TableGrid"
                    :chart-data="EnglishMathTrendData"
                    :vertical-lines="['2021-22 ELA']"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="showSection('AcademicAchievement')
          && !HideELAParticipationPerformance"
        class="page-break"
      />
      <!-- Academic 3 -->
      <div
        v-if="showSection('AcademicAchievement') && !HideELAParticipationPerformance"
        class="print-container"
        name="AcademicAchievement-3"
      >
        <print-header>
          {{ getSectionHeaderText('AcademicAchievement') }}
        </print-header>
        <b-row>
          <b-col>
            <span v-html="AcadAchievementIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="$store.getters.SelectedSchoolYear.value >= 2122"
              :id="ELAParticipationPerformanceId"
              :visual-title="ELAParticipationPerformanceTitle"
              :visual-description="ELAParticipationPerformanceDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="ELAParticipationPerformanceData"
              :visual-footer="ELAParticipationPerformancePlusFooter"
              :horizontal-lines="['White', 'Blanco',
                                  'Female', 'Femenino', 'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja',
                                  'Students with Disabilities', 'Estudiantes con Discapacidades',
                                  'English Learners', 'Estudiantes de inglés',
                                  'Homeless Students', 'Estudiantes sin hogar']"
              :table-shading="ELAParticipationPerformanceTableShading"
            />
          </b-col>
        </b-row>
      </div>
      <div
        v-if="showSection('AcademicAchievement')
          && !HideEnglishPerformance"
        class="page-break"
      />
      <!-- Academic 3 -->
      <div
        v-if="showSection('AcademicAchievement') && !HideEnglishPerformance"
        style="height: 900px;"
      >
        <print-header>
          {{ getSectionHeaderText('AcademicAchievement') }}
        </print-header>
        <b-row>
          <b-col>
            <span v-html="AcadAchievementIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideEnglishPerformance"
              :visual-title="EnglishPerformanceTitle"
              :visual-description="EnglishPerformanceDescription"
              :chart-type="ChartTypes.TripleBarChart"
              :chart-data="EnglishPerformanceData"
              :chart-sub-title="EnglishPerformanceChartSubTitle"
            />
          </b-col>
        </b-row>
      </div>
      <!-- Academic ELA PerfBy Grade -->
      <div v-for="(grade, index) in ELAPerfByGradeData" :key="`ELAPerfByGradeId_${index}`">
        <div class="page-break" />
        <div class="print-container" :name="`ELA_Perf_By_Grade_${index}`">
          <print-header>
            {{ getSectionHeaderText('AcademicAchievement') }}
          </print-header>
          <b-row>
            <b-col>
              <span v-html="AcadAchievementIntro" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <visual-component
                :visual-title="ELAPerfByGradeTitle(index)"
                :visual-description="ELAPerfByGradeDescription"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="grade"
                :header-shading="true"
                :horizontal-lines="['White', 'Blanco',
                                    'Female', 'Femenino', 'Economically Disadvantaged Students',
                                    'Estudiantes Económicamente en Desventaja',
                                    'Students with Disabilities', 'Estudiantes con Discapacidades',
                                    'English Learners', 'Estudiantes de inglés',
                                    'Homeless Students', 'Estudiantes sin hogar']"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- Academic Math Part and Perf -->
      <div
        v-if="showSection('AcademicAchievement')
          && !HideMathPartPerf"
        class="page-break"
      />
      <div
        v-if="showSection('AcademicAchievement')
          && !HideMathPartPerf"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('AcademicAchievement') }}
        </print-header>
        <b-row>
          <b-col>
            <span v-html="AcadAchievementIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              :visual-title="MathParticipationPerformanceTitle"
              :visual-description="MathParticipationPerformanceDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="MathParticipationPerformanceData"
              :visual-footer="MathParticipationPerformancePlusFooter"
              :horizontal-lines="['White', 'Blanco',
                                  'Female', 'Femenino', 'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja',
                                  'Students with Disabilities', 'Estudiantes con Discapacidades',
                                  'English Learners', 'Estudiantes de inglés',
                                  'Homeless Students', 'Estudiantes sin hogar']"
              :table-shading="MathParticipationPerformanceTableShading"
            />
          </b-col>
        </b-row>
      </div>
      <div
        v-if="showSection('AcademicAchievement')
          && !HideMathPerformance"
        class="page-break"
      />
      <!-- Academic 4 -->
      <div
        v-if="showSection('AcademicAchievement') && !HideMathPerformance"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('AcademicAchievement') }}
        </print-header>
        <b-row>
          <b-col>
            <span v-html="AcadAchievementIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideMathPerformance"
              :visual-title="MathPerformanceTitle"
              :visual-description="MathPerformanceDescription"
              :chart-type="ChartTypes.TripleBarChart"
              :chart-data="MathPerformanceData"
              :chart-sub-title="MathPerfChartSubTitle"
              :visual-footer="MathPerformancePlusFooter"
            />
          </b-col>
        </b-row>
      </div>
      <!-- Academic Math Perf by Grade -->
      <div
        v-for="(grade, index) in MathPerfByGradeData"
        :key="`MathPerfByGradeId_${index}`"
      >
        <div class="page-break" />
        <div class="print-container" :name="`Math_Perf_By_Grade_${index}`">
          <print-header>
            {{ getSectionHeaderText('AcademicAchievement') }}
          </print-header>
          <b-row>
            <b-col>
              <span v-html="AcadAchievementIntro" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <visual-component
                :visual-title="MathPerfByGradeTitle(index)"
                :visual-description="MathPerfByGradeDescription(index)"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="grade"
                :header-shading="true"
                :horizontal-lines="['White', 'Blanco',
                                    'Female', 'Femenino', 'Economically Disadvantaged Students',
                                    'Estudiantes Económicamente en Desventaja',
                                    'Students with Disabilities', 'Estudiantes con Discapacidades',
                                    'English Learners', 'Estudiantes de inglés',
                                    'Homeless Students', 'Estudiantes sin hogar']"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- Academic Math Perf by Test -->
      <div
        v-for="(grade, index) in MathPerfByTestData"
        :key="`MathPerfByTestId_${index}`"
      >
        <div class="page-break" />
        <div class="print-container">
          <print-header>
            {{ getSectionHeaderText('AcademicAchievement') }}
          </print-header>
          <b-row>
            <b-col>
              <span v-html="AcadAchievementIntro" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <visual-component
                :visual-title="MathPerfByTestTitle(index)"
                :visual-description="MathPerfByTestDescription(index)"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="grade"
                :header-shading="true"
                :horizontal-lines="['White', 'Blanco',
                                    'Female', 'Femenino', 'Economically Disadvantaged Students',
                                    'Estudiantes Económicamente en Desventaja',
                                    'Students with disabilities', 'Estudiantes con Discapacidades',
                                    'English learners', 'Estudiantes de inglés',
                                    'Homeless students', 'Estudiantes sin hogar']"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- Academic 5 -->
      <div v-if="showAcademicPage5" class="page-break" />
      <div v-if="showAcademicPage5" class="print-container">
        <print-header>
          {{ getSectionHeaderText('AcademicAchievement') }}
        </print-header>
        <b-row>
          <b-col>
            <span v-html="AcadAchievementIntro" />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="12">
            <visual-component
              v-if="!HideDLMAssessmentParticipation"
              :visual-title="DLMAssessmentParticipationTitle"
              :visual-description="DLMAssessmentParticipationDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="DLMAssessmentParticipationData"
            />
          </b-col>
        </b-row>
      </div>
      <div
        v-if="$store.getters.SelectedSchoolYear.value <= 2021
          && !HideEnglishProficiencyTest && !HideEnglishProficiency"
      >
        <div class="page-break" />
        <!-- Academic ELA Proficiency Test Part Perf-->
        <div class="print-container">
          <print-header>
            {{ getSectionHeaderText('AcademicAchievement') }}
          </print-header>
          <b-row>
            <b-col>
              <span v-html="AcadAchievementIntro" />
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="12">
              <visual-component
                v-if="$store.getters.SelectedSchoolYear.value <= 2021"
                :visual-title="EnglishProgressToProficiencyTitle"
                :visual-description="EnglishProgressToProficiencyDescription"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="EnglishProgressToProficiencyData"
              />
              <br />
              <visual-component
                v-if="!HideEnglishProficiencyTest
                  && $store.getters.SelectedSchoolYear.value <= 2021"
                :visual-title="EnglishProgressToProficiencyTestTitle"
                :visual-description="EnglishProgressToProficiencyTestDescription"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="EnglishProgressToProficiencyTestData"
              />
              <br />
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- Academic ELA Proficiency Test and Progress-->
      <div
        v-if="$store.getters.SelectedSchoolYear.value > 2021
          && !HideEnglishProficiencyTest && !HideEnglishProficiency"
      >
        <div class="page-break" />
        <div class="print-container">
          <print-header>
            {{ getSectionHeaderText('AcademicAchievement') }}
          </print-header>
          <b-row>
            <b-col>
              <span v-html="AcadAchievementIntro" />
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="12">
              <visual-component
                :visual-title="EnglishProgressToProficiencyTestTitle"
                :visual-description="EnglishProgressToProficiencyTestDescription"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="EnglishProgressToProficiencyTestData"
              />
              <visual-component
                :visual-title="EnglishProgressToProficiencyTitle"
                :visual-description="EnglishProgressToProficiencyDescription"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="EnglishProgressToProficiencyData"
                :visual-footer="EnglishProfPlusFooter"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="showAcademicPage6" class="page-break" />
      <!-- Academic 6 -->
      <div
        v-if="showAcademicPage6"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('AcademicAchievement') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="pt-4 intro" v-html="NJSLAScienceGradeIntro" />
          </b-col>
        </b-row>
        <b-row v-if="NJSLAScienceGradeSummaryData.length === 1">
          <b-col cols="12">
            <visual-component
              :visual-title="NJSLAScienceGradeSummaryTitle(0)"
              :visual-description="NJSLAScienceGradeSummaryDescription"
              :chart-type="ChartTypes.StackedBarChart"
              :chart-data="NJSLAScienceGradeSummaryData[0]"
            />
          </b-col>
        </b-row>
        <b-row v-if="NJSLAScienceGradeSummaryData.length === 2">
          <b-col cols="6">
            <visual-component
              :visual-title="NJSLAScienceGradeSummaryTitle(0)"
              :visual-description="NJSLAScienceGradeSummaryDescription"
              :chart-type="ChartTypes.StackedBarChart"
              :chart-data="NJSLAScienceGradeSummaryData[0]"
            />
          </b-col>
          <b-col cols="6">
            <visual-component
              :visual-title="NJSLAScienceGradeSummaryTitle(1)"
              :visual-description="NJSLAScienceGradeSummaryDescription"
              :chart-type="ChartTypes.StackedBarChart"
              :chart-data="NJSLAScienceGradeSummaryData[1]"
            />
          </b-col>
        </b-row>
        <b-row v-if="NJSLAScienceGradeSummaryData.length === 3">
          <b-col cols="6">
            <visual-component
              :visual-title="NJSLAScienceGradeSummaryTitle(0)"
              :visual-description="NJSLAScienceGradeSummaryDescription"
              :chart-type="ChartTypes.StackedBarChart"
              :chart-data="NJSLAScienceGradeSummaryData[0]"
            />
          </b-col>
          <b-col cols="6">
            <visual-component
              :visual-title="NJSLAScienceGradeSummaryTitle(1)"
              :visual-description="NJSLAScienceGradeSummaryDescription"
              :chart-type="ChartTypes.StackedBarChart"
              :chart-data="NJSLAScienceGradeSummaryData[1]"
            />
          </b-col>
        </b-row>
      </div>
      <div
        v-if="showAcademicPage6 && NJSLAScienceGradeSummaryData.length === 3"
        class="page-break"
      />
      <!-- Academic 6 - B -->
      <div
        v-if="showAcademicPage6 && NJSLAScienceGradeSummaryData.length === 3"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('AcademicAchievement') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="pt-4 intro" v-html="NJSLAScienceGradeIntro" />
          </b-col>
        </b-row>
        <b-row v-if="NJSLAScienceGradeSummaryData.length === 3">
          <b-col cols="12">
            <visual-component
              :visual-title="NJSLAScienceGradeSummaryTitle(2)"
              :visual-description="NJSLAScienceGradeSummaryDescription"
              :chart-type="ChartTypes.StackedBarChart"
              :chart-data="NJSLAScienceGradeSummaryData[2]"
            />
          </b-col>
        </b-row>
      </div>
      <!-- NJSLA Science By Grade - Tables -->
      <div
        v-for="(grade, index) in NJSLAPerfByGradeTableData"
        :key="`NJSLAPerfByGradeTableId_${index}`"
      >
        <div class="page-break" />
        <div v-if="showSection('AcademicAchievement')" class="print-container">
          <print-header>
            {{ getSectionHeaderText('AcademicAchievement') }}
          </print-header>
          <b-row>
            <b-col>
              <div class="pt-4 intro" v-html="NJSLAScienceGradeIntro" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <visual-component
                :visual-title="NJSLAPerfByGradeTableTitle(index)"
                :visual-description="NJSLAPerfByGradeTableDescription"
                :chart-type="ChartTypes.TableGrid"
                :chart-data="grade"
                :horizontal-lines="['White', 'Blanco',
                                    'Female', 'Femenino', 'Economically Disadvantaged Students',
                                    'Estudiantes Económicamente en Desventaja',
                                    'Students with Disabilities', 'Estudiantes con Discapacidades',
                                    'English Learners', 'Estudiantes de inglés',
                                    'Homeless Students', 'Estudiantes sin hogar']"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="showSection('AcademicAchievement')" class="page-break" />
      <!-- Academic 7 -->
      <div v-if="showSection('AcademicAchievement')" class="print-container">
        <print-header>
          {{ getSectionHeaderText('AcademicAchievement') }}
        </print-header>
        <b-row>
          <b-col>
            <visual-component
              :id="NJGPA11Id"
              :visual-title="NJGPA11Title"
              :visual-description="NJGPA11Description"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="NJGPA11Data"
              :horizontal-lines="['White', 'Blanco',
                                  'Female', 'Femenino', 'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja',
                                  'Students with disabilities', 'Estudiantes con Discapacidades',
                                  'English learners', 'Estudiantes de inglés',
                                  'Homeless students', 'Estudiantes sin hogar']"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showSection('AcademicAchievement') && !HideNAEP" class="page-break" />
      <!-- Academic 7 -->
      <div v-if="showSection('AcademicAchievement') && !HideNAEP" class="print-container">
        <print-header>
          {{ getSectionHeaderText('AcademicAchievement') }}
        </print-header>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideNAEP"
              :id="NAEPId"
              :visual-title="NAEPTitle"
              :visual-description="NAEPDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="NAEPData"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showCollegePage1" class="page-break" />
      <div v-if="showCollegePage1" class="print-container">
        <!-- College 1 -->
        <print-header>
          {{ getSectionHeaderText('CollegeCareer') }}
        </print-header>
        <b-row>
          <b-col>
            <span v-html="AssessmentsIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <visual-component
              v-if="!HideAssessmentsParticipationData"
              :visual-title="AssessmentsParticipationTitle"
              :visual-description="AssessmentsParticipationDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="AssessmentsParticipationData"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <visual-component
              v-if="!HideAssessmentsPerformanceData"
              :visual-title="AssessmentsPerformanceTitle"
              :visual-description="AssessmentsPerformanceDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="AssessmentsPerformanceData"
              :horizontal-lines="['SAT - Reading and Writing', 'SAT - Lectura y Escritura',
                                  'ACT - Reading', 'ACT - Lectura']"
              :vertical-lines="['State Average Score', 'Puntaje promedio estatal']"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showCollegePage2a" class="page-break" />
      <div v-if="showCollegePage2a" class="print-container">
        <!-- College 2 -->
        <print-header>
          {{ getSectionHeaderText('CollegeCareer') }}
        </print-header>
        <b-row>
          <b-col>
            <div
              v-if="!HideAPIBData
                && !HideDualEnrollmentData"
              :id="AdvancedHeaderId"
              class="pt-4 intro"
              v-html="APIBIntro"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-container v-if="!HideAPIBData" fluid class="pt-4">
              <b-row>
                <b-col class="outside-border px-4">
                  <b-row :id="APIBId">
                    <b-col>
                      <h3>{{ APIBTitle }} </h3>
                    </b-col>
                  </b-row>
                  <p v-html="APIBDescription" />
                  <b-row
                    v-for="(fullAPIBIDataSet, index) in APIBIData"
                    :key="`fullAPIBIDataSet-${index}`"
                  >
                    <b-col>
                      <visual-component
                        :visual-title="''"

                        :chart-type="ChartTypes.HorizontalBarChart"
                        :show-x-axis-labels="false"
                        :chart-data="fullAPIBIDataSet"
                        :suppress-border="true"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
          <b-col cols="6">
            <visual-component
              v-if="!HideDualEnrollmentData"
              :id="DualEnrollmentId"
              :visual-title="DualEnrollmentTitle"
              :visual-description="DualEnrollmentDescription"
              :chart-type="ChartTypes.HorizontalBarChart"
              :chart-data="DualEnrollmentData"
              :show-x-axis-labels="false"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showCollegePage2b" class="page-break" />
      <div v-if="showCollegePage2b" class="print-container">
        <!-- College 2b -->
        <print-header>
          {{ getSectionHeaderText('CollegeCareer') }}
        </print-header>
        <b-row>
          <b-col>
            <div
              v-if="!HideAPIBData
                && !HideDualEnrollmentData"
              :id="AdvancedHeaderId"
              class="pt-4 intro"
              v-html="APIBIntro"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <visual-component
              v-if="!HideDualEnrollStdtGrpData"
              :id="DualEnrollStdtGrpId"
              :visual-title="DualEnrollStdtGrpTitle"
              :visual-description="DualEnrollStdtGrpDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="DualEnrollStdtGrpData"
              :horizontal-lines="['White', 'Blanco', 'Female', 'Femenino',
                                  'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja']"
              :vertical-lines="['% Enrolled in one or more Dual Enrollment course',
                                '% Inscriptos en uno o más cursos de inscripción doble']"
            />
          </b-col>
        </b-row>
      </div>
      <!-- <div class="page-break"></div> -->
      <!-- College 2 -->
      <div
        v-for="(block, index) in aPIBCoursesOfferedBlockCount"
        :key="`College_2_${index}`"
        class="print-container page-break"
      >
        <print-header>
          {{ getSectionHeaderText('CollegeCareer') }}
        </print-header>
        <b-row>
          <b-col>
            <div
              v-if="!HideAPIBCoursesOfferedData"
              :id="AdvancedHeaderId + '2'"
              class="pt-4 intro"
              v-html="APIBIntro"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideAPIBCoursesOfferedData"
              :visual-title="APIBCoursesOfferedTitle"
              :visual-description="APIBCoursesOfferedDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="aPIBCoursesOfferedBlock(index)"
              :table-shading="APIBCoursesOfferedShading"
              :horizontal-lines="['Total Exams taken', 'Total de exámenes realizados']"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showCollegePage3" class="page-break" />
      <div v-if="showCollegePage3" class="print-container">
        <!-- College 3 -->
        <print-header>
          {{ getSectionHeaderText('CollegeCareer') }}
        </print-header>
        <b-row>
          <b-col>
            <div
              v-if="!HideCTEParticipationData
                && !HideCTEStdGroupData
                && !HideCTEValuedCredCareerData"
              class="pt-4 intro"
              v-html="CTEProgramsIntro"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-container v-if="!HideCTEParticipationData" fluid>
              <b-row class="pt-4">
                <b-col :id="CTEParticipationId" class="outside-border p-4">
                  <b-row>
                    <b-col>
                      <h3>{{ CTEParticipationTitle }} </h3>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="text-left">
                      <span>{{ CTEParticipationDescription }}</span>
                    </b-col>
                  </b-row>
                  <b-row
                    v-for="(fullCTEParticipationDataSet, index)
                      in CTEParticipationData"
                    :key="`fullCTEParticipationDataSet-${index}`"
                  >
                    <b-col>
                      <visual-component
                        class="keep-break"
                        :visual-title="''"

                        :chart-type="ChartTypes.HorizontalBarChart"
                        :chart-data="fullCTEParticipationDataSet"
                        :show-x-axis-labels="false"
                        :suppress-border="true"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
          <b-col cols="6">
            <visual-component
              v-if="!HideStructuredLearningExpParticipationData"
              :visual-title="StructuredLearningExpParticipationTitle"
              :visual-description="StructuredLearningExpParticipationDescription"
              :chart-type="ChartTypes.HorizontalBarChart"
              :show-x-axis-labels="false"
              :chart-data="StructuredLearningExpParticipationData"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showCollegePage4" class="page-break" />
      <div v-if="showCollegePage4" class="print-container">
        <!-- College 4 -->
        <print-header>
          {{ getSectionHeaderText('CollegeCareer') }}
        </print-header>
        <b-row>
          <b-col>
            <div
              v-if="!HideCTEParticipationData
                && !HideCTEStdGroupData
                && !HideWorkBasedLearningData"
              class="pt-4 intro"
              v-html="CTEProgramsIntro"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideCTEStdGroupData"
              :visual-title="CTEStdGroupTitle"
              :visual-description="CTEStdGroupDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="CTEStdGroupData"
              :horizontal-lines="['White', 'Blanco',
                                  'Female', 'Femenino', 'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja']"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showCollegePage4" class="page-break" />
      <div v-if="showCollegePage4" class="print-container">
        <!-- College 4b -->
        <print-header>
          {{ getSectionHeaderText('CollegeCareer') }}
        </print-header>
        <b-row>
          <b-col>
            <div
              v-if="!HideCTEParticipationData
                && !HideCTEStdGroupData
                && !HideCTEValuedCredCareerData"
              class="pt-4 intro"
              v-html="CTEProgramsIntro"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideWorkBasedLearningData"
              :id="WorkBasedLearningId"
              :visual-title="WorkBasedLearningTitle"
              :visual-description="WorkBasedLearningDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="WorkBasedLearningData"
              :horizontal-lines="['Total']"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showCollegePage5" class="page-break" />
      <div v-if="showCollegePage5" class="print-container">
        <!-- College 5 -->
        <print-header>
          {{ getSectionHeaderText('CollegeCareer') }}
        </print-header>
        <b-row>
          <b-col>
            <div
              v-if="!HideCTEParticipationData
                && !HideCTEStdGroupData
                && !HideCTEValuedCredCareerData"
              class="pt-4 intro"
              v-html="CTEProgramsIntro"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideCTEValuedCredData"
              :visual-title="CTEValuedCredTitle"
              :visual-description="CTEValuedCredDescription"
              :chart-type="ChartTypes.HorizontalBarChart"
              :show-x-axis-labels="false"
              :chart-data="CTEValuedCredData"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="false" class="page-break" />
      <div v-if="false" class="print-container">
        <!-- College 5 - Split from 2 pages -->
        <print-header>
          {{ getSectionHeaderText('CollegeCareer') }}
        </print-header>
        <b-row>
          <b-col>
            <div
              v-if="!HideCTEParticipationData
                && !HideCTEStdGroupData
                && !HideWorkBasedLearningData"
              class="pt-4 intro"
              v-html="CTEProgramsIntro"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideWorkBasedLearningData"
              :id="WorkBasedLearningId"
              :visual-title="WorkBasedLearningTitle"
              :visual-description="WorkBasedLearningDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="WorkBasedLearningData"
              :horizontal-lines="['Total']"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showCollegePage5" class="page-break" />
      <div v-if="showCollegePage5" class="print-container">
        <!-- College 6 -->
        <print-header>
          {{ getSectionHeaderText('CollegeCareer') }}
        </print-header>
        <b-row>
          <b-col>
            <div
              v-if="!HideCTEParticipationData
                && !HideCTEStdGroupData
                && !HideWorkBasedLearningData"
              class="pt-4 intro"
              v-html="CTEProgramsIntro"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideCTEValuedCredCareerData"
              :visual-title="CTEValuedCredCareerTitle"
              :visual-description="CTEValuedCredCareerDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="CTEValuedCredCareerData"
              :horizontal-lines="['Total']"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showCollegePage678" class="page-break" />
      <div v-if="showCollegePage678" class="print-container">
        <!-- College 6 -->
        <print-header>
          {{ getSectionHeaderText('CollegeCareer') }}
        </print-header>
        <b-row>
          <b-col>
            <div
              v-if="!(HideCourseParticipationMathData
                && HideCourseParticipationScienceData
                && HideCourseParticipationHistoryData
                && HideCourseParticipationLanguagesData
                && HideCourseParticipationITData)"
              class="pt-4 intro"
              v-html="CourseParticipationIntro"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideCourseParticipationMathData"
              :id="CourseParticipationMathId"
              :visual-title="CourseParticipationMathTitle"
              :visual-description="CourseParticipationMathDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="CourseParticipationMathData"
              :table-shading="ParticipationShading"
              :horizontal-lines="['Total']"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideCourseParticipationScienceData"
              :visual-title="CourseParticipationScienceTitle"
              :visual-description="CourseParticipationScienceDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="CourseParticipationScienceData"
              :table-shading="ParticipationShading"
              :horizontal-lines="['Total']"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showCollegePage678" class="page-break" />
      <div v-if="showCollegePage678" class="print-container">
        <!-- College 7 -->
        <print-header>
          {{ getSectionHeaderText('CollegeCareer') }}
        </print-header>
        <b-row>
          <b-col>
            <div
              v-if="!(HideCourseParticipationMathData
                && HideCourseParticipationScienceData
                && HideCourseParticipationHistoryData
                && HideCourseParticipationLanguagesData
                && HideCourseParticipationITData)"
              class="pt-4 intro"
              v-html="CourseParticipationIntro"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideCourseParticipationHistoryData"
              :visual-title="CourseParticipationHistoryTitle"
              :visual-description="CourseParticipationHistoryDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="CourseParticipationHistoryData"
              :table-shading="ParticipationShading"
              :horizontal-lines="['Total']"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideCourseParticipationLanguagesData"
              :visual-title="CourseParticipationLanguagesTitle"
              :visual-description="CourseParticipationLanguagesDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="CourseParticipationLanguagesData"
              :horizontal-lines="['Total']"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showCollegePage678" class="page-break" />
      <div v-if="showCollegePage678" class="print-container">
        <!-- College 8 -->
        <print-header>
          {{ getSectionHeaderText('CollegeCareer') }}
        </print-header>
        <b-row>
          <b-col>
            <div
              v-if="!(HideCourseParticipationMathData
                && HideCourseParticipationScienceData
                && HideCourseParticipationHistoryData
                && HideCourseParticipationLanguagesData
                && HideCourseParticipationITData)"
              class="pt-4 intro"
              v-html="CourseParticipationIntro"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideCourseParticipationITData"
              :visual-title="CourseParticipationITTitle"
              :visual-description="CourseParticipationITDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="CourseParticipationITData"
              :table-shading="ParticipationShading"
              :horizontal-lines="['Total']"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showCollegePage9" class="page-break" />
      <div v-if="showCollegePage9" class="print-container">
        <!-- College 9 -->
        <print-header>
          {{ getSectionHeaderText('CollegeCareer') }}
        </print-header>
        <b-row>
          <b-col>
            <div
              v-if="!(HideCourseParticipationMathData
                && HideCourseParticipationScienceData
                && HideCourseParticipationHistoryData
                && HideCourseParticipationLanguagesData
                && HideCourseParticipationITData)"
              class="pt-4 intro"
              v-html="CourseParticipationIntro"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideSealBiliteracyData"
              :visual-title="SealBiliteracyTitle"
              :visual-description="SealBiliteracyDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="sealBiliteracyBlock(0)"
              :horizontal-lines="['Total']"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showCollegePage9 && sealBiliteracyBlockCount > 1" class="page-break" />
      <!-- College 9 - Split 2 pages -->
      <div v-if="showCollegePage9 && sealBiliteracyBlockCount > 1" class="print-container">
        <print-header>
          {{ getSectionHeaderText('CollegeCareer') }}
        </print-header>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideSealBiliteracyData"
              :visual-title="SealBiliteracyTitle"
              :visual-description="SealBiliteracyDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="sealBiliteracyBlock(1)"
              :horizontal-lines="['Total']"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showCollegePage9 && sealBiliteracyBlockCount > 1" class="page-break" />
      <!-- College 9 - Split 2 pages -->
      <div v-if="showCollegePage9 && sealBiliteracyBlockCount > 1" class="print-container">
        <print-header>
          {{ getSectionHeaderText('CollegeCareer') }}
        </print-header>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideSealBiliteracyData"
              :visual-title="SealBiliteracyTitle"
              :visual-description="SealBiliteracyDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="sealBiliteracyBlock(2)"
              :horizontal-lines="['Total']"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showCollegePage10" class="page-break" />
      <div v-if="showCollegePage10" class="print-container">
        <!-- College 10 -->
        <print-header>
          {{ getSectionHeaderText('CollegeCareer') }}
        </print-header>
        <b-row>
          <!-- Intro -->
          <b-col>
            <div class="pt-4 intro" v-html="CourseParticipationIntro" />
          </b-col>
        </b-row>
        <b-row class="pt-2 mx-0">
          <b-col style="border: 1px solid #287AB8; border-radius: 0.25rem;">
            <b-row>
              <!-- Title -->
              <b-col>
                <h3>{{ CourseParticipationArtsTitle }}</h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p v-html="CourseParticipationArtsDescription" />
              </b-col>
            </b-row>
            <!-- K-5 -->
            <div v-if="showCollegePageK5" class="page-break" />
            <div v-if="showCollegePageK5" class="print-container">
              <print-header>
                {{ getSectionHeaderText('CollegeCareer') }}
              </print-header>
              <b-row>
                <b-col>
                  <div
                    v-if="showCollegePageK5"
                    class="pt-4 intro"
                    v-html="CourseParticipationIntro"
                  />
                </b-col>
              </b-row>
              <!-- K-5 -->
              <b-row>
                <b-col
                  v-if="!HideVisualPerformingArtsKto5"
                  cols="12"
                  md="6"
                  class="border-right-only pt-4"
                  style="border-right: 1px solid #287AB8;"
                >
                  <b-row>
                    <b-col class="descriptive-text">
                      <span v-html="GradeK5Title" />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      col
                      :md="ReportType === 'school'
                        || ReportType === 'district' ? `6` : `12`"
                    >
                      <visual-component
                        ref="VisualPerformingDonutKto5"
                        :chart-type="ChartTypes.DonutChart"
                        :chart-data="VisualPerformingDonutKto5"
                        :suppress-border="true"
                      />
                    </b-col>
                    <b-col
                      v-if="ReportType === 'school' || ReportType === 'district'"
                      col
                      md="6"
                    >
                      <visual-component
                        ref="VisualPerformingDonutKto5State"
                        :chart-type="ChartTypes.DonutChart"
                        :chart-data="VisualPerformingDonutKto5State"
                        :suppress-border="true"
                      />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  v-if="!HideVisualPerformingArtsKto5"
                  cols="12"
                  md="6"
                  class="border-right-only pt-4"
                  style="border-right: 1px solid #287AB8;"
                >
                  <span v-html="VisArtsDesc" />
                  <b-row
                    v-for="(fullHorizontal1DataSet, index) in VisualPerformingArtsKto5"
                    ref="VPAK5Container"
                    :key="`fullHorizontal1DataSet-${index}`"
                  >
                    <b-col style="text-align: left; border: 0px;">
                      <visual-component
                        :ref="`VPAK5-Chart-${index}`"
                        :suppress-border="true"
                        :chart-type="ChartTypes.HorizontalBarChart"
                        :chart-data="fullHorizontal1DataSet"
                        :show-x-axis-labels="false"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <!-- End K-5 -->
            <div v-if="showCollegePage10" class="page-break" />
            <div v-if="showCollegePage10" class="print-container">
              <!-- College 10-B -->
              <print-header>
                {{ getSectionHeaderText('CollegeCareer') }}
              </print-header>
              <b-row>
                <b-col>
                  <div
                    v-if="showCollegePage10"
                    class="pt-4 intro"
                    v-html="CourseParticipationIntro"
                  />
                </b-col>
              </b-row>
              <!-- 6-8 -->
              <b-row>
                <b-col
                  v-if="!HideVisualPerformingArts6to8"
                  cols="12"
                  md="6"
                  class="border-right-only pt-4"
                  style="border-right: 1px solid #287AB8;"
                >
                  <b-row>
                    <b-col class="descriptive-text">
                      <span v-html="Grade68Title" />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      col
                      :md="ReportType === 'school'
                        || ReportType === 'district' ? `6` : `12`"
                    >
                      <visual-component
                        ref="VisualPerformingDonut6to8"
                        :chart-type="ChartTypes.DonutChart"
                        :chart-data="VisualPerformingDonut6to8"
                        :suppress-border="true"
                      />
                    </b-col>
                    <b-col
                      v-if="ReportType === 'school' || ReportType === 'district'"
                      col
                      md="6"
                    >
                      <visual-component
                        ref="VisualPerformingDonut6to8State"
                        :chart-type="ChartTypes.DonutChart"
                        :chart-data="VisualPerformingDonut6to8State"
                        :suppress-border="true"
                      />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  v-if="!HideVisualPerformingArts6to8"
                  cols="12"
                  md="6"
                  class="border-right-only pt-4"
                  style="border-right: 1px solid #287AB8;"
                >
                  <span v-html="VisArtsDesc" />
                  <b-row
                    v-for="(fullHorizontal1DataSet, index) in VisualPerformingArts6to8"
                    ref="VPA68Container"
                    :key="`fullHorizontal1DataSet-${index}`"
                  >
                    <b-col style="text-align: left; border: 0px;">
                      <visual-component
                        :ref="`VPA68-Chart-${index}`"
                        :suppress-border="true"

                        :chart-type="ChartTypes.HorizontalBarChart"
                        :chart-data="fullHorizontal1DataSet"
                        :show-x-axis-labels="false"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <!-- End 6 - 8-->
            <div v-if="showCollegePage10" class="page-break" />
            <div v-if="showCollegePage10" class="print-container">
              <!-- College 10-B -->
              <print-header>
                {{ getSectionHeaderText('CollegeCareer') }}
              </print-header>
              <b-row>
                <b-col>
                  <div
                    v-if="showCollegePage10"
                    class="pt-4 intro"
                    v-html="CourseParticipationIntro"
                  />
                </b-col>
              </b-row>
              <!-- 9-12 -->
              <b-row>
                <b-col
                  v-if="!HideVisualPerformingArts9to12"
                  cols="12"
                  md="6"
                  class="border-bottom-only"
                >
                  <b-row>
                    <b-col class="descriptive-text">
                      <span v-html="Grade912Title" />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      col
                      :md="ReportType === 'school'
                        || ReportType === 'district' ? `6` : `12`"
                    >
                      <visual-component
                        ref="VisualPerformingDonut9to12"
                        :chart-type="ChartTypes.DonutChart"
                        :chart-data="VisualPerformingDonut9to12"
                        :suppress-border="true"
                      />
                    </b-col>
                    <b-col
                      v-if="ReportType === 'school' || ReportType === 'district'"
                      col
                      md="6"
                    >
                      <visual-component
                        ref="VisualPerformingDonut9to12State"
                        :chart-type="ChartTypes.DonutChart"
                        :chart-data="VisualPerformingDonut9to12State"
                        :suppress-border="true"
                      />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  v-if="!HideVisualPerformingArts9to12"
                  cols="12"
                  md="6"
                  class="pt-4"
                >
                  <span v-html="VisArtsDesc" />
                  <b-row
                    v-for="(fullHorizontal2DataSet, index)
                      in VisualPerformingArts9to12"
                    ref="VPA912Container"
                    :key="`fullHorizontal2DataSet-${index}`"
                  >
                    <b-col style="text-align: left; border: 0px;">
                      <visual-component
                        :ref="`VPA912-Chart-${index}`"
                        :suppress-border="true"

                        :chart-type="ChartTypes.HorizontalBarChart"
                        :show-x-axis-labels="false"
                        :chart-data="fullHorizontal2DataSet"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <!-- End 9 - 12 -->
          </b-col>
        </b-row>
      </div>
      <div
        v-if="showSection('PostSecondary')
          && currentInstitution.template !== 'NotTested'"
        class="page-break"
      />
      <!-- Grad 1 -->
      <div
        v-if="showSection('PostSecondary') && currentInstitution.template !== 'NotTested'"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('PostSecondary') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro" v-html="GraduationRateTrendsVisualIntro" />
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col>
            <b-card :class="'outside-border'" no-body>
              <b-row :id="GraduationRateTrendsId">
                <b-col>
                  <h3>{{ GraduationRateTrendsVisualTitle }}</h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p v-html="GraduationRateTrendsvisualDescription" />
                </b-col>
              </b-row>
              <b-row>
                <b-col col md="6" sm="12" class="border-right-only">
                  <visual-component
                    ref="GradRateTrend4Year"
                    :visual-title="GraduationRateTrendsVisual4YearTitle"
                    :suppress-border="true"
                    :suppress-print-button="true"

                    :chart-type="ChartTypes.LineChart"
                    :chart-data="GraduationRateTrendsVisual4YearData"
                  />
                </b-col>
                <b-col col md="6" sm="12">
                  <visual-component
                    ref="GradRateTrend5Year"
                    :visual-title="GraduationRateTrendsVisual5YearTitle"
                    :suppress-border="true"
                    :suppress-print-button="true"

                    :chart-type="ChartTypes.LineChart"
                    :chart-data="GraduationRateTrendsVisual5YearData"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <visual-component
                    v-if="$store.getters.SelectedSchoolYear.value == 2021"
                    ref="GradRateTrendTable"
                    :visual-title="''"
                    :suppress-border="true"

                    :suppress-print-button="true"
                    :table-shading="GraduationRateTrendsVisualShading"
                    :chart-type="ChartTypes.TableGrid"
                    :chart-data="GraduationRateTrendsVisualData"
                    :vertical-lines="['Cohort 2020 4-Year Rate',
                                      'Cohorte 2020 Tasa de 4 años']"
                  />
                  <visual-component
                    v-if="$store.getters.SelectedSchoolYear.value == 2122"
                    ref="GradRateTrendTable"
                    :visual-title="''"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :table-shading="GraduationRateTrendsVisualShading"
                    :chart-type="ChartTypes.TableGrid"
                    :chart-data="GraduationRateTrendsVisualData"
                    :vertical-lines="['Cohort 2021 4-Year Rate',
                                      'Cohorte 2021 Tasa de 4 años']"
                  />
                  <visual-component
                    v-if="$store.getters.SelectedSchoolYear.value == 2223"
                    ref="GradRateTrendTable"
                    :visual-title="''"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :visual-description="''"
                    :table-shading="GraduationRateTrendsVisualShading"
                    :chart-type="ChartTypes.TableGrid"
                    :chart-data="GraduationRateTrendsVisualData"
                    :vertical-lines="['Cohort 2023 4-Year Rate', 'Cohorte 2023 Tasa de 4 años']"
                  />
                  <visual-component
                    v-if="$store.getters.SelectedSchoolYear.value == 2324"
                    ref="GradRateTrendTable"
                    :visual-title="''"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :visual-description="''"
                    :table-shading="GraduationRateTrendsVisualShading"
                    :chart-type="ChartTypes.TableGrid"
                    :chart-data="GraduationRateTrendsVisualData"
                    :vertical-lines="['Cohort 2024 4-Year Rate', 'Cohorte 2024 Tasa de 4 años']"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="showSection('PostSecondary')
          && currentInstitution.template !== 'NotTested'"
        class="page-break"
      />
      <!-- Grad 2 -->
      <div
        v-if="showSection('PostSecondary') && currentInstitution.template !== 'NotTested'"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('PostSecondary') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro" v-html="GraduationRateTrendsVisualIntro" />
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col>
            <visual-component
              v-if="!HideCohort4Year"
              :visual-title="Cohort4YearTitle"
              :visual-description="Cohort4YearDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="Cohort4YearData"
              :horizontal-lines="['White', 'Blanco', 'Female', 'Femenino',
                                  'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja']"
              :vertical-lines="['Non-Continuing Students',
                                'Estudiantes que no son de Educación Continua',
                                'Estudiantes no continuos']"
            />
          </b-col>
        </b-row>
      </div>
      <div
        v-if="showSection('PostSecondary')
          && currentInstitution.template !== 'NotTested'"
        class="page-break"
      />
      <!-- Grad 3 -->
      <div
        v-if="showSection('PostSecondary') && currentInstitution.template !== 'NotTested'"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('PostSecondary') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro" v-html="GraduationRateTrendsVisualIntro" />
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col>
            <visual-component
              v-if="!HideCohort5Year"
              :visual-title="Cohort5YearTitle"
              :visual-description="Cohort5YearDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="Cohort5YearData"
              :horizontal-lines="['White', 'Blanco', 'Female', 'Femenino',
                                  'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja']"
              :vertical-lines="['Non-Continuing Students',
                                'Estudiantes que no son de Educación Continua',
                                'Estudiantes no continuos']"
            />
          </b-col>
        </b-row>
      </div>
      <div
        v-if="showSection('PostSecondary')
          && currentInstitution.template !== 'NotTested'"
        class="page-break"
      />
      <!-- Grad 4 -->
      <div
        v-if="showSection('PostSecondary') && currentInstitution.template !== 'NotTested'"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('PostSecondary') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro" v-html="GraduationRateTrendsVisualIntro" />
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col>
            <visual-component
              v-if="!HideCohort6Year"
              :visual-title="Cohort6YearTitle"
              :visual-description="Cohort6YearDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="Cohort6YearData"
              :horizontal-lines="['White', 'Blanco', 'Female', 'Femenino',
                                  'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja']"
              :vertical-lines="['Non-Continuing Students',
                                'Estudiantes que no son de Educación Continua',
                                'Estudiantes no continuos']"
            />
          </b-col>
        </b-row>
      </div>
      <div
        v-if="showSection('PostSecondary')
          && currentInstitution.template !== 'NotTested'"
        class="page-break"
      />
      <!-- Grad 4 -->
      <div
        v-if="showSection('PostSecondary') && currentInstitution.template !== 'NotTested'"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('PostSecondary') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro" v-html="GraduationRateTrendsVisualIntro" />
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col>
            <visual-component
              v-if="!HideFederalGradRates
                && $store.getters.SelectedSchoolYear.value == 2021"
              :id="FederalGradRatesId"
              :visual-title="FederalGradRatesTitle"
              :visual-description="FederalGradRatesDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="FederalGradRatesData"
              :horizontal-lines="['White', 'Blanco', 'Female', 'Femenino',
                                  'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja']"
              :vertical-lines="['2020 5-Year Federal Graduation Rate',
                                'Tasa de graduación federal de 5 años de 2020']"
            />
            <visual-component
              v-if="!HideFederalGradRates
                && $store.getters.SelectedSchoolYear.value >= 2122"
              :id="FederalGradRatesId"
              :visual-title="FederalGradRatesTitle"
              :visual-description="FederalGradRatesDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="FederalGradRatesData"
              :horizontal-lines="['White', 'Blanco', 'Female', 'Femenino',
                                  'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja']"
              :vertical-lines="['2021 5-Year Federal Graduation Rate', '2022 5-Year Federal Graduation Rate',
                                'Tasa de graduación federal de 5 años de 2021',
                                'Tasa de graduación federal de 5 años de 2022']"
            />
          </b-col>
        </b-row>
      </div>
      <div
        v-if="showSection('PostSecondary')
          && currentInstitution.template !== 'NotTested'"
        class="page-break"
      />
      <!-- Grad 4 -->
      <div
        v-if="showSection('PostSecondary') && currentInstitution.template !== 'NotTested'"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('PostSecondary') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro" v-html="GraduationRateTrendsVisualIntro" />
          </b-col>
        </b-row>
        <b-row class="pt-4">
          <b-col>
            <visual-component
              v-if="!HideAccountabilityGradRates
                && $store.getters.SelectedSchoolYear.value == 2021"
              :id="AccountabilityGradRatesId"
              :visual-title="AccountabilityGradRatesTitle"
              :visual-description="AccountabilityGradRatesDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="AccountabilityGradRatesData"
              :table-shading="AccountabilityGradRatesShading"
              :horizontal-lines="['White', 'Blanco', 'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja']"
              :vertical-lines="['Cohort 2020: Met Target', 'Cohorte 2020: Objetivo cumplido']"
            />
            <visual-component
              v-if="!HideAccountabilityGradRates
                && $store.getters.SelectedSchoolYear.value == 2122"
              :id="AccountabilityGradRatesId"
              :visual-title="AccountabilityGradRatesTitle"
              :visual-description="AccountabilityGradRatesDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="AccountabilityGradRatesData"
              :table-shading="AccountabilityGradRatesShading"
              :horizontal-lines="['White', 'Blanco', 'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja']"
              :vertical-lines="['Cohort 2021: Met Target', 'Cohorte 2021: Objetivo cumplido']"
            />
            <visual-component
              v-if="!HideAccountabilityGradRates
                && $store.getters.SelectedSchoolYear.value == 2223"
              :id="AccountabilityGradRatesId"
              :visual-title="AccountabilityGradRatesTitle"
              :visual-description="AccountabilityGradRatesDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="AccountabilityGradRatesData"
              :table-shading="AccountabilityGradRatesShading"
              :horizontal-lines="['White', 'Blanco', 'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja']"
              :vertical-lines="['Cohort 2022: Met Target', 'Cohorte 2022: Objetivo cumplido']"
            />
            <visual-component
              v-if="!HideAccountabilityGradRates
                && $store.getters.SelectedSchoolYear.value == 2324"
              :id="AccountabilityGradRatesId"
              :visual-title="AccountabilityGradRatesTitle"
              :visual-description="AccountabilityGradRatesDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="AccountabilityGradRatesData"
              :table-shading="AccountabilityGradRatesShading"
              :horizontal-lines="['White', 'Blanco', 'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja']"
              :vertical-lines="['Cohort 2023: Met Target', 'Cohorte 2023: Objetivo cumplido']"
            />
          </b-col>
        </b-row>
      </div>
      <div
        v-if="showSection('PostSecondary')
          && currentInstitution.template !== 'NotTested'"
        class="page-break"
      />
      <!-- Grad 5 -->
      <div
        v-if="showSection('PostSecondary') && currentInstitution.template !== 'NotTested'"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('PostSecondary') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro" v-html="GraduationRateTrendsVisualIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
            <visual-component
              v-if="!HideGradPathways
                && $store.getters.SelectedSchoolYear.value <= 2122"
              :visual-title="GradPathwaysTitle"
              :visual-description="GradPathwaysDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="GradPathwaysData"
            />
            <b-card
              v-if="$store.getters.SelectedSchoolYear.value == 2223"
              :id="GradPathwaysId"
              :class="'outside-border'"
            >
              <b-card-header class="d-none d-print-block">
                {{ printDisplay }}
              </b-card-header>
              <b-row :id="GradPathwaysId">
                <b-col>
                  <h3>{{ GradPathwaysTitle }}</h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p v-html="GradPathwaysDescription" />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="4">
            <visual-component
              v-if="!HideDropout"
              :visual-title="DropoutTitle"
              :visual-description="DropoutDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="DropoutData"
            />
          </b-col>
        </b-row>
      </div>
      <div
        v-if="showSection('PostSecondary')
          && currentInstitution.template !== 'NotTested'"
        class="page-break"
      />
      <!-- Grad 6 -->
      <div
        v-if="showSection('PostSecondary') && currentInstitution.template !== 'NotTested'
          && $store.getters.SelectedSchoolYear.value <= 2122"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('PostSecondary') }}
        </print-header>
        <b-row
          v-if="!HidePostSecEnrollSummaryTable
            && !HidePostSecEnrollSummaryVisual"
          class="py-4"
        >
          <b-col>
            <span v-html="PostSecEnrollIntro" />
            <b-card class="outside-border pt-4">
              <b-row>
                <b-col>
                  <h3>{{ PostSecEnrollSummaryTitle }}</h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p v-html="PostSecEnrollSummaryDescription" />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <visual-component
                    v-if="!HidePostSecEnrollSummaryVisual"
                    ref="PostSecEnrollSummaryChart"

                    :visual-title="''"
                    :chart-type="ChartTypes.StackedBarChart"
                    :chart-data="PostSecEnrollSummaryVisualData"
                    :suppress-border="true"
                    :suppress-print-button="true"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <visual-component
                    v-if="!HidePostSecEnrollSummaryTable"
                    ref="PostSecEnrollSummaryTable"

                    :visual-title="''"
                    :chart-type="ChartTypes.TableGrid"
                    :chart-data="PostSecEnrollSummaryTableData"
                    :suppress-border="true"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="showSection('PostSecondary')
          && currentInstitution.template !== 'NotTested'"
        class="page-break"
      />
      <!-- Grad 7 -->
      <div
        v-if="showSection('PostSecondary') && currentInstitution.template !== 'NotTested'"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('PostSecondary') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro" v-html="PostSecEnrollIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HidePostSecEnrollFallVisual"
              :visual-description="PostSecEnrollFallDescription"
              :chart-type="ChartTypes.TableGrid"
              :visual-title="PostSecEnrollFallTitle"
              :chart-data="PostSecEnrollFallData"
              :horizontal-lines="['White', 'Blanco',
                                  'Statewide', 'Toda la Estado', 'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja',
                                  'Female', 'Femenino']"
              :vertical-lines="['% Enrolled in Any Institution',
                                '% de Inscritos en Cualquier Institución']"
            />
          </b-col>
        </b-row>
      </div>
      <div
        v-if="showSection('PostSecondary')
          && currentInstitution.template !== 'NotTested'"
        class="page-break"
      />
      <!-- Grad 8 -->
      <div
        v-if="showSection('PostSecondary') && currentInstitution.template !== 'NotTested'"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('PostSecondary') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro" v-html="PostSecEnrollIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HidePostSecEnroll16MonthVisual"
              :visual-title="PostSecEnroll16MonthTitle"
              :visual-description="PostSecEnroll16MonthDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="PostSecEnroll16MonthData"
              :horizontal-lines="['White', 'Blanco',
                                  'Statewide', 'Toda la Estado', 'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja',
                                  'Female', 'Femenino']"
              :vertical-lines="['% Enrolled in Any Institution',
                                '% de Inscritos en Cualquier Institución',
                                '% of Enrolled in 4-Year Institution', '% de Inscritos en Institución de 4 años',
                                '% of Enrolled in Private Institution', '% de Inscritos en Institución Privada']"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showSection('PostSecondary')" class="page-break" />
      <div v-if="showSection('PostSecondary')" class="print-container">
        <!-- Grad 9 -->
        <print-header>
          {{ getSectionHeaderText('PostSecondary') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro" v-html="PostSecEnrollIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideApprenticeship"
              :id="ApprenticeshipId"
              :visual-title="ApprenticeshipTitle"
              :visual-description="ApprenticeshipDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="ApprenticeshipData"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showSection('Climate') && !HideAbsentChronicData" class="page-break" />
      <!-- Climate 1 -->
      <div v-if="showSection('Climate') && !HideAbsentChronicData" class="print-container">
        <print-header>
          {{ getSectionHeaderText('Climate') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro" v-html="AbsentIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              class="h-100"
              :id="AbsentChronicId"
              :visual-title="AbsentChronicTitle"
              :visual-description="AbsentChronicDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="AbsentChronicData"
              :table-shading="AbsentChronicTableShading"
              :horizontal-lines="['White', 'Blanco',
                                  'Female', 'Femenino', 'Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja']"
              :vertical-lines="AbsentChronicVerticalLines"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showSection('Climate') && !HideAbsentChronicData" class="page-break" />
      <!-- Climate 2 -->
      <div v-if="showSection('Climate') && !HideAbsentChronicData" class="print-container">
        <print-header>
          {{ getSectionHeaderText('Climate') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro" v-html="AbsentIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              class="h-100"
              :id="AbsentDaysId"
              :visual-title="AbsentDaysTitle"
              :visual-description="AbsentDaysDescription"
              :chart-type="ChartTypes.BarChart"
              :chart-data="AbsentDaysData"
              :v-html="NumberOfAbsences"
              :chart-sub-title="AbsentDaysChartSubTitle"
              :chart-x-axis-title="AbsentDaysChartXAxisTitle"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showSection('Climate') && !HideAbsentChronicData" class="page-break" />
      <!-- Climate 3 -->
      <div v-if="showSection('Climate') && !HideAbsentChronicData" class="print-container">
        <print-header>
          {{ getSectionHeaderText('Climate') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro" v-html="AbsentIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              :id="AbsentGradeId"
              class="h-100"
              :visual-title="AbsentGradeTitle"
              :visual-description="AbsentGradeDescription"
              :chart-type="ChartTypes.BarChart"
              :chart-data="AbsentGradeData"
              :chart-sub-title="AbsentGradeChartSubTitle"
              :chart-x-axis-title="AbsentGradeChartXAxisTitle"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showSection('Climate')" class="page-break" />
      <div v-if="showSection('Climate')" class="print-container">
        <!-- Climate 4 -->
        <print-header>
          {{ getSectionHeaderText('Climate') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro" v-html="Violenceintro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <visual-component
              v-if="!HideViolenceOffensesData"
              :visual-title="ViolenceOffensesTitle"
              :visual-description="ViolenceOffensesDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="ViolenceOffensesData"
              :horizontal-lines="['Total Unique Incidents', 'Total de incidentes únicos']"
            />
          </b-col>
          <b-col cols="6">
            <visual-component
              v-if="!HidePoliceNotifData"
              class="h-100"
              :visual-title="PoliceNotifTitle"
              :visual-description="PoliceNotifDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="PoliceNotifData"
              :horizontal-lines="['Other Incidents Leading to Removal',
                                  'Otros Incidentes que Conducen a la Eliminación']"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showSection('Climate')" class="page-break" />
      <div v-if="showSection('Climate')" class="print-container">
        <!-- Climate 3 -->
        <print-header>
          {{ getSectionHeaderText('Climate') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro" v-html="Violenceintro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <visual-component
              v-if="!HideHarrassInvestData"
              class="h-100"
              :visual-title="HarrassInvestTitle"
              :visual-description="HarrassInvestDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="HarrassInvestData"
              :table-shading="HarrassInvestTableShading"
            />
          </b-col>
          <b-col cols="6" class="pt-4">
            <b-container fluid class="outside-border">
              <b-row>
                <b-col>
                  <h3 v-html="StdDisciplinTitle" />
                  <p class="intro" v-html="StdDisciplinDescription" />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="8">
                  <visual-component
                    :visual-title="''"

                    :chart-type="ChartTypes.TableGrid"
                    :chart-data="StdDisciplinData"
                    :suppress-border="true"
                  />
                </b-col>
                <b-col cols="4">
                  <visual-component
                    :visual-title="''"

                    :chart-type="ChartTypes.TableGrid"
                    :chart-data="SchoolDaysMissedData"
                    :suppress-border="true"
                  />
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="showSection('Climate')
          && !(HideSchoolDayData && HideDeviceRatiosData && HideTechnologyInternetAccessTitle)"
        class="page-break"
      />
      <!-- Climate 4 -->
      <!-- eslint-disable-next-line max-len -->
      <div v-if="showSection('Climate') && !(HideSchoolDayData && HideDeviceRatiosData && HideTechnologyInternetAccessTitle)" class="print-container">
        <print-header>
          {{ getSectionHeaderText('Climate') }}
        </print-header>
        <b-row>
          <b-col cols="6">
            <visual-component
              v-if="!HideSchoolDayData"
              :visual-title="SchoolDayTitle"
              :visual-description="SchoolDayDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="SchoolDayData"
              :horizontal-lines="['Full Time - Instructional Time',
                                  'Tiempo Completo - Tiempo de Instrucción']"
            />
          </b-col>
          <b-col cols="6">
            <visual-component
              v-if="!HideDeviceRatiosData"
              :visual-title="DeviceRatiosTitle"
              :visual-description="DeviceRatiosDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="DeviceRatiosData"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="!HideTechnologyInternetAccessTitle" :cols="12">
            <b-container
              v-if="!HideTechnologyInternetAccessTitle"
              fluid
            >
              <b-row class="pt-4">
                <b-col cols="12" class="outside-border pt-4">
                  <h3 v-html="TechnologyInternetAccessTitle" />
                  <p class="p-1" v-html="TechnologyInternetAccessText" />
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </div>
      <div v-if="showSection('Staff')" class="page-break" />
      <div v-if="showSection('Staff')" class="print-container">
        <!-- Staff 1 -->
        <print-header>
          {{ getSectionHeaderText('Staff') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro keep-break" v-html="StaffIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <visual-component
              v-if="!HideTeacherExpVisual"
              class="h-100"
              :visual-title="TeacherExpTitle"
              :visual-description="TeacherExpDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="TeacherExpData"
            />
          </b-col>
          <b-col cols="4">
            <visual-component
              v-if="!HideAdmExpVisual"
              class="h-100"
              :visual-title="AdmExpTitle"
              :visual-description="AdmExpDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="AdmExpData"
            />
          </b-col>
          <b-col cols="4">
            <b-row>
              <b-col>
                <visual-component
                  :visual-title="StaffCountsTitle"
                  :visual-description="StaffCountsDescription"
                  :chart-type="ChartTypes.TableGrid"
                  :chart-data="StaffCountsData"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div v-if="showSection('Staff')" class="page-break" />
      <div v-if="showSection('Staff')" class="print-container">
        <!-- Staff 2 -->
        <print-header>
          {{ getSectionHeaderText('Staff') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro keep-break" v-html="StaffIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <visual-component
              class="h-100"
              v-if="!HideStdStaffRatiosVisual"
              :visual-title="StdStaffRatiosTitle"
              :visual-description="StdStaffRatiosDescription"
              :visual-footer="StdStaffRatiosPlusFooter"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="StdStaffRatiosData"
              :table-shading="StdStaffRatiosTableShading"
              :horizontal-lines="['Students to Librarians/Media Specialists †',
                                  'Estudiantes a Bibliotecarios/Especialistas en medios †']"
            />
          </b-col>
          <b-col cols="8">
            <visual-component
              v-if="!HideTeacherDemogVisual"
              :visual-title="TeacherDemogTitle"
              :visual-description="TeacherDemogDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="TeacherDemogData"
              :horizontal-lines="['White', 'Blanco']"
              :vertical-lines="['Administrators in School',
                                'Administradores en la escuela', 'Administrators in District',
                                'Administradores en el distrito']"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showSection('Staff')" class="page-break" />
      <div v-if="showSection('Staff')" class="print-container">
        <!-- Staff 3 -->
        <print-header>
          {{ getSectionHeaderText('Staff') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro keep-break" v-html="StaffIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-card class="outside-border pt-4">
              <b-row :id="TeacherLevelEducId">
                <b-col>
                  <h3>{{ TeacherLevelEducTitle }}</h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p v-html="TeacherLevelEducDescription" />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <visual-component
                    v-if="!HideTeacherLevelEducVisual"
                    ref="TeacherLevelEduc1"

                    :visual-title="''"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :chart-type="ChartTypes.HorizontalBarChart"
                    :chart-data="TeacherLevelEducData()[0]"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <visual-component
                    v-if="!HideTeacherLevelEducVisual"
                    ref="TeacherLevelEduc2"
                    :visual-title="''"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :chart-type="ChartTypes.HorizontalBarChart"
                    :chart-data="TeacherLevelEducData()[1]"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <visual-component
                    v-if="!HideTeacherLevelEducVisual"
                    ref="TeacherLevelEduc3"
                    :visual-title="''"
                    :suppress-border="true"
                    :suppress-print-button="true"
                    :chart-type="ChartTypes.HorizontalBarChart"
                    :chart-data="TeacherLevelEducData()[2]"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-row>
              <b-col>
                <visual-component
                  v-if="!HideTeacher1YearRetentionVisual"
                  :visual-title="Teacher1YearRetentionTitle"
                  :visual-description="Teacher1YearRetentionDescription"
                  :chart-type="ChartTypes.TableGrid"
                  :chart-data="Teacher1YearRetentionData"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div v-if="showSection('Staff')" class="page-break" />
      <div v-if="showSection('Staff')" class="print-container">
        <!-- Staff 3b -->
        <print-header>
          {{ getSectionHeaderText('Staff') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro keep-break" v-html="StaffIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col>
                <visual-component
                  v-if="!HideTeacherSubjectAreaVisual"
                  :visual-title="TeacherSubjectAreaTitle"
                  :visual-description="TeacherSubjectAreaDescription"
                  :chart-type="ChartTypes.TableGrid"
                  :chart-data="TeacherSubjectAreaData"
                  :vertical-lines="['% Non-binary or Undesignated Gender',
                                    '% % Género no binario o no identificado',
                                    '% Two or More Races',
                                    '% Dos o Más Razas',
                                    '% 4 or more years experience in the district',
                                    '% 4 o más años de experiencia en el Distrito']"
                  :horizontal-lines="['Special Education',
                                      'Educación Especial']"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="showSection('Staff')
          && !HideStatewideEducatorEquityVisual"
        class="page-break"
      />
      <!-- Staff 4 -->
      <div
        v-if="showSection('Staff') && !HideStatewideEducatorEquityVisual"
        class="print-container"
      >
        <print-header>
          {{ getSectionHeaderText('Staff') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro keep-break" v-html="StaffIntro" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <visual-component
              v-if="!HideStatewideEducatorEquityVisual"
              :visual-title="StatewideEducatorEquityTitle"
              :visual-description="StatewideEducatorEquityDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="StatewideEducatorEquityData"
            />
          </b-col>
        </b-row>
      </div>
      <div
        v-if="schoolYear <= 1920 || ReportType === 'state'"
        class="print-container page-break"
      >
        <print-header>
          {{ getSectionHeaderText('PerPupil') }}
        </print-header>
        <b-row>
          <b-col>
            <b-container fluid>
              <b-row class="pt-4 pb-4">
                <b-col>
                  <visual-component
                    v-if="schoolYear <= 1920 || ReportType === 'state'"
                    :visual-title="ExpenditureTitle"
                    :visual-description="ExpenditureDescription"
                    :visual-footer="ExpenditureFooter"
                    chart-type="None"
                    :chart-data="{ Values: [], Labels: [], ColumnHeaders: [] }"
                  >
                    <span
                      v-if="ExpenditureUrl && ExpenditureUrl !== '-'"
                      v-html="ExpenditureUrl"
                    />
                  </visual-component>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </div>
      <div
        v-for="(block, index) in perPupilBlockCount"
        v-else
        :key="`Per_Pupil_${index}`"
        class="print-container page-break"
      >
        <print-header>
          {{ getSectionHeaderText('PerPupil') }}
        </print-header>
        <b-row>
          <b-col>
            <b-container fluid>
              <b-row class="pt-4 pb-4">
                <b-col>
                  <visual-component
                    :visual-title="ExpenditureTitle"
                    :visual-description="ExpenditureDescription"
                    :visual-footer="ExpenditureFooter"
                    :chart-type="ChartTypes.TableGrid"
                    :chart-data="perPupilBlock(index)"
                    :horizontal-lines="HorizontalLineRows"
                  >
                    <span
                      v-if="ExpenditureUrl && ExpenditureUrl !== '-'"
                      v-html="ExpenditureUrl"
                    />
                  </visual-component>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </div>
      <div v-if="showSection('Accountability')" class="page-break" />
      <!-- Accountability 1 -->
      <div v-if="showSection('Accountability')" class="print-container" name="Accountability_1">
        <print-header>
          {{ getSectionHeaderText('Accountability') }}
        </print-header>
        <b-row>
          <b-col>
            <div class="intro" v-html="AccountabilityIntro" />
          </b-col>
        </b-row>
      </div>
      <div v-if="showSection('Accountability') && ReportType !== 'school'">
        <div v-for="(block, index) in targetedSupportBlockCount" :key="`Accountability_${index}`">
          <div
            v-if="showSection('Accountability')
              && ReportType !== 'school'"
            class="page-break"
          />
          <div
            v-if="showSection('Accountability')
              && ReportType !== 'school'"
            class="print-container"
            :name="`Accountability_2_${index}`"
          >
            <print-header>
              {{ getSectionHeaderText('Accountability') }}
            </print-header>
            <b-row>
              <b-col>
                <visual-component
                  :id="ComprehensiveOrTargetedSupportId"
                  :visual-title="ComprehensiveOrTargetedSupportTitle"
                  :visual-description="ComprehensiveOrTargetedSupportDescription"
                  :chart-type="ChartTypes.TableGrid"
                  :chart-data="targetedSupportBlock(index)"
                />
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-if="targetedSupportBlockCount === 0" class="page-break" />
        <div
          v-if="targetedSupportBlockCount === 0"
          class="print-container"
          :name="`Accountability_3_${index}`"
        >
          <print-header>
            {{ getSectionHeaderText('Accountability') }}
          </print-header>
          <b-row>
            <b-col>
              <visual-component
                :id="ComprehensiveOrTargetedSupportId"
                :visual-title="ComprehensiveOrTargetedSupportTitle"
                :visual-description="ComprehensiveOrTargetedSupportDescription"
                :chart-type="ChartTypes.None"
                :chart-data="{}"
                :visual-no-data-text="ComprehensiveOrTargettedSupportNoDataText"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="showSection('Accountability')" class="page-break" />
      <!-- Accountability 2 -->
      <div v-if="showSection('Accountability')" class="print-container" name="Accountability_4">
        <print-header>
          {{ getSectionHeaderText('Accountability') }}
        </print-header>
        <b-row>
          <b-col v-if="ReportType === 'school'" cols="7">
            <visual-component
              v-if="ReportType === 'school'"
              :id="AccountabilityStatusId"
              class="h-100"
              :visual-title="AccountabilityStatusTitle"
              :visual-description="AccountabilityStatusDescription"
              :visual-footer="AccountabilityStatusPlusFooter"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="AccountabilityStatusData"
            />
          </b-col>
          <b-col :cols="ReportType === 'school' ? 5 : 12">
            <visual-component
              class="h-100"
              :visual-title="AccountabilityProgressTitle"
              :visual-description="AccountabilityProgressDescription"
              :visual-footer="AccountabilityProgressPlusFooter"
              :chart-type="ChartTypes.TableGrid"
              :table-shading="AccountabilityProgressTableShading"
              :chart-data="AccountabilityProgressData"
            />
          </b-col>
        </b-row>
      </div>
      <div
        v-if="$store.getters.SelectedSchoolYear.value != 2021
          && ReportType === 'school'
          && showSection('Accountability')"
        class="page-break"
      />
      <!-- Accountability 5 -->
      <div
        v-if="showSection('Accountability')
          && ReportType === 'school'"
        class="print-container"
        name="Accountability_5"
      >
        <print-header>
          {{ getSectionHeaderText('Accountability') }}
        </print-header>
        <b-row>
          <b-col>
            <visual-component
              v-if="$store.getters.SelectedSchoolYear.value != 2021
                && ReportType == 'school'"
              :id="AccountabilitySummativeId"
              :visual-title="AccountabilitySummativeTitle"
              :visual-description="AccountabilitySummativeDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="AccountabilitySummativeData"
              :table-shading="AccountabilitySummativeTableShading"
              :visual-footer="AccountabilitySummativeFooter"
              :horizontal-lines="['Summative Score', 'Puntaje sumativo']"
            />
          </b-col>
        </b-row>
      </div>
      <!-- Accountability 2 -->
      <div
        v-if="$store.getters.SelectedSchoolYear.value != 2021
          && ReportType != 'state' && showSection('Accountability')"
        class="page-break"
      />
      <div
        v-if="$store.getters.SelectedSchoolYear.value != 2021
          && ReportType != 'state' && showSection('Accountability')"
        class="print-container"
        name="Accountability_6"
      >
        <print-header>
          {{ getSectionHeaderText('Accountability') }}
        </print-header>
        <b-row>
          <b-col>
            <visual-component
              :visual-title="AccountabilitySummaryTitle"
              :visual-description="AccountabilitySummaryDescription"
              :chart-type="ChartTypes.TableGrid"
              :chart-data="AccountabilitySummaryData"
              :table-shading="AccoutabilitySummaryShading"
              :visual-footer="AccountabilitySummaryGroupPlusFooter"
              :horizontal-lines="['Economically Disadvantaged Students',
                                  'Estudiantes Económicamente en Desventaja']"
              :vertical-lines="['Requires Additional Targeted Support: Low Performing Student Group',
                                'Requiere apoyo específico adicional: grupo de estudiantes de bajo rendimiento',
                                'Chronic Absenteeism', 'Absentismo Crónico',
              ]"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="showSection('Narrative')">
        <div
          v-for="(index, i) in narrativePages"
          :key="`Narrative_Outer_${i}`"
          class="print-container page-break"
        >
          <print-header>
            {{ getSectionHeaderText('Narrative') }}
          </print-header>
          <b-row>
            <b-col>
              <div class="intro" v-html="Narrativetext" />
            </b-col>
          </b-row>
          <b-row
            v-for="(narrativeIndex, r) in remainingItems(i)"
            :key="`Narrative_Inner_${i}_${r}`"
          >
            <b-col>
              <b-container class="outside-border keep-break my-1 py-2">
                <narrative-description
                  :icon-class="narrativeIcon(narrativeItems[(narrativeItemsPerPage * i) + r])"
                  :icon-data="narrativeItems[(narrativeItemsPerPage * i) + r]"
                  :show-bullet-points="narrativeShowBullet(
                    narrativeItems[(narrativeItemsPerPage * i) + r],
                  )"
                />
              </b-container>
            </b-col>
          </b-row>
        </div>
        <div
          v-if="narrativeOtherInfo && narrativeOtherInfo.key"
          class="print-container page-break"
        >
          <print-header>
            {{ getSectionHeaderText('Narrative') }}
          </print-header>
          <b-row>
            <b-col>
              <div class="intro" v-html="Narrativetext" />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-container class="outside-border keep-break my-1 py-2">
                <narrative-description
                  :icon-class="narrativeIcon(narrativeOtherInfo)"
                  :icon-data="narrativeOtherInfo"
                  :show-bullet-points="narrativeShowBullet(narrativeOtherInfo)"
                />
              </b-container>
            </b-col>
          </b-row>
        </div>
        <div v-if="narrativePages === 0" class="print-container page-break">
          <print-header>
            Narrative
          </print-header>
          <b-row>
            <b-col>
              <div class="intro" v-html="Narrativetext" />
            </b-col>
          </b-row>
          <b-row>
            <b-col col class="m-auto">
              <p class="lead">
                <strong>No narrative was submitted.</strong>
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
    <div class="d-none" style="position: fixed; top: 0; right: 15px; cursor: pointer;">
      <a
        title="Print Current Page"
        @click="printPage"
        @keydown="printPage"
      >
        Print Page
      </a>
    </div>
    <div class="d-print-none" style="position: fixed; top: 0; right: 15px; cursor: pointer;">
      <b-btn id="printModeButton" @click="setprintMode">
        Set Print Mode
      </b-btn>
    </div>
  </div>
</template>

<script>
import TranslationSvc from '../services/translation.service';
import translation from '../mixins/translation-service.mixin';
import hiddenLogic from '../mixins/hidden.mixin';
import SchoolDistrictInformation from '../components/SchoolDistrictInformation.vue';
import NarrativeDescription from '../components/NarrativeDescription.vue';
import PrintHeader from '../components/PrintHeader.vue';
import VisualComponent from '../components/VisualComponent.vue';
import Overview from '../mixins/detailComponents/overview.mixin';
// eslint-disable-next-line import/no-cycle
import Demographics from '../mixins/detailComponents/demographics.mixin';
// eslint-disable-next-line import/no-cycle
import Growth from '../mixins/detailComponents/growth.mixin';
// eslint-disable-next-line import/no-cycle
import Academic from '../mixins/detailComponents/academic.mixin';
// eslint-disable-next-line import/no-cycle
import College from '../mixins/detailComponents/college.mixin';
// eslint-disable-next-line import/no-cycle
import Grad from '../mixins/detailComponents/grad.mixin';
// eslint-disable-next-line import/no-cycle
import Climate from '../mixins/detailComponents/climate.mixin';
// eslint-disable-next-line import/no-cycle
import Staff from '../mixins/detailComponents/staff.mixin';
import PerPupil from '../mixins/detailComponents/perpupil.mixin';
// eslint-disable-next-line import/no-cycle
import Accountability from '../mixins/detailComponents/accountability.mixin';
import Narrative from '../mixins/detailComponents/narrative.mixin';
// eslint-disable-next-line import/no-cycle
import VisualPerformingArts from '../mixins/detailComponents/visual-performing-arts.mixin';
import DataLoader from '../mixins/print-data-loader.mixin';

export default {
  components: {
    SchoolDistrictInformation,
    PrintHeader,
    VisualComponent,
    NarrativeDescription,
  },
  mixins: [
    translation,
    hiddenLogic,
    Overview,
    Demographics,
    Growth,
    Academic,
    College,
    Grad,
    Climate,
    Staff,
    PerPupil,
    Accountability,
    Narrative,
    VisualPerformingArts,
    DataLoader,
  ],
  data() {
    return {
      narrativeList: [],
      narrativeItemsPerPage: 3,
    };
  },
  computed: {
    currentInstitution() {
      switch (this.ReportType) {
        case 'school':
          return this.$store.getters.CurrentSchoolInfo;
        case 'district':
          return this.$store.getters.CurrentDistrictInfo;
        case 'state':
          return this.$store.getters.CurrentStateInfo;
        default:
          return {};
      }
    },
    narrativePages() {
      return Math.ceil(this.narrativeItems.length / this.narrativeItemsPerPage);
    },
    narrativeOtherInfo() {
      const storeData = this.$store.getters.NarrativeReportData.filter((o) => o.Component === `${this.ReportType}_Narrative`);
      if (!storeData || storeData.length === 0 || !storeData[0].data) {
        return [];
      }
      return storeData[0].data.filter((o) => o.key === 'otherinformation')[0];
    },
    narrativeItems() {
      const storeData = this.$store.getters.NarrativeReportData.filter((o) => o.Component === `${this.ReportType}_Narrative`);
      if (!storeData || storeData.length === 0 || !storeData[0].data) {
        return [];
      }
      return storeData[0].data.filter((o) => o.key !== 'otherinformation');
    },
    requestCount() {
      return this.$store.getters.RequestCount;
    },
    showAcademicPage2() {
      return this.showSection('AcademicAchievement')
      && !this.HideEnglishMathTrend;
    },
    showAcademicELAPartPerf() {
      return this.showSection('AcademicAchievement')
        && (!this.HideELAParticipationPerformance);
    },
    showAcademicPage6() {
      return this.showSection('AcademicAchievement')
        && this.visualIsAvailableForInstitution(
          'AcademicAchievement',
          TranslationSvc.Constants.ComponentNames.NJSLAScienceGradeSummaryTitle,
        );
    },
    showAcademicPage5() {
      return this.showSection('AcademicAchievement')
        && (!this.HideDLMAssessmentParticipation);
    },
    showAcademicPage5b() {
      return this.showSection('AcademicAchievement')
        && (!this.HideEnglishProficiency
        || !this.HideEnglishParticipationPerf);
    },
    showCollegePage1() {
      return this.showSection('CollegeCareer')
        && (!this.HideAssessmentsParticipationData
        || !this.HideAssessmentsPerformanceData);
    },
    showCollegePage2() {
      const showSect = this.showSection('CollegeCareer');
      const showVis = (!this.HideAPIBData
        || !this.HideAPIBCoursesOfferedData
        || !this.HideDualEnrollmentData);

      if (!showSect) { return false; }

      return showSect && showVis;
    },
    showCollegePage2a() {
      const showSect = this.showSection('CollegeCareer');
      const showVis = (!this.HideAPIBData);

      if (!showSect) { return false; }

      return showSect && showVis;
    },
    showCollegePage2b() {
      const showSect = this.showSection('CollegeCareer');
      const showVis = (!this.HideDualEnrollmentData);

      if (!showSect) { return false; }

      return showSect && showVis;
    },
    showCollegePage3() {
      return this.showSection('CollegeCareer')
        && (!this.HideCTEParticipationData
        || !this.HideCTEStdGroupData
        || !this.HideWorkBasedLearningData);
    },
    showCollegePage4() {
      return this.showSection('CollegeCareer')
        && (!this.HideCTEStdGroupData
        || !this.HideWorkBasedLearningData);
    },
    showCollegePage5() {
      return this.showSection('CollegeCareer')
        && (!this.HideCTEValuedCredData
        || !this.HideCTEValuedCredCareerData);
    },
    showCollegePage678() {
      return this.showSection('CollegeCareer')
        && (!this.HideCourseParticipationMathData
        || !this.HideCourseParticipationScienceData
        || !this.HideCourseParticipationHistoryData
        || !this.HideCourseParticipationLanguagesData
        || !this.HideCourseParticipationITData);
    },
    showCollegePage9() {
      return this.showSection('CollegeCareer')
        && !this.HideSealBiliteracyData;
    },
    showCollegePageK5() {
      const ret = this.showSection('CollegeCareer')
        && (!this.HideVisualPerformingArtsKto5);
      return ret;
    },
    showCollegePage10() {
      const ret = this.showSection('CollegeCareer')
        && (!this.HideVisualPerformingArts6to8
        || !this.HideVisualPerformingArts9to12);
      return ret;
    },
    academicPage5Columns() {
      const visualCount = (this.HideDLMAssessmentParticipation ? 0 : 1)
        + (this.HideEnglishProficiency ? 0 : 1)
        + (this.HideEnglishParticipationPerf ? 0 : 1);

      return visualCount === 0 ? 12 : 12 / visualCount;
    },
    showAccountability() {
      return this.showSection('Accountability')
        && this.HideComprehensiveOrTargettedSupportData;
    },
    schoolsInDistrictBlockCount() {
      if (!this.SchoolsInDistrictData
        || !this.SchoolsInDistrictData.Values
        || this.SchoolsInDistrictData.Values.length === 0
        || !this.SchoolsInDistrictData.Values[0].data
        || this.SchoolsInDistrictData.Values[0].data.length === 0) {
        return 0;
      }
      return Math.ceil(this.SchoolsInDistrictData.Values[0].data.length / 30);
    },
    targetedSupportBlockCount() {
      if (!this.ComprehensiveOrTargettedSupportData
        || !this.ComprehensiveOrTargettedSupportData.Values
        || this.ComprehensiveOrTargettedSupportData.Values.length === 0
        || !this.ComprehensiveOrTargettedSupportData.Values[0].data
        || this.ComprehensiveOrTargettedSupportData.Values[0].data.length === 0) {
        // eslint-disable-next-line
        return 0;
      }
      const perPage = this.$store.getters.CurrentLanguage === 'ES' ? 12 : 16;
      return Math.ceil(this.ComprehensiveOrTargettedSupportData.Values[0].data.length / perPage);
    },
    perPupilBlockCount() {
      if (!this.ExpenditureData
        || !this.ExpenditureData.Values
        || this.ExpenditureData.Values.length === 0
        || !this.ExpenditureData.Values[0].data
        || this.ExpenditureData.Values[0].data.length === 0) {
        return 0;
      }
      const perPage = 20;
      return Math.ceil(this.ExpenditureData.Values[0].data.length / perPage);
    },
    aPIBCoursesOfferedBlockCount() {
      if (!this.APIBCoursesOfferedData
        || !this.APIBCoursesOfferedData.Values
        || this.APIBCoursesOfferedData.Values.length === 0
        || !this.APIBCoursesOfferedData.Values[0].data
        || this.APIBCoursesOfferedData.Values[0].data.length === 0) {
        return 0;
      }
      return Math.ceil(this.APIBCoursesOfferedData.Values[0].data.length / 22);
    },
    sealBiliteracyBlockCount() {
      return Math.ceil(this.SealBiliteracyData.Values[0].data.length / 20);
    },
  },
  watch: {
    async requestCount(newValue) {
      if (newValue === 0) {
        // this.$children.forEach((child) => {
        //   if (child.enableprintMode) {
        //     child.enableprintMode();
        //   }
        // });

        // const visualComponents = this.$children.filter(
        // (o) => o.$vnode.tag.indexOf('visual-component') >= 0);
        // visualComponents.forEach((comp) => {
        //   comp.enablePrintView();
        // });

        // setTimeout(() => {
        //   window.status = 'ready';
        // }, 1000);
      }
    },
  },
  created() {
    this.$store.dispatch('setPrintView', true);

    const printStyle = document.createElement('link');
    printStyle.setAttribute('rel', 'stylesheet');
    printStyle.setAttribute('type', 'text/css');
    printStyle.setAttribute('href', `${process.env.VUE_APP_ROOT}print.css`);
    document.head.appendChild(printStyle);

    this.fetchPrintData();
  },
  mounted() {
    // window.onafterprint = () => {
    //   this.$router.push({
    //     name: 'Overview',
    //     params: this.$route.params,
    //   });
    // };
    window.addEventListener('load', () => {
    });
  },
  methods: {
    getSectionHeaderText(section) {
      if (!this.translationService) return 'Translation Is Null';
      return this.translationService.getNavigationTranslation(
        section,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      ).replace('<br/>', ' ').replace('<br />', ' ');
    },
    schoolsInDistrictBlock(block) {
      const valuesArray = [];
      const labelsArray = [];
      let i;
      const perPage = 30;
      for (i = block * perPage; i < (block + 1) * perPage; i += 1) {
        if (i >= this.SchoolsInDistrictData.Labels.length) break;
        labelsArray.push(this.SchoolsInDistrictData.Labels[i]);
      }

      let data = [];
      let dataAsString = [];
      this.SchoolsInDistrictData.Values.forEach((val) => {
        for (i = block * perPage; i < (block + 1) * perPage; i += 1) {
          if (i >= val.data.length) break;
          data.push(val.data[i]);
          dataAsString.push(val.dataAsString[i]);
        }
        valuesArray.push({
          backgroundColor: val.backgroundColor,
          borderColor: val.borderColor,
          data,
          dataAsString,
          fill: val.fill,
          label: val.label,
        });
        data = [];
        dataAsString = [];
      });

      return {
        Labels: labelsArray,
        Values: valuesArray,
        ColumnHeaders: this.SchoolsInDistrictData.ColumnHeaders,
      };
    },
    targetedSupportBlock(block) {
      const valuesArray = [];
      const labelsArray = [];
      let i;
      const perPage = this.$store.getters.CurrentLanguage === 'ES' ? 12 : 16;
      for (i = block * perPage; i < (block + 1) * perPage; i += 1) {
        if (i >= this.ComprehensiveOrTargettedSupportData.Labels.length) break;
        labelsArray.push(this.ComprehensiveOrTargettedSupportData.Labels[i]);
      }

      let data = [];
      let dataAsString = [];
      this.ComprehensiveOrTargettedSupportData.Values.forEach((val) => {
        for (i = block * perPage; i < (block + 1) * perPage; i += 1) {
          if (i >= val.data.length) break;
          data.push(val.data[i]);
          dataAsString.push(val.dataAsString[i]);
        }
        valuesArray.push({
          backgroundColor: val.backgroundColor,
          borderColor: val.borderColor,
          data,
          dataAsString,
          fill: val.fill,
          label: val.label,
        });
        data = [];
        dataAsString = [];
      });

      return {
        Labels: labelsArray,
        Values: valuesArray,
        ColumnHeaders: this.ComprehensiveOrTargettedSupportData.ColumnHeaders,
      };
    },
    perPupilBlock(block) {
      const valuesArray = [];
      const labelsArray = [];
      let i;
      const perPage = 20;
      for (i = block * perPage; i < (block + 1) * perPage; i += 1) {
        if (i >= this.ExpenditureData.Labels.length) break;
        labelsArray.push(this.ExpenditureData.Labels[i]);
      }

      let data = [];
      let dataAsString = [];
      this.ExpenditureData.Values.forEach((val) => {
        for (i = block * perPage; i < (block + 1) * perPage; i += 1) {
          if (i >= val.data.length) break;
          data.push(val.data[i]);
          dataAsString.push(val.dataAsString[i]);
        }
        valuesArray.push({
          backgroundColor: val.backgroundColor,
          borderColor: val.borderColor,
          data,
          dataAsString,
          fill: val.fill,
          label: val.label,
        });
        data = [];
        dataAsString = [];
      });

      return {
        Labels: labelsArray,
        Values: valuesArray,
        ColumnHeaders: this.ExpenditureData.ColumnHeaders,
      };
    },
    aPIBCoursesOfferedBlock(block) {
      const valuesArray = [];
      const labelsArray = [];
      let i;
      const perPage = 22;
      for (i = block * perPage; i < (block + 1) * perPage; i += 1) {
        if (i >= this.APIBCoursesOfferedData.Labels.length) break;
        labelsArray.push(this.APIBCoursesOfferedData.Labels[i]);
      }

      let data = [];
      let dataAsString = [];
      this.APIBCoursesOfferedData.Values.forEach((val) => {
        for (i = block * perPage; i < (block + 1) * perPage; i += 1) {
          if (i >= val.data.length) break;
          data.push(val.data[i]);
          dataAsString.push(val.dataAsString[i]);
        }
        valuesArray.push({
          backgroundColor: val.backgroundColor,
          borderColor: val.borderColor,
          data,
          dataAsString,
          fill: val.fill,
          label: val.label,
        });
        data = [];
        dataAsString = [];
      });

      return {
        Labels: labelsArray,
        Values: valuesArray,
        ColumnHeaders: this.APIBCoursesOfferedData.ColumnHeaders,
      };
    },
    sealBiliteracyBlock(block) {
      const valuesArray = [];
      const labelsArray = [];
      let i;
      const perPage = 20;
      for (i = block * perPage; i < (block + 1) * perPage; i += 1) {
        if (i >= this.SealBiliteracyData.Labels.length) break;
        labelsArray.push(this.SealBiliteracyData.Labels[i]);
      }

      let data = [];
      let dataAsString = [];
      this.SealBiliteracyData.Values.forEach((val) => {
        for (i = block * perPage; i < (block + 1) * perPage; i += 1) {
          if (i >= val.data.length) break;
          data.push(val.data[i]);
          dataAsString.push(val.dataAsString[i]);
        }
        valuesArray.push({
          backgroundColor: val.backgroundColor,
          borderColor: val.borderColor,
          data,
          dataAsString,
          fill: val.fill,
          label: val.label,
        });
        data = [];
        dataAsString = [];
      });

      return {
        Labels: labelsArray,
        Values: valuesArray,
        ColumnHeaders: this.SealBiliteracyData.ColumnHeaders,
      };
    },
    showSection(sectionCode) {
      let institution = {};

      switch (this.ReportType) {
        case 'school':
          institution = this.$store.getters.CurrentSchoolInfo;
          break;
        case 'district':
          institution = this.$store.getters.CurrentDistrictInfo;
          break;
        case 'state':
          institution = this.$store.getters.CurrentStateInfo;
          break;
        default:
          institution = {};
      }

      return institution.topT2NavigationItems
        && institution.topT2NavigationItems.some((nav) => nav.value === sectionCode);
    },
    narrativeIcon(narrativeItem) {
      switch (narrativeItem.key) {
        case 'highlights':
          return ['fas', 'bullhorn'];
        case 'mission':
          return ['fas', 'lightbulb'];
        case 'awards':
          return ['fas', 'award'];
        case 'courses':
          return ['fas', 'book'];
        case 'sports':
          return ['fas', 'running'];
        case 'clubs':
          return ['fas', 'icons'];
        case 'programs':
          return ['fas', 'theater-masks'];
        case 'staff':
          return ['fas', 'users'];
        case 'postsecondary':
          return ['fas', 'university'];
        case 'supports':
          return ['fas', 'hands-helping'];
        case 'health':
          return ['fas', 'user-md'];
        case 'parent':
          return ['fas', 'handshake'];
        case 'climatesurveyused':
          return ['fas', 'poll'];
        case 'facilities':
          return ['fas', 'hotel'];
        case 'schoolsafety':
          return ['fas', 'shield-alt'];
        case 'technology':
          return ['fas', 'laptop'];
        case 'earlychildhood':
          return ['fas', 'font'];
        case 'learningduringcovid':
          return ['fas', 'people-arrows'];
        case 'otherinformation':
          return ['fas', 'info-circle'];
        default:
          return ['fas', 'bug'];
      }
    },
    narrativeShowBullet(narrativeItem) {
      if (!narrativeItem || !narrativeItem.key) {
        return false;
      }
      switch (narrativeItem.key) {
        case 'highlights':
          return true;
        default:
          return false;
      }
    },
    remainingItems(rowIndex) {
      return this.narrativeItems.length - (rowIndex * this.narrativeItemsPerPage)
        >= this.narrativeItemsPerPage ? this.narrativeItemsPerPage
        : this.narrativeItems.length - (rowIndex * this.narrativeItemsPerPage);
    },
    printPage() {
      this.$htmlToPaper('print-target');
    },
    setprintMode() {
      const visualComponents = this.$children.filter((o) => o.$vnode.tag.indexOf('visual-component') >= 0);
      /* eslint-disable */
      visualComponents.forEach(async (comp) => {
        await new Promise((r) => setTimeout(r, 200));
        comp.enablePrintView();
      });
      window.status = 'ready';
      /* eslint-enable */
    },
  },
};
</script>

<style>
@media print {
}
</style>

<style scoped>
  /* @import "/print.css"; */
.alignment {
  text-align: left;
}
</style>
