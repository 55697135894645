import axios from 'axios';

const tokenUri = `${process.env.VUE_APP_API_ROOT}${process.env.VUE_APP_API_AUTH}`;

const LOCAL_STORAGE_TOKEN = 'token';
const LOCAL_STORAGE_TOKEN_EXPIRES = 'token_expire';
const LOCAL_STORAGE_ACCESS_CODE = 'access_code';

function getLocalToken() {
  return localStorage.getItem(LOCAL_STORAGE_TOKEN);
}

function getTokenExpires() {
  return new Date(localStorage.getItem(LOCAL_STORAGE_TOKEN_EXPIRES));
}

function isAuthenticated() {
  const token = getLocalToken();
  const expires = getTokenExpires();

  return token !== null && expires > new Date();
}

function setToken(token, expires) {
  localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
  localStorage.setItem(LOCAL_STORAGE_TOKEN_EXPIRES, expires);
}

function clear() {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_EXPIRES);
  localStorage.removeItem(LOCAL_STORAGE_ACCESS_CODE);
}

async function getNewToken() {
  const data = {};
  if (localStorage.getItem(LOCAL_STORAGE_ACCESS_CODE)) {
    data.accessCode = localStorage.getItem(LOCAL_STORAGE_ACCESS_CODE);
  }

  const response = await axios({
    method: 'post',
    url: tokenUri,
    data,
    headers: {
      'content-type': 'application/json',
    },
  });
  const dt = new Date();
  const expireDt = new Date(dt.setMinutes(dt.getMinutes() + (response.data.expires_In / 60) - 5));
  setToken(response.data.jwtToken, expireDt);
}

async function getToken() {
  if (!isAuthenticated()) {
    await getNewToken();
  }
  return localStorage.getItem(LOCAL_STORAGE_TOKEN);
}

export default {
  isAuthenticated,
  getToken,
  logout() {
    clear();
  },
  authUrl() {
    return tokenUri;
  },
};
