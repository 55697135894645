import districtService from '../services/district.service';
import schoolService from '../services/school.service';
import stateService from '../services/state.service';

export default {
  state: {
    collegeReportData: [],
  },
  getters: {
    CollegeReportData: (state) => state.collegeReportData,
  },
  mutations: {
    SET_COLLEGE_REPORT_DATA(state, payload) {
      const component = state.collegeReportData
        .filter((o) => o.Component === payload.Component);

      if (component && component.length === 1) {
        component[0].data = payload.data;
      } else {
        state.collegeReportData.push({
          Component: payload.Component,
          data: payload.data,
        });
      }
    },
  },
  actions: {
    async getDistrictAssessmentsParticipation(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_AssessmentsParticipation`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getPSATSATACTParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getPSATSATACTParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getPSATSATACTParticipation(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_AssessmentsParticipation`, data: response });
      }
    },
    async getDistrictAssessmentsPerformance(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_AssessmentsPerformance`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getPSATSATACTPerformance(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getPSATSATACTPerformance(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getPSATSATACTPerformance(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_AssessmentsPerformance`, data: response });
      }
    },
    async getDistrictAPIB(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_APIB`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getAPIBCourseworkParticipationandPerformance(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getAPIBCourseworkParticipationandPerformance(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getAPIBCourseworkParticipationandPerformance(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_APIB`, data: response });
      }
    },
    async getDistrictAPIBCoursesOffered(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_APIBCoursesOffered`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getAPIBCoursesOffered(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getAPIBCoursesOffered(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getAPIBCoursesOffered(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_APIBCoursesOffered`, data: response });
      }
    },
    async getDistrictDualEnrollment(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_DualEnrollment`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getDualEnrollmentCourseworkParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getDualEnrollmentCourseworkParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getDualEnrollmentCourseworkParticipation(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_DualEnrollment`, data: response });
      }
    },
    async getDistrictDualEnrollStdtGrp(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_DualEnrollStdtGrp`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getDualEnrollStdtGrp(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getDualEnrollStdtGrp(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getDualEnrollStdtGrp(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_DualEnrollStdtGrp`, data: response });
      }
    },
    async getStructuredLearningExpParticipation(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_StructuredLearningExpParticipation`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getStructuredLearningExpParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getStructuredLearningExpParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getStructuredLearningExpParticipation(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_StructuredLearningExpParticipation`, data: response });
      }
    },
    async getDistrictCTEParticipation(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CTEParticipation`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getCareerandTechnicalEducationParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getCareerandTechnicalEducationParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getCareerandTechnicalEducationParticipation(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CTEParticipation`, data: response });
      }
    },
    async getDistrictCTEStdGroup(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CTEStGroup`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getCareerandTechnicalEducationParticipationbyStudentGroup(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getCareerandTechnicalEducationParticipationbyStudentGroup(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getCareerandTechnicalEducationParticipationbyStudentGroup(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CTEStGroup`, data: response });
      }
    },
    async getWorkBasedLearning(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_WorkBasedLearning`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getWorkBasedLearning(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getWorkBasedLearning(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getWorkBasedLearning(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_WorkBasedLearning`, data: response });
      }
    },
    async getDistrictCTEValuedCred(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CTEValuedCred`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getIndustryValuedCredentials(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getIndustryValuedCredentials(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getIndustryValuedCredentials(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CTEValuedCred`, data: response });
      }
    },
    async getDistrictCTEValuedCareer(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CTEValuedCareer`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getCareerandTechnicalEducationValuedCareer(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getCareerandTechnicalEducationValuedCareer(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getCareerandTechnicalEducationValuedCareer(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CTEValuedCareer`, data: response });
      }
    },
    async getDistrictCourseParticipationMath(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CourseParticipationMath`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getMathematicsCourseParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getMathematicsCourseParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getMathematicsCourseParticipation(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CourseParticipationMath`, data: response });
      }
    },
    async getDistrictCourseParticipationScience(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CourseParticipationScience`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getScienceCourseParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getScienceCourseParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getScienceCourseParticipation(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CourseParticipationScience`, data: response });
      }
    },
    async getDistrictCourseParticipationHistory(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CourseParticipationHistory`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getSocialStudiesandHistoryCourseParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getSocialStudiesandHistoryCourseParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getSocialStudiesandHistoryCourseParticipation(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CourseParticipationHistory`, data: response });
      }
    },
    async getDistrictCourseParticipationLanguage(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CourseParticipationLanguage`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getWorldLanguagesCourseParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getWorldLanguagesCourseParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getWorldLanguagesCourseParticipation(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CourseParticipationLanguage`, data: response });
      }
    },
    async getDistrictCourseParticipationIT(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CourseParticipationIT`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getComputerScienceandInformationTechnologyCourseParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getComputerScienceandInformationTechnologyCourseParticipation(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getComputerScienceandInformationTechnologyCourseParticipation(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CourseParticipationIT`, data: response });
      }
    },
    async getDistrictCourseParticipationArtsKto5(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CourseParticipationArtsKto5`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getVisualandPerformingArtsCourseParticipationKto5(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getVisualandPerformingArtsCourseParticipationKto5(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getVisualandPerformingArtsCourseParticipationKto5(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CourseParticipationArtsKto5`, data: response });
      }
    },
    async getDistrictCourseParticipationArtsKto5Donut(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CourseParticipationArtsKto5Donut`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getVisualandPerformingArtsCourseParticipationKto5Donut(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getVisualandPerformingArtsCourseParticipationKto5Donut(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getVisualandPerformingArtsCourseParticipationKto5Donut(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CourseParticipationArtsKto5Donut`, data: response });
      }
    },
    async getDistrictCourseParticipationArtsKto5DonutState(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CourseParticipationArtsKto5DonutState`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getVisualandPerformingArtsCourseParticipationKto5DonutState(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getVisualandPerformingArtsCourseParticipationKto5DonutState(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getVisualandPerformingArtsCourseParticipationKto5DonutState(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CourseParticipationArtsKto5DonutState`, data: response });
      }
    },
    async getDistrictCourseParticipationArts6to8(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CourseParticipationArts6to8`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getVisualandPerformingArtsCourseParticipation6to8(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getVisualandPerformingArtsCourseParticipation6to8(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getVisualandPerformingArtsCourseParticipation6to8(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CourseParticipationArts6to8`, data: response });
      }
    },
    async getDistrictCourseParticipationArts6to8Donut(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CourseParticipationArts6to8Donut`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getVisualandPerformingArtsCourseParticipation6to8Donut(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getVisualandPerformingArtsCourseParticipation6to8Donut(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getVisualandPerformingArtsCourseParticipation6to8Donut(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CourseParticipationArts6to8Donut`, data: response });
      }
    },
    async getDistrictCourseParticipationArts6to8DonutState(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CourseParticipationArts6to8DonutState`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getVisualandPerformingArtsCourseParticipation6to8DonutState(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getVisualandPerformingArtsCourseParticipation6to8DonutState(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getVisualandPerformingArtsCourseParticipation6to8DonutState(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CourseParticipationArts6to8DonutState`, data: response });
      }
    },
    async getDistrictCourseParticipationArts9to12(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CourseParticipationArts9to12`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getVisualandPerformingArtsCourseParticipation9to12(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getVisualandPerformingArtsCourseParticipation9to12(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getVisualandPerformingArtsCourseParticipation9to12(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CourseParticipationArts9to12`, data: response });
      }
    },
    async getDistrictCourseParticipationArts9to12Donut(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CourseParticipationArts9to12Donut`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getVisualandPerformingArtsCourseParticipation9to12Donut(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getVisualandPerformingArtsCourseParticipation9to12Donut(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getVisualandPerformingArtsCourseParticipation9to12Donut(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CourseParticipationArts9to12Donut`, data: response });
      }
    },
    async getDistrictCourseParticipationArts9to12DonutState(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_CourseParticipationArts9to12DonutState`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getVisualandPerformingArtsCourseParticipation9to12DonutState(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getVisualandPerformingArtsCourseParticipation9to12DonutState(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getVisualandPerformingArtsCourseParticipation9to12DonutState(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_CourseParticipationArts9to12DonutState`, data: response });
      }
    },
    async getDistrictSealBiliteracy(
      { commit, state },
      payload,
    ) {
      if (!state.collegeReportData.some((o) => o.Component === `${payload.reportType}_SealBiliteracy`)) {
        let response;
        switch (payload.reportType) {
          case 'district':
            response = await districtService.Detail.College
              .getSealofBiliteracy(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.langCd,
              );
            break;
          case 'school':
            response = await schoolService.Detail.College
              .getSealofBiliteracy(
                payload.schoolYear,
                payload.countyCd,
                payload.districtCd,
                payload.schoolCd,
                payload.langCd,
              );
            break;
          default:
            response = await stateService.Detail.College
              .getSealofBiliteracy(
                payload.schoolYear,
                payload.langCd,
              );
            break;
        }
        commit('SET_COLLEGE_REPORT_DATA', { Component: `${payload.reportType}_SealBiliteracy`, data: response });
      }
    },
  },
};
