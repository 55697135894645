import TranslationSvc from '../../services/translation.service';
import mapping from '../mapping.mixin';
import navigation from '../navigation.mixin';
import hidden from '../hidden.mixin';

export default {
  mixins: [mapping, navigation, hidden],
  methods: {
    fetchData() {
      const params = {
        schoolYear: this.$store.getters.SelectedSchoolYear.text,
        countyCd: this.$route.params.countyCd,
        districtCd: this.$route.params.districtCd,
        schoolCd: this.$route.params.schoolCd,
        langCd: this.$store.getters.CurrentLanguage,
        reportType: this.ReportType,
      };
      this.$store.dispatch('getDistrictViolenceOffense', params);
      this.$store.dispatch('getDistrictPoliceNotif', params);
      this.$store.dispatch('getDistrictHarrassInvest', params);
      this.$store.dispatch('getDistrictStdDisciplin', params);
      this.$store.dispatch('getDistrictStdDisciplinGrade', params);
      this.$store.dispatch('getDistrictStdArrestedGroup', params);
      this.$store.dispatch('getDistrictStdArrestedGrade', params);
      this.$store.dispatch('getDistrictStdPoliceGroup', params);
      this.$store.dispatch('getDistrictStdPoliceGrade', params);
      this.$store.dispatch('getDistrictSchoolDay', params);
      this.$store.dispatch('getDistrictSchoolDaysMissed', params);
      this.$store.dispatch('getDistrictDeviceRatios', params);
      this.$store.dispatch('getDistrictChronicAbsent', params);
      this.$store.dispatch('getDistrictDaysAbsent', params);
      this.$store.dispatch('getDistrictChronicAbsentGrade', params);
    },
  },
  computed: {
    AbsentSectionHeader() {
      return this.translationService.getClimateEnvTranslation(
        'Absent-Header',
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    DisciplineSectionHeader() {
      return this.translationService.getClimateEnvTranslation(
        'Discipline-Header',
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    SchoolDaySectionHeader() {
      return this.translationService.getClimateEnvTranslation(
        'SchoolDay-Header',
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    DeviceRatioSectionHeader() {
      return this.translationService.getClimateEnvTranslation(
        'DeviceRatios-Header',
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    Language() {
      return this.$store.getters.CurrentLanguage;
    },
    AbsentHeaderId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.AbsentHeader,
      );
    },
    AbsentIntro() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.AbsentIntro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AbsentChronicId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.AbsentChronicTitle,
      );
    },
    AbsentChronicTitle() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.AbsentChronicTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AbsentChronicDescription() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.AbsentChronicText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AbsentChronicData() {
      try {
        const storeData = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_AbsentChronic`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    AbsentChronicTableShading() {
      return [
        {
          cellValues: ['  ', ' NA '],
          cellVariant: 'secondary',
          suppressText: true,
        },
      ];
    },
    AbsentChronicVerticalLines() {
      if (this.ReportType === 'state') {
        return [''];
      }
      return ['% of Students Chronically Absent', '% de Estudiantes ausentes crónicamente'];
    },
    AbsentChronicTrendsId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.AbsentChronicTrendsTitle,
      );
    },
    AbsentChronicTrendsTitle() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.AbsentChronicTrendsTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AbsentChronicTrendsDescription() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.AbsentChronicTrendsText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AbsentChronicTrendsData() {
      try {
        const storeData = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_AbsentChronic`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    AbsentTrendsChronicTableData() {
      try {
        const storeData = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_AbsentChronic`);
        if (!storeData || storeData.length === 0) {
          return {};
        }
        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    AbsentDaysId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.AbsentDaysTitle,
      );
    },
    AbsentDaysTitle() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.AbsentDaysTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AbsentDaysDescription() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.AbsentDaysText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AbsentDaysChartSubTitle() {
      // return this.translationService.getClimateEnvTranslation(
      //   TranslationSvc.Constants.ComponentNames.AbsentDaysChartSubTitle,
      //   this.$store.getters.CurrentLanguage,
      //   this.$store.getters.SelectedSchoolYear.value,
      // );
      try {
        const storeData = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_DaysAbsent`);
        if (!storeData || storeData.length === 0 || !storeData[0].data
          || !storeData[0].data.columnHeaders || storeData[0].data.columnHeaders.length < 2) {
          return '';
        }
        return storeData[0].data.columnHeaders[1];
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    AbsentDaysChartXAxisTitle() {
      // return this.translationService.getClimateEnvTranslation(
      //   TranslationSvc.Constants.ComponentNames.AbsentDaysChartSubTitle,
      //   this.$store.getters.CurrentLanguage,
      //   this.$store.getters.SelectedSchoolYear.value,
      // );
      try {
        const storeData = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_DaysAbsent`);
        if (!storeData || storeData.length === 0 || !storeData[0].data
          || !storeData[0].data.columnHeaders || storeData[0].data.columnHeaders.length < 1) {
          return '';
        }
        return storeData[0].data.columnHeaders[0];
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    AbsentDaysData() {
      try {
        const storeData = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_DaysAbsent`);
        if (!storeData || storeData.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;
        const responseObject = this.MapData(storeObject, this.ChartTypes);

        let i;
        let j;
      // eslint-disable-next-line no-plusplus
      for (j = 0; j < responseObject.Values.length; j++) {
        responseObject.Values[j].backgroundColor = [];
        responseObject.Values[j].borderColor = [];
        // eslint-disable-next-line no-plusplus
        for (i = 0; i < responseObject.Labels.length; i++) {
          if (responseObject.Labels[i] === '10% to 12.9%'
            || responseObject.Labels[i] === '13% to 19.9%'
            || responseObject.Labels[i] === '20% or higher'
            || responseObject.Labels[i] === '20% o más') {
            responseObject.Values[j].backgroundColor.push('#fbe3d6');
          } else {
            responseObject.Values[j].backgroundColor.push('#c6dcf1');
          }
        }
      }
      return responseObject;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    AbsentGradeId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.AbsentGradeTitle,
      );
    },
    AbsentGradeTitle() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.AbsentGradeTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AbsentGradeDescription() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.AbsentGradeText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    AbsentGradeChartSubTitle() {
      // return this.translationService.getClimateEnvTranslation(
      //   TranslationSvc.Constants.ComponentNames.AbsentGradeChartSubTitle,
      //   this.$store.getters.CurrentLanguage,
      //   this.$store.getters.SelectedSchoolYear.value,
      // );
      try {
        const storeData = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_DaysAbsent`);
        if (!storeData || storeData.length === 0 || !storeData[0].data
          || !storeData[0].data.columnHeaders || storeData[0].data.columnHeaders.length < 2) {
          return '';
        }
        return storeData[0].data.columnHeaders[1];
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    AbsentGradeChartXAxisTitle() {
      // return this.translationService.getClimateEnvTranslation(
      //   TranslationSvc.Constants.ComponentNames.AbsentGradeChartSubTitle,
      //   this.$store.getters.CurrentLanguage,
      //   this.$store.getters.SelectedSchoolYear.value,
      // );
      try {
        const storeData = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_AbsentChronicGrade`);
        if (!storeData || storeData.length === 0 || !storeData[0].data
          || !storeData[0].data.columnHeaders || storeData[0].data.columnHeaders.length < 1) {
          return '';
        }
        return storeData[0].data.columnHeaders[0];
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    AbsentGradeData() {
      try {
        const storeData = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_AbsentChronicGrade`);
        if (!storeData || storeData.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    DisciplineId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.DisciplineHeader,
      );
    },
    NumberOfAbsences() {
      return '<span><strong>Number of Absences</strong></span>';
    },
    GradeLevel() {
      return '<span><strong>Grade Level</strong></span>';
    },
    Violenceintro() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.Violenceintro,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ViolenceOffensesId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.ViolenceOffensesTitle,
      );
    },
    ViolenceOffensesTitle() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.ViolenceOffensesTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ViolenceOffensesDescription() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.ViolenceOffensesText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    ViolenceOffensesData() {
      try {
        const storeData = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_ViolenceOffense`);
        if (!storeData || storeData.length === 0) {
          return {};
        }

        const storeObject = storeData[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    PoliceNotifId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.PoliceNotifTitle,
      );
    },
    PoliceNotifTitle() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.PoliceNotifTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    PoliceNotifDescription() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.PoliceNotifText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    PoliceNotifData() {
      try {
        const storeObjects = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_PoliceNotif`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    HarrassInvestId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.HarrassInvestTitle,
      );
    },
    HarrassInvestTitle() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.HarrassInvestTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    HarrassInvestDescription() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.HarrassInvestText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    HarrassInvestTableShading() {
      return [
        {
          cellValues: ['NA'],
          cellVariant: 'secondary',
          suppressText: true,
        },
      ];
    },
    HarrassInvestData() {
      try {
        const storeObjects = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_HarrassInvest`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    StdDisciplinId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.StdDisciplinTitle,
      );
    },
    StdDisciplinTitle() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.StdDisciplinTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdDisciplinDescription() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.StdDisciplinText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdDisciplinData() {
      try {
        const storeObjects = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_StdDisciplin`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    StdDisciplinGradeId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.StdDisciplinGradeTitle,
      );
    },
    StdDisciplinGradeTitle() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.StdDisciplinGradeTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdDisciplinGradeDescription() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.StdDisciplinGradeText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdDisciplinGradeData() {
      try {
        const storeObjects = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_StdDisciplinGrade`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    StdArrestedGroupId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.StdArrestedGroupTitle,
      );
    },
    StdArrestedGroupTitle() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.StdArrestedGroupTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdArrestedGroupDescription() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.StdArrestedGroupText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdArrestedGroupData() {
      try {
        const storeObjects = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_StdArrestedGroup`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    StdArrestedGradeId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.StdArrestedGradeTitle,
      );
    },
    StdArrestedGradeTitle() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.StdArrestedGradeTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdArrestedGradeDescription() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.StdArrestedGradeText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdArrestedGradeData() {
      try {
        const storeObjects = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_StdArrestedGrade`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    StdPoliceGroupId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.StdPoliceGroupTitle,
      );
    },
    StdPoliceGroupTitle() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.StdPoliceGroupTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdPoliceGroupDescription() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.StdPoliceGroupText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdPoliceGroupData() {
      try {
        const storeObjects = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_StdPoliceGroup`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    StdPoliceGradeId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.StdPoliceGradeTitle,
      );
    },
    StdPoliceGradeTitle() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.StdPoliceGradeTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdPoliceGradeDescription() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.StdPoliceGradeText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    StdPoliceGradeData() {
      try {
        const storeObjects = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_StdPoliceGrade`);
        if (!storeObjects || storeObjects.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    SchoolDayHeaderId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.SchoolDayHeader,
      );
    },
    SchoolDayId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.SchoolDayTitle,
      );
    },
    SchoolDayTitle() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.SchoolDayTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    SchoolDayDescription() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.SchoolDayText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    SchoolDayData() {
      try {
        const storeObjects = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_SchoolDay`);
        if (!storeObjects || storeObjects.length === 0
          || !storeObjects[0].data || !storeObjects[0].data.labels
          || !storeObjects[0].data.labels.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    SchoolDaysMissedId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.SchoolDaysMissedTitle,
      );
    },
    SchoolDaysMissedTitle() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.SchoolDaysMissedTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    SchoolDaysMissedDescription() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.SchoolDaysMissedText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    SchoolDaysMissedData() {
      try {
        const storeObjects = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_SchoolDaysMissed`);
        if (!storeObjects || storeObjects.length === 0
          || !storeObjects[0].data || !storeObjects[0].data.labels
          || !storeObjects[0].data.labels.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;

        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    DeviceRatiosHeaderId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.DeviceRatiosHeader,
      );
    },
    DeviceRatiosId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.DeviceRatiosTitle,
      );
    },
    DeviceRatiosTitle() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.DeviceRatiosTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    DeviceRatiosDescription() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.DeviceRatiosText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    DeviceRatiosData() {
      try {
        const storeObjects = this.$store.getters.ClimateReportData.filter((o) => o.Component === `${this.ReportType}_DeviceRatios`);
        if (!storeObjects || storeObjects.length === 0
          || !storeObjects[0].data || !storeObjects[0].data.labels
          || !storeObjects[0].data.labels.length === 0) {
          return {};
        }

        const storeObject = storeObjects[0].data;
        return this.MapData(storeObject, this.ChartTypes);
      } catch (e) {
        console.error(e);
        return {};
      }
    },
    TechnologyInternetAccessId() {
      return this.GetElementIdByTitle(
        TranslationSvc.Constants.ComponentNames.TechnologyInternetAccessTitle,
      );
    },
    TechnologyInternetAccessTitle() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.TechnologyInternetAccessTitle,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
    TechnologyInternetAccessText() {
      return this.translationService.getClimateEnvTranslation(
        TranslationSvc.Constants.ComponentNames.TechnologyInternetAccessText,
        this.$store.getters.CurrentLanguage,
        this.$store.getters.SelectedSchoolYear.value,
      );
    },
  },
  watch: {
    Language() {
      this.$store.dispatch('flushReportData');
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
